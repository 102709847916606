import { Breadcrumbs, Divider, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RegistrationSettings from './RegistrationSettings'

const localization = 'pages.overview.settings.'
const OverviewSettings = () => {
  const { t } = useTranslation()

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t(`${localization}title`)}</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <RegistrationSettings />
      </Paper>
    </>
  )
}

export default OverviewSettings
