import { Breadcrumbs, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import UserAttributes from '../Component/UserAttributes'
import UserDetailToolbar from '../Component/UserDetailToolbar'
import UserInformation from '../Component/UserInformation'
import { useUpdateDisableSuperUserMutation } from './mutate'
import { useSuperUserQuery } from './query'

const localization = 'pages.adminOverview.users.detail'

const SuperUserProfile = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, isSuccess } = useSuperUserQuery(id)

  const { mutate: updateDisableUser } = useUpdateDisableSuperUserMutation()

  const changeDisableUser = ({ id, disabled }) => {
    updateDisableUser({
      id,
      disabled
    })
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t(`${localization}.userInfo`)}</Typography>
      </Breadcrumbs>
      <UserDetailToolbar
        user={data}
        localization={localization}
        changeDisableUser={changeDisableUser}
      />
      <Grid container spacing={3}>
        {isSuccess && <UserInformation data={data} />}
        {isSuccess && <UserAttributes data={data} />}
      </Grid>
    </>
  )
}

export default SuperUserProfile
