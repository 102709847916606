import { CircleSharp, DeleteForever, KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, Menu, MenuItem } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useAdminPermission } from '~/hooks/useAdminPermission'

const StyledMenu = styled(props => <Menu {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const localization = 'pages.tenant.'

const TenantActionButton = ({
  row,
  onRemoveTenantClick,
  onCheckPermissionClick,
  onCheckDomainClick,
  isDomainChecking,
  isIntuneChecking
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState()
  const { overviewUser } = useAdminPermission()
  const adminCanCheckStatus = overviewUser.tenants.canCheckStatus
  const adminCanRemoveTenant = overviewUser.tenants.canRemoveTenant
  const open = Boolean(anchorEl)
  const actionClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        id='overview-tenant-action-button'
        aria-controls={open ? 'custom-action' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={actionClick}
        endIcon={<KeyboardArrowDown />}
      >
        {t(`${localization}actions`)}
      </Button>

      <StyledMenu
        id='custom-action'
        MenuListProps={{
          'aria-labelledby': 'overview-tenant-action-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {row?.serviceTenant && row?.service_type === 'App\\Models\\AzureTenant' && (
          <Box>
            <MenuItem
              onClick={e => {
                onRemoveTenantClick(e, row.serviceTenant.id)
                setAnchorEl(null)
              }}
              disabled={!adminCanRemoveTenant}
              disableRipple
            >
              <DeleteForever /> {t(`${localization}removeTenant`)}
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={e => onCheckPermissionClick(row.serviceTenant.id)}
              disabled={!adminCanCheckStatus || isIntuneChecking}
              disableRipple
            >
              <CircleSharp /> {t(`${localization}checkStatus`)}
            </MenuItem>
          </Box>
        )}

        {row?.serviceTenant && row?.service_type === 'App\\Models\\GoogleDomain' && (
          <MenuItem
            onClick={e => onCheckDomainClick(row.serviceTenant.id)}
            disabled={!adminCanCheckStatus || isDomainChecking}
            disableRipple
          >
            {isDomainChecking ? <CircularProgress size='1.5rem' /> : <CircleSharp />}
            {t(`${localization}checkStatus`)}
          </MenuItem>
        )}
      </StyledMenu>
    </>
  )
}

TenantActionButton.propTypes = {
  row: PropTypes.object,
  onRemoveTenantClick: PropTypes.func.isRequired,
  onCheckPermissionClick: PropTypes.func.isRequired,
  onCheckDomainClick: PropTypes.func.isRequired,
  isDomainChecking: PropTypes.bool.isRequired,
  isIntuneChecking: PropTypes.bool.isRequired
}

TenantActionButton.defaultProps = {
  row: {}
}

export default TenantActionButton
