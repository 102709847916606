import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import { Checkbox, Grid, Paper, Typography } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import FullPageLoading from '~/components/FullPageLoading'
import SearchToolBar from '~/components/SearchToolBar'
import TenantIcon from '~/components/layout/IconComponent'
import { selectUser } from '~/store/auth/selector'
import { useOrganisationListOverviewQuery } from '../query'
import OrganisationAzureDetails from './OrganisationAzureDetails'
import OrganisationGoogleDetails from './OrganisationGoogleDetails'
import './style.scss'

const StyledTreeItem = styled(props => <TreeItem {...props} />)(({ theme }) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 30,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  },
  // [`& .${treeItemClasses.content}`]: {
  //   padding: 0.25,
  //   margin: 0.25
  // },
  [`& .${treeItemClasses.label}`]: {
    padding: 10
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    marginRight: 0
  }
}))

const flattenOrganisations = arr => {
  return arr.flatMap(item => [item, ...flattenOrganisations(item.childrens || [])])
}

const OrgTreeItem = ({ item, setOrganisationId, user, shouldHide }) => {
  const disabled = item.tenant_id !== user.active_tenant_id || item.is_temporary_removed
  const className = `${shouldHide ? 'org-hidden' : ''} ${disabled ? 'disable' : ''}`

  return (
    <StyledTreeItem
      key={item.id}
      nodeId={`${item.id}`}
      label={
        <>
          {item.external_id} - {item.name}
          <TenantIcon item={item} />
        </>
      }
      onClick={() => {
        setOrganisationId(item.id)
      }}
      className={className}
    >
      {item.childrens &&
        item.childrens.map((i, idx) => {
          return (
            <OrgTreeItem
              key={item.id + idx}
              item={i}
              setOrganisationId={setOrganisationId}
              user={user}
              shouldHide={shouldHide}
            />
          )
        })}
    </StyledTreeItem>
  )
}

OrgTreeItem.defaultProps = {
  shouldHide: false
}

OrgTreeItem.propTypes = {
  item: PropTypes.object.isRequired,
  setOrganisationId: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  shouldHide: PropTypes.bool
}

const OrganisationTree = () => {
  const user = useSelector(selectUser)
  const [organisationId, setOrganisationId] = useState(null)
  const [search, setSearchValue] = useState('')
  const [checked, setChecked] = useState(true)

  // GET VALUE
  const { data: organisations, isLoading } = useOrganisationListOverviewQuery()

  const flatOrganisations = useMemo(() => {
    if (!organisations) {
      return []
    }

    return flattenOrganisations(organisations)
  }, [organisations])

  const organisationsIds = useMemo(() => {
    return flatOrganisations.map(v => v.id.toString())
  }, [flatOrganisations])

  const onSearch = debounce(event => setSearchValue(event.target.value), 500)

  const filterOrganizations = useMemo(() => {
    if (!search || search.length === 0) {
      return null
    }

    return flatOrganisations.filter(
      i =>
        i.name.toLowerCase().includes(search.toLowerCase()) &&
        !(i.tenant_id !== user.active_tenant_id || i.is_temporary_removed)
    )
  }, [search, flatOrganisations, user])

  if (isLoading) {
    return <FullPageLoading />
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        <Grid item sm={6} xs={12}>
          <Paper sx={{ padding: 4 }}>
            <Grid item xs={12} sm={12} sx={{ mb: 1, mr: 1 }}>
              <SearchToolBar filterSearch={search} onChange={onSearch} placeholder='schoolName' />
            </Grid>
            <Typography variant='h6'>Organisations</Typography>
            {filterOrganizations && (
              <TreeView>
                {filterOrganizations.map(item => (
                  <StyledTreeItem
                    key={item.id}
                    nodeId={`${item.id}`}
                    label={
                      <>
                        {item.external_id} - {item.name}
                        <TenantIcon item={item} />
                      </>
                    }
                    onClick={() => {
                      setOrganisationId(item.id)
                    }}
                  />
                ))}
              </TreeView>
            )}

            {!filterOrganizations && (
              <>
                <Checkbox
                  color='primary'
                  sx={{
                    pointerEvents: 'auto'
                  }}
                  tabIndex={-1}
                  disableRipple
                  checked={checked}
                  onChange={event => {
                    setChecked(v => !v)
                  }}
                />
                Hide all deleted or not related organisation
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: '100%',
                    width: '100%',
                    flexGrow: 1,
                    overflowY: 'auto'
                  }}
                  expanded={organisationsIds}
                >
                  {organisations &&
                    organisations.map(rootItem => {
                      return (
                        <OrgTreeItem
                          item={rootItem}
                          setOrganisationId={setOrganisationId}
                          key={rootItem.id}
                          user={user}
                          shouldHide={checked}
                        />
                      )
                    })}
                </TreeView>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12}>
          {user?.is_google && <OrganisationGoogleDetails organisationId={organisationId} />}
          {user?.is_azure && <OrganisationAzureDetails organisationId={organisationId} />}
        </Grid>
      </Grid>
    </>
  )
}

export default OrganisationTree
