import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserDetailInformation } from '~/store/user/selector'
import { useChangeActiveTenantMutation } from '../Detail/mutate'

const UserOrgs = ({ localization, canChangeTenant }) => {
  const [isChanged, setIsChanged] = useState(false)

  const selectedUser = useSelector(selectUserDetailInformation)
  const [selectedTenant, setSelectedTenant] = useState(selectedUser?.tenant?.name)

  const onChangeTenant = useCallback(event => {
    setIsChanged(true)
    setSelectedTenant(event.target.innerHTML)
  }, [])

  const { t } = useTranslation()
  const { mutate: changeTenant, isLoading: isSaving } = useChangeActiveTenantMutation({})

  const handleUpdateTenant = () => {
    const tenantType = selectedUser?.is_azure ? 'azure' : 'google'
    changeTenant({ name: selectedTenant, type: tenantType })
    setIsChanged(false)
  }

  const label = selectedUser?.is_azure
    ? t(`${localization}.tenant`)
    : t(`${localization}.googleDomain`)

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Typography gutterBottom variant='body1'>
          CHANGE {label.toUpperCase()}
        </Typography>

        <Grid container spacing={2}>
          {selectedUser?.tenants && (
            <Autocomplete
              id='tenant'
              name='tenant'
              freeSolo
              value={selectedUser?.tenant?.name}
              options={selectedUser?.tenants.map(option => option.name)}
              onChange={e => {
                setIsChanged(true)
                setSelectedTenant(event.target.innerHTML)
              }}
              sx={{ mt: 2, ml: 2, width: '70%' }}
              renderInput={params => (
                <TextField {...params} label={label} onChange={onChangeTenant} />
              )}
            />
          )}
          <Grid item md={3}>
            <LoadingButton
              variant='contained'
              size='large'
              disabled={!isChanged}
              loading={isSaving}
              sx={{
                textTransform: 'none',
                backgroundColor: '#76B72A',
                height: '100%'
              }}
              type='submit'
              onClick={handleUpdateTenant}
            >
              {t(`${localization}.save`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

UserOrgs.propTypes = {
  localization: PropTypes.string,
  canChangeTenant: PropTypes.bool
}

UserOrgs.defaultProps = {
  canChangeTenant: true
}

export default UserOrgs
