import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { rejectAzUser } from '~/api/admin'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useRejectAzUserMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async id => {
      return await rejectAzUser(id)
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          message: 'User has been rejected',
          variant: 'success'
        })
        queryClient.invalidateQueries('tenant_registrations')
      },
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message: message || 'Failed to reject user',
          variant: 'error'
        })
      }
    }
  )
}
