import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import FullPageLoading from '~/components/FullPageLoading'
import { ADMIN_USER_ROLE } from '~/constants/Routes'
import Body from './Body'
import { useGetAllPermissionsQuery, useRoleDetailQuery } from './query'

const RoleDetail = () => {
  const urlParams = useParams()
  const { data: queryResponse, isLoading } = useRoleDetailQuery({ id: urlParams.id })

  const { data: permissions } = useGetAllPermissionsQuery(queryResponse?.guard_name)

  if (isLoading) {
    return <FullPageLoading />
  }

  // update breadCrumb
  const BreadComponents = [
    { title: 'Overview' },
    {
      route: ADMIN_USER_ROLE,
      title: 'Roles'
    },
    {
      page: 'detail',
      title: queryResponse.name
    }
  ]

  return (
    <>
      <BreadCrumb breadcrumbs={BreadComponents} />
      {permissions && queryResponse && (
        <Body permissions={permissions} useRoleData={queryResponse}></Body>
      )}
    </>
  )
}
export default RoleDetail
