import { Box, Button, Stack, Toolbar as MuiToolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'

const Toolbar = props => {
  const { t } = useTranslation()
  return (
    <>
      <MuiToolbar disableGutters={false}>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction='row'
          justifyContent='right'
          alignItems='right'
          minWidth='46px'
          spacing={2}
        >
          <Button
            variant='contained'
            size='small'
            sx={{
              textTransform: 'none',
              backgroundColor: '#76B72A'
            }}
            type='submit'
          >
            {t(`${props.localization}.save`)}
          </Button>
        </Stack>
      </MuiToolbar>
    </>
  )
}

Toolbar.propTypes = {
  localization: propTypes.string
}

export default Toolbar
