import { Stack, Typography } from '@mui/material'
import CustomModal from '~/components/Modal/CustomModal'
import { LoadingButton } from '~/components/Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useBulkDeleteDeviceMutation, useDeregisterDeviceMutation } from '../query'

const ModalBulkDeRegister = ({
  open,
  handleCloseModal,
  deviceToDeregist,
  setOpen,
  setSelected,
  selected,
  setIsSelectAllSelected,
  refetchDeviceList
}) => {
  const { t } = useTranslation()

  const { isLoading: isDeletingADevice, mutateAsync: deregistDevice } =
    useDeregisterDeviceMutation()

  const { mutateAsync: bulkdDeregistDevices, isLoading: isBulkDeletingDevices } =
    useBulkDeleteDeviceMutation()

  const handleSubmitModal = async () => {
    if (deviceToDeregist === null) {
      setOpen(false)
      return
    }

    if (_.isArray(deviceToDeregist)) {
      await bulkdDeregistDevices(selected)
    } else {
      await deregistDevice(deviceToDeregist)
    }

    await refetchDeviceList()
    setIsSelectAllSelected(false)
    setSelected([])
    setOpen(false)
  }

  return (
    <CustomModal open={open} onClose={handleCloseModal}>
      <Typography variant='h6'>
        {Array.isArray(deviceToDeregist)
          ? 'Do you want to deregister these devices?'
          : `Do you want to deregister this device: ${deviceToDeregist}?`}
      </Typography>
      <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
        <LoadingButton onClick={handleCloseModal}>{t('pages.organisation.cancel')}</LoadingButton>
        <LoadingButton
          onClick={handleSubmitModal}
          loading={isDeletingADevice || isBulkDeletingDevices}
        >
          {t('pages.organisation.confirm')}
        </LoadingButton>
      </Stack>
    </CustomModal>
  )
}

ModalBulkDeRegister.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  deviceToDeregist: PropTypes.array,
  setOpen: PropTypes.func,
  setSelected: PropTypes.func,
  selected: PropTypes.array,
  setIsSelectAllSelected: PropTypes.func,
  refetchDeviceList: PropTypes.func
}

export default ModalBulkDeRegister
