import { Box, Container, Grid, Link, Typography } from '@mui/material'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectIsAuthenticating, selectSsoRedirectUrl } from '../../store/auth/selector'
import BackgroundBody from './BackgroundBody'
import LoginHeader from './Header'
import FullPageLoading from '~/components/FullPageLoading'
import privacyPolicy from '~/assets/PrivacyPolicyForEasy4EnrollApplication.pdf'
import termOfService from '~/assets/Easy4EnrollTermsofService.pdf'

const Login = () => {
  const { t } = useTranslation()
  const { showSnackbar, snackbar } = useSnackbar()

  const ssoRedirectUrl = useSelector(selectSsoRedirectUrl)

  const search = useLocation()
  const error = useMemo(() => {
    return new URLSearchParams(search.search).get('error')
  }, [search])

  const status = useMemo(() => {
    return new URLSearchParams(search.search).get('status')
  }, [search])

  if (ssoRedirectUrl) {
    window.location.href = ssoRedirectUrl
  }

  useEffect(() => {
    if (error) {
      showSnackbar(t('message.authenticationFail'), 'error')
    }
    if (status) {
      showSnackbar(t(`message.${status}`), 'warning')
    }
  }, [error, showSnackbar, status, t])

  const isAuthenticating = useSelector(selectIsAuthenticating)
  if (isAuthenticating) {
    return <FullPageLoading />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
        position: 'absolute'
      }}
    >
      <LoginHeader />
      <BackgroundBody>
        <Container maxWidth='lg'>
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  position: 'relative',
                  marginTop: 10,
                  color: 'white'
                }}
              >
                <Typography component='h1' variant='h4' color='inherit' gutterBottom>
                  {t('login.welcomeTitle')}
                </Typography>
                <Typography component='h6' variant='h6' color='inherit' gutterBottom>
                  <Link
                    href={privacyPolicy}
                    target='_blank'
                    style={{
                      color: '#fff',
                      fontWeight: 'normal',
                      fontSize: '18px',
                      textDecorationColor: 'white'
                    }}
                  >
                    {t('login.privacyStatement')}
                  </Link>
                </Typography>
                <Typography component='h6' variant='h6' color='inherit' gutterBottom>
                  <Link
                    href={termOfService}
                    target='_blank'
                    style={{
                      color: '#fff',
                      fontWeight: 'normal',
                      fontSize: '18px',
                      textDecorationColor: 'white'
                    }}
                  >
                    {t('login.termOfService')}
                  </Link>
                </Typography>
                <Typography component='h6' variant='h6' color='inherit' gutterBottom>
                  <Link
                    href='https://rentcompany.nl/en/easy4enroll'
                    target='_blank'
                    style={{
                      color: '#fff',
                      fontWeight: 'normal',
                      fontSize: '18px',
                      textDecorationColor: 'white'
                    }}
                  >
                    {t('login.faq')}
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </BackgroundBody>

      {snackbar}
    </Box>
  )
}

export default Login
