import { enqueueSnackbar } from 'notistack'
import { useQuery, useQueryClient } from 'react-query'
import organisationApi from '~/api/organisationApi'
import overviewTenantApi from '~/api/overviewTenantApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useGroupTagsQuery = () => {
  return useQuery(
    ['group_tag'],
    async () => {
      const { data } = await organisationApi.listGroupTagsApi()
      return { data: data.data }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useOverviewGroupTagsQuery = tenantId => {
  return useQuery(
    ['overview_group_tag'],
    async () => {
      const { data } = await overviewTenantApi.listGroupTagsApi(tenantId)
      return { data: data.data }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useOvervbiewGroupTagLoanerQuery = tenantId => {
  return useQuery(['overview_group_tags_loaner'], async () => {
    const { data } = await overviewTenantApi.listGroupTagsLoanerApi(tenantId)
    return data
  })
}

export const useOverviewGroupTagStaffQuery = tenantId => {
  return useQuery(['overview_group_tags_staff'], async () => {
    const { data } = await overviewTenantApi.listGroupTagsStaffApi(tenantId)
    return data
  })
}

export const useHealthCheckGoogleDomainQuery = (serviceTenantId, setServiceTenantId) => {
  const queryClient = useQueryClient()
  return useQuery(
    ['overview_healthcheck_google_domain'],
    async () => {
      const { data } = await overviewTenantApi.healthCheckGoogleDomainApi(serviceTenantId)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.message ?? 'Health Check Updated!',
          variant: 'success'
        })
        setServiceTenantId(null)
        queryClient.invalidateQueries('tenant_details')
      },
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false,
      enabled: !!serviceTenantId
    }
  )
}
