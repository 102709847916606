import LargeBackgound from '../../assets/images/large_background/easy4device_using_laptop.jpg'
import PropTypes from 'prop-types'

const BackgroundBody = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${LargeBackgound})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        flex: 1
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,.3)'
        }}
      >
        {children}
      </div>
    </div>
  )
}

BackgroundBody.propTypes = {
  children: PropTypes.node
}

export default BackgroundBody
