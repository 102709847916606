import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { updateDisableSuperUser, updateSuperUserRole } from '~/api'

export const useUpdateRoleMutation = props => {
  const urlParams = useParams()
  const queryClient = useQueryClient()

  return useMutation(
    ['admin_role_update'],
    async values => {
      return await updateSuperUserRole(urlParams.id, { role_name: values })
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message ? data.message : 'Save successful!',
          variant: 'success'
        })
        queryClient.invalidateQueries('overview_user_info')

        return data
      },
      onError: error => {
        enqueueSnackbar({
          message: error.message ? error.message : 'Save failed!',
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useUpdateDisableSuperUserMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ['super_admin_update_disable'],
    async ({ id, disabled }) => {
      const { data } = await updateDisableSuperUser(id, { disabled })
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message ? data.message : 'Save successful!',
          variant: 'success'
        })
        queryClient.invalidateQueries('overview_super_user_info')

        return data
      },
      onError: error => {
        enqueueSnackbar({
          message: error.message ? error.message : 'Save failed!',
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
