import {
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import useTable from '~/hooks/useTable'
import { CompanyTenantsHeadCells } from './CompanyHeadCells'
import { useTenantsByCompanyIdQuery } from './query'

const CompanyTenantTableBody = ({ isLoading, tenants }) => {
  const { overviewUser } = useAdminPermission()
  if (isLoading) {
    return <TableLoading colSpan={CompanyTenantsHeadCells.length} />
  }

  const renderIdCol = row => (
    <>
      {overviewUser.tenants.canViewDetail ? (
        <Link to={`/overview/tenants/${row.id}`}>
          <MuiLink>{row.id}</MuiLink>
        </Link>
      ) : (
        <>{row.id}</>
      )}
    </>
  )

  return (
    <TableBody>
      {tenants.length === 0 && <DataNotFound colSpan={CompanyTenantsHeadCells.length} />}
      {tenants.length > 0 &&
        tenants.map((row, index) => {
          return (
            <TableRow hover key={index}>
              <TableCell align='left'>{renderIdCol(row)}</TableCell>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

CompanyTenantTableBody.propTypes = {
  isLoading: PropTypes.bool,
  tenants: PropTypes.array
}

CompanyTenantTableBody.defaultProps = {
  isLoading: false,
  tenants: []
}

const CompanyTenant = () => {
  const { id } = useParams()

  const {
    data: tenants,
    isLoading,
    order,
    page,
    orderBy,
    sortHandler,
    setPage
  } = useTable({
    query: useTenantsByCompanyIdQuery,
    queryAttributes: {
      id
    },
    initialStates: {
      order: 'asc',
      page: 1,
      orderBy: 'name'
    }
  })

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
          <TableHeader
            headCells={CompanyTenantsHeadCells}
            onRequestSort={sortHandler}
            order={order}
            orderBy={orderBy}
            localization=''
          />
          <CompanyTenantTableBody isLoading={isLoading} tenants={tenants?.data || []} />
        </Table>
      </TableContainer>
      <PaginationTable
        data={tenants}
        page={page}
        handleChangePage={(_, value) => setPage(value)}
        size='medium'
      />
    </Paper>
  )
}

export default CompanyTenant
