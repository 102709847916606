import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useLanguage = () => {
  const { t, i18n } = useTranslation()
  const appLanguage = i18n.language

  const languages = [
    { label: t('general.dutch'), id: 'nl' },
    { label: t('general.english'), id: 'en' }
  ]

  const defaultLang = languages.find(lang => lang.id === appLanguage) || languages[0]
  const [language, setLanguage] = useState(defaultLang)

  return {
    defaultLang,
    languages,
    language,
    setLanguage
  }
}

export default useLanguage
