import { Autocomplete, Box, FormControl, TextField } from '@mui/material'
import debounce from 'lodash.debounce'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import TenantIcon from './layout/IconComponent'
import { useUpdateActiveTenantMutation } from './layout/query'

const TenantSelect = () => {
  const user = useSelector(selectUser)
  const [activeTenant, setActiveTenant] = useState(null)
  const [inputWidth, setInputWidth] = useState('0ch')
  const [minWidth, setMinWidth] = useState('400px')
  const updateActiveTenant = useUpdateActiveTenantMutation()

  // PROCESS CHANGE ACTIVE TENANT
  const handleActiveTenantChange = debounce(value => {
    updateActiveTenant.mutate(value?.id)
  }, 300)

  const tenants = useMemo(() => {
    const tenants = [...(user?.tenants || [])]
    return tenants.sort((a, b) => a.name?.localeCompare(b?.name))
  }, [user])

  useEffect(() => {
    const userTenant = tenants.find(tenant => tenant.id === user?.active_tenant_id)
    if (userTenant) {
      setActiveTenant({
        id: userTenant.id,
        label: userTenant?.name
      })
    }
  }, [tenants, user])

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth <= 768 || !activeTenant) {
        const ratio = window.innerWidth / 768
        setInputWidth(`${40 * ratio}ch`)
        setMinWidth(`${1 * ratio}vw`)
      } else {
        setInputWidth(`${activeTenant.label.length}ch`)
        setMinWidth('400px')
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [activeTenant])

  return (
    <FormControl
      sx={{
        mb: '5px',
        mt: '5px',
        width: '100%',
        p: '0 3px'
      }}
    >
      <Autocomplete
        id='tenant-select'
        name='tenant'
        label=''
        disableClearable
        value={activeTenant}
        options={
          tenants?.map(option => ({
            label: option.name,
            id: option.id,
            is_azure: option.service_type === 'App\\Models\\AzureTenant',
            is_google: option.service_type === 'App\\Models\\GoogleDomain'
          })) || []
        }
        onChange={(e, value) => {
          handleActiveTenantChange(value)
        }}
        getOptionLabel={option => option.label || ''}
        sx={{ backgroundColor: 'white', width: inputWidth, minWidth }}
        renderOption={(props, option) => (
          <li {...props}>
            <Box display='flex' alignItems='center' gap='8px'>
              <Box alignSelf='center'>
                <TenantIcon item={option} />
              </Box>
              {option.label}
            </Box>
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent'
                }
              }
            }}
            placeholder='Tenant/Domain'
          />
        )}
      />
    </FormControl>
  )
}

export default TenantSelect
