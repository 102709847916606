import { Stack, Typography } from '@mui/material'
import CustomModal from '~/components/Modal/CustomModal'
import { LoadingButton } from '~/components/Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useRepairDeviceMutation } from '../query'

const ModalIsRepaired = ({
  openIsRepairModal,
  handleCloseIsRepairModal,
  deviceToInRepair,
  setOpenIsRepairModal,
  refetchDeviceList
}) => {
  const { t } = useTranslation()

  const { mutateAsync: repairDeviceMute, isLoading: isRepairDeviceLoading } =
    useRepairDeviceMutation()

  const handleSubmitIsRepairModal = async () => {
    if (deviceToInRepair === null) {
      setOpenIsRepairModal(false)
      return
    }

    await repairDeviceMute({ id: deviceToInRepair?.id, type: 'IS_REPAIRED' })
    await refetchDeviceList()

    setOpenIsRepairModal(false)
  }

  return (
    <CustomModal open={openIsRepairModal} onClose={handleCloseIsRepairModal}>
      <Typography variant='h6'>
        {t('pages.device.putDeviceToIsRepaired')} : {deviceToInRepair?.serial}
      </Typography>
      <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
        <LoadingButton onClick={handleCloseIsRepairModal}>
          {t('pages.organisation.cancel')}
        </LoadingButton>
        <LoadingButton onClick={handleSubmitIsRepairModal} loading={isRepairDeviceLoading}>
          {t('pages.organisation.confirm')}
        </LoadingButton>
      </Stack>
    </CustomModal>
  )
}

ModalIsRepaired.propTypes = {
  openIsRepairModal: PropTypes.bool,
  handleCloseIsRepairModal: PropTypes.func,
  deviceToInRepair: PropTypes.object,
  setOpenIsRepairModal: PropTypes.func,
  refetchDeviceList: PropTypes.func
}

export default ModalIsRepaired
