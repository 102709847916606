import { Breadcrumbs, Divider, Typography } from '@mui/material'
import GroupTagTable from './GroupTagTable/GroupTagTable'

const GroupTags = () => {
  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Group Tags</Typography>
      </Breadcrumbs>
      <Divider />
      <GroupTagTable />
    </>
  )
}

export default GroupTags
