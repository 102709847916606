import { useSelector } from 'react-redux'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { formatDateTime, isOverviewPage } from '~/utils/helpers'
import PropTypes from 'prop-types'
import { selectUser } from '~/store/auth/selector'
import { selectAdmin } from '~/store/adminAuth/selector'

const DateTimeLocale = ({ datetime, formatHour = false }) => {
  const format = useSelector(selectFormatDateState)
  const selector = isOverviewPage ? selectAdmin : selectUser
  const user = useSelector(selector)

  if (!datetime) {
    return 'N/A'
  }

  return formatDateTime(datetime, format, user.timezone, formatHour)
}

DateTimeLocale.propTypes = { datetime: PropTypes.string }

export default DateTimeLocale
