import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import ProductionLogo from '~/assets/images/logo/easy4E.png'
import StagingLogo from '~/assets/images/logo/easy4E_staging.png'
import { ADMIN_OVERVIEW } from '~/constants/Routes'
import { KEY_ADMIN_AUTH_TOKEN } from '~/constants/constants'
import { useAdminAuthenticateMutation } from './mutation'

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required()
  })
  .required()

const AdminLogin = () => {
  useEffect(() => {
    const isAdminAuth = localStorage.getItem(KEY_ADMIN_AUTH_TOKEN)
    if (isAdminAuth) {
      window.location.href = ADMIN_OVERVIEW
    }
  })
  const { mutate: adminLogin, isLoading } = useAdminAuthenticateMutation()

  const onSubmit = async values => {
    adminLogin(values)
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        bgcolor: '#f0f0f0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <div style={{ marginBottom: 20 }}>
        {import.meta.env.VITE_ENVIRONMENT === 'development' ? (
          <img src={StagingLogo} width={150} alt='' />
        ) : (
          <img src={ProductionLogo} width={150} alt='' />
        )}
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          bgcolor: '#fff',
          borderRadius: 8,
          px: 4,
          py: 4
        }}
      >
        <Typography>Admin please login for continue</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 2 }}>
            <Typography>
              Email <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              sx={{ width: '350px' }}
              fullWidth
              variant='outlined'
              {...register('email')}
              error={!!errors.email}
              helperText={<>{errors.email?.message}</>}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography>
              Password <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              fullWidth
              type='password'
              variant='outlined'
              {...register('password')}
              error={!!errors.password}
              helperText={<>{errors.password?.message}</>}
            />
          </Box>

          <Box sx={{ textAlign: 'center' }}>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              sx={{ mt: 1, mr: 1, textTransform: 'none', backgroundColor: '#76B72A' }}
            >
              Submit
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default AdminLogin
