import { useTranslation } from 'react-i18next'
import { Autocomplete, Grid, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import EntitySection from './EntitySection'
import { Controller } from 'react-hook-form'

const CompanySchoolFormSelector = ({
  control,
  mostUsedEntityId,
  orgData,
  setValue,
  schoolName,
  errors,
  schoolRenderOption,
  schoolDisableOption
}) => {
  const { t } = useTranslation()

  const [entity, setEntity] = useState(null)
  const [school, setSchool] = useState(null)

  const schools = useMemo(() => {
    let result = orgData || []
    if (entity) {
      result = result.filter(school => school?.entity_id === entity.id)
    }

    return result.map(option => ({
      label: option.name + ' - ' + option.external_id,
      is_google: option.tenant?.service_type === 'App\\Models\\GoogleDomain',
      is_azure: option.tenant?.service_type === 'App\\Models\\AzureTenant',
      entity_id: option.entity_id,
      tenant_id: option.tenant_id,
      external_id: option.external_id,
      id: option.id
    }))
  }, [orgData, entity])

  useEffect(() => {
    if (!school || !schools.find(v => v.id === school.id)) {
      setSchool(null)
      setValue('school_id', '')
    }
  }, [school, schools, setValue])

  return (
    <>
      <Grid item mb={2}>
        <Typography>Entity Id</Typography>
        <EntitySection
          setValue={setValue}
          errors={errors}
          control={control}
          entity={entity}
          setEntity={setEntity}
          school={school}
          mostUsedEntityId={mostUsedEntityId}
        />
      </Grid>
      <Grid item mb={2}>
        {schoolName && <Typography>{schoolName}</Typography>}
        <Typography>{t('pages.adminOverview.inviteAzureTenant.schoolId')}</Typography>
        <Controller
          render={({ field: { onChange }, ...props }) => (
            <Autocomplete
              {...props}
              value={school}
              options={schools}
              onChange={(e, data) => {
                onChange(data?.external_id)
                setSchool(data)
              }}
              sx={{ mt: 1 }}
              getOptionLabel={o => o.label || ''}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('pages.adminOverview.inviteAzureTenant.schoolId')}
                  error={!!errors.school_id}
                  helperText={<>{errors.school_id?.message}</>}
                />
              )}
              getOptionDisabled={schoolDisableOption}
              {...(schoolRenderOption && { renderOption: schoolRenderOption })}
            />
          )}
          defaultValue={school?.external_id}
          name={'school_id'}
          control={control}
        />
      </Grid>
    </>
  )
}

CompanySchoolFormSelector.propTypes = {
  mostUsedEntityId: PropTypes.number,
  orgData: PropTypes.array,
  setValue: PropTypes.func,
  schoolName: PropTypes.string,
  control: PropTypes.object.isRequired,
  schoolRenderOption: PropTypes.object,
  schoolDisableOption: PropTypes.func,
  errors: PropTypes.shape({
    school_id: PropTypes.shape({
      message: PropTypes.string
    })
  }).isRequired
}

CompanySchoolFormSelector.defaultProps = {
  mostUsedEntityId: null,
  schoolName: '',
  companies: {},
  orgData: [],
  schoolRenderOption: null,
  schoolDisableOption: () => {},
  errors: {
    school_id: null,
    entity_id: null
  }
}

export default CompanySchoolFormSelector
