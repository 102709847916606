import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ScrollToTop from 'react-scroll-up'

const ScrollUpButton = () => {
  return (
    <ScrollToTop showUnder={160}>
      <ArrowCircleUpIcon fontSize='large' sx={{ color: '#76B72A' }} />
    </ScrollToTop>
  )
}

export default ScrollUpButton
