import adminAxiosClient from './adminBase'

export const getHealthCheckStatuses = tenantId =>
  adminAxiosClient.get(`api/overview/health-check/${tenantId}/statuses`)

export const healthCheckSyncData = tenantId =>
  adminAxiosClient.post(`api/overview/health-check/${tenantId}/sync-data`)

export const syncDeviceApi = tenantId =>
  adminAxiosClient.post(`api/overview/tenants/${tenantId}/sync-devices`)

export const getTenantInfo = id => adminAxiosClient.get(`api/overview/tenants/${id}`)

export const getTenantDetail = tenantId => adminAxiosClient.get(`api/overview/tenants/${tenantId}`)

// OVERVIEW > USERS > REGISTRATIONS
export const rejectAzUser = id => adminAxiosClient.post(`api/overview/registrations/${id}/reject`)
export const getRegistrations = params =>
  adminAxiosClient.get('api/overview/registrations', { params })
export const getRegistrationDetail = id => adminAxiosClient.get(`api/overview/registrations/${id}`)

// OVERVIEW > TENNANTS > TENANT GOOGLE DETAILS
export const updateGoogleCustomerId = (id, customerId) =>
  adminAxiosClient.post(`api/overview/google-domains/${id}/update-customer-id`, {
    customer_id: customerId
  })
export const updateSchoolToken = (id, token) =>
  adminAxiosClient.post(`api/overview/organisations/${id}/update-provision-token`, {
    token
  })
export const updateServiceTenantToken = (id, token) =>
  adminAxiosClient.post(`api/overview/google-domains/${id}/update-zte-token`, {
    pre_provisioning_token: token
  })
export const updateSchoolTenantId = payload =>
  adminAxiosClient.post(`api/overview/tenants/${payload.id}/add-school`, {
    school_id: payload.school_id,
    entity_id: payload.entity_id
  })

// OVERVIEW > ADMINISTRATOR > SETTINGS
export const getAppointmentLinkSettings = () =>
  adminAxiosClient.get('api/overview/configs/appointment-link')
export const updateAppointmentLinkSettings = data =>
  adminAxiosClient.put('api/overview/configs/appointment-link', data)
