import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import CustomAvatar from './CustomAvatar'
import LabelStatusUser from './LabelStatusUser'
import UserRole from './UserRole'

const localization = 'pages.adminOverview.users.detail'

const UserInformation = ({ data }) => {
  const { overviewUser } = useAdminPermission()
  const canChangeRole = overviewUser.administrator.users.canChangeAdminRole
  const selectedUser = data
  const avatar = selectedUser?.is_google
    ? selectedUser?.google_avatar ?? ''
    : selectedUser?.media?.preview_url ?? ''

  return (
    <>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardContent>
            <Box
              sx={{
                width: '100%',
                bgcolor: 'background.paper'
              }}
            >
              <Box sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems='center'>
                  <Grid item xs={12} md={2}>
                    <CustomAvatar url={avatar} sx={{ width: 65, height: 65 }} />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Typography gutterBottom variant='h6' component='div'>
                      {selectedUser?.first_name} {selectedUser?.last_name}
                    </Typography>
                    <Typography gutterBottom variant='h8' component='div'>
                      {selectedUser?.email}
                    </Typography>
                    <Typography variant='h8' component='div'>
                      <LabelStatusUser
                        status={selectedUser?.disabled === true ? 'disabled' : selectedUser?.status}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider variant='middle' />
              <UserRole localization={localization} canChangeRole={canChangeRole} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

UserInformation.propTypes = {
  data: PropTypes.object
}

export default UserInformation
