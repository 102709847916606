import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import organisationApi from '~/api/organisationApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const usePreProvisionTokensQuery = () => {
  return useQuery(
    ['preprovision_token'],
    async () => {
      const { data } = await organisationApi.listPreprovisioningTokens()
      return { data: data.data }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useAllPreProvisionTokensQuery = () => {
  return useQuery(
    ['all_preprovision_token'],
    async () => {
      const { data } = await organisationApi.listAllPreprovisioningTokens()
      return { data: data.data }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
