import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import CompanySchoolFormSelector from '~/components/CompanySchoolFormSelector'
import FullPageLoading from '~/components/FullPageLoading'
import { ADMIN_INVITE_AZURE_TENANTS, ADMIN_OVERVIEW } from '~/constants/Routes'
import useLanguage from '~/hooks/useLanguage'
import { useAzureRegistrationDetailQuery } from '../Registrations/query'
import { useInviteAzureTenantMutation } from './mutation'
import { useGetOrgsQuey } from './query'
import { enqueueSnackbar } from 'notistack'
import { parseApiErrorMessage } from '~/utils/helpers'

const CheckboxComponent = ({ field: props }) => (
  <Checkbox {...props} checked={props.value} onChange={e => props.onChange(e.target.checked)} />
)

CheckboxComponent.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

const initialValue = {
  tenant_domain: '',
  name: '',
  tenant_admin_email: '',
  language: 'en',
  school_id: '',
  apply_to_child: false,
  entity_id: ''
}

const InviteAzureTenant = () => {
  const [searchParams] = useSearchParams()
  const regId = searchParams.get('registration-id')
  const { data: userRegistration, isLoading: isLoadingReg } = useAzureRegistrationDetailQuery(regId)
  const { languages, language, setLanguage } = useLanguage()
  const localization = 'pages.adminOverview.inviteAzureTenant'
  const { t } = useTranslation()

  const schema = yup
    .object({
      tenant_domain: yup.string().required(t('validation.tenantDomain')),
      name: yup.string().required(t('validation.nameRequired')),
      tenant_admin_email: yup.string().email().required(t('validation.emailRequired')),
      language: yup.string().nullable(),
      registration_id: yup.string().nullable(),
      school_id: yup
        .string()
        .nullable()
        .test({
          name: 'schoolEntityCheck',
          message: t('validation.schoolAndEntityReuiredTogether'),
          test: function (value) {
            const { entity_id } = this.parent
            if ((value && !entity_id) || (!value && entity_id)) {
              return false
            }
            return true
          }
        }),
      entity_id: yup
        .string()
        .nullable()
        .test({
          name: 'entitySchoolCheck',
          message: t('validation.schoolAndEntityReuiredTogether'),
          test: function (value) {
            const { school_id } = this.parent
            if ((value && !school_id) || (!value && school_id)) {
              return false
            }
            return true
          }
        })
    })
    .required()

  const defaultValues = useMemo(() => {
    if (regId !== null && userRegistration && userRegistration?.status === 'pending') {
      let tenantDomain = userRegistration.tenant_domain
      if (tenantDomain?.endsWith('.onmicrosoft.com')) {
        tenantDomain = tenantDomain.replace('.onmicrosoft.com', '')
      }

      const userLang = userRegistration.language ?? 'en'

      return {
        tenant_domain: tenantDomain ?? '',
        name: userRegistration.name ?? '',
        tenant_admin_email: userRegistration.email ?? '',
        registration_id: regId,
        language: userLang,
        school_id: '',
        apply_to_child: false,
        entity_id: ''
      }
    } else {
      return initialValue
    }
  }, [regId, userRegistration])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const { data: orgData } = useGetOrgsQuey()
  const { mutate, isLoading } = useInviteAzureTenantMutation()
  const navigate = useNavigate()
  const onSubmit = async values => {
    mutate(values, {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.data?.message ?? 'Successfull Invite Azure tenant',
          variant: 'success'
        })
        navigate({ pathname: ADMIN_OVERVIEW })
      },
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      }
    })
  }

  const schoolName = regId
    ? `${t(`${localization}.userSchool`)}: ${userRegistration?.organisation_name}`
    : ''

  // To handle update and reset form
  useEffect(() => {
    if (regId === null) {
      reset(initialValue)
    } else {
      reset(defaultValues)
    }
  }, [defaultValues, regId, reset])

  useEffect(() => {
    if (userRegistration && !userRegistration.is_azure) {
      navigate({ pathname: ADMIN_INVITE_AZURE_TENANTS })
    }
  }, [navigate, userRegistration])

  if (isLoadingReg) {
    return <FullPageLoading />
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.inviteAzureTenant')}</Typography>
      </Breadcrumbs>
      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 2, mt: 5 }}>
          <Typography>
            {t(`${localization}.tenantDomain`)}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <TextField
              {...register('tenant_domain')}
              variant='outlined'
              error={!!errors.tenant_domain}
              helperText={<>{errors.tenant_domain?.message}</>}
            />
            <Typography>.onmicrosoft.com</Typography>
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography>
            {t(`${localization}.name`)}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            {...register('name')}
            fullWidth
            variant='outlined'
            error={!!errors.name}
            helperText={<>{errors.name?.message}</>}
          />
        </Box>

        <CompanySchoolFormSelector
          errors={errors}
          control={control}
          orgData={orgData}
          schoolName={schoolName}
          setValue={setValue}
        />

        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Controller name='apply_to_child' control={control} render={CheckboxComponent} />
            }
            style={{ fontStyle: 'italic' }}
            label={t('pages.adminOverview.applyToChild')}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography>
            {t(`${localization}.tenantAdminEmail`)}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            {...register('tenant_admin_email')}
            fullWidth
            variant='outlined'
            error={!!errors.tenant_admin_email}
            helperText={<>{errors.tenant_admin_email?.message}</>}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography> {t(`${localization}.language`)}</Typography>
          <Controller
            render={({ field: { onChange }, ...props }) => (
              <Autocomplete
                value={language}
                options={languages.map(option => option)}
                onChange={(e, value) => {
                  onChange(value.id)
                  setLanguage(value)
                }}
                sx={{ mt: 1 }}
                renderInput={params => (
                  <TextField {...params} label={t(`${localization}.language`)} />
                )}
              />
            )}
            defaultValue={language?.id}
            name={'language'}
            control={control}
          />
        </Box>

        <Stack direction='row-reverse'>
          <LoadingButton variant='contained' type='submit' loading={isLoading}>
            {!regId ? t('button.invite') : t('button.setup')}
          </LoadingButton>
        </Stack>
      </form>
    </>
  )
}

export default InviteAzureTenant
