import axiosClient from './base'

class OrganisationApi {
  listOrganisationsApi = params => axiosClient.get('api/organisations', { params })
  listGroupTagsApi = () => axiosClient.get('api/me/group-tags')
  listGroupTagsLoanerApi = () => axiosClient.get('api/me/group-tags-loaner')
  listGroupTagsStaffApi = () => axiosClient.get('api/me/group-tags-staff')
  listOrgOverviewApi = () => axiosClient.get('api/me/organisations-overview')
  listPreprovisioningTokens = () => axiosClient.get('api/me/provision-tokens')
  listAllPreprovisioningTokens = () => axiosClient.get('api/me/all-provision-tokens')
  updateGroupTagApi = (id, tags) =>
    axiosClient.post(`api/organisations/${id}/update-group-tag`, {
      group_tag: tags.group_tag,
      group_tag_staff: tags.group_tag_staff,
      group_tag_loaner: tags.group_tag_loaner
    })

  updatePreprovisionTokenApi = (id, token) =>
    axiosClient.post(`api/organisations/${id}/update-provision-token`, {
      token
    })

  showOrganisationsApi = id => axiosClient.get(`api/organisations/${id}`)
  removeOrganisationFromTenantApi = id =>
    axiosClient.post(`api/organisations/${id}/remove-organisation-from-tenant`)
}

export default new OrganisationApi()
