import { createSlice } from '@reduxjs/toolkit'

export const userStateSlice = createSlice({
  name: 'userState',
  initialState: {
    userState: null,
    userDetailInformation: null,
    userPermissions: []
  },
  reducers: {
    setUserState: (state, action) => {
      state.userState = action.payload
    },
    setUserDetailInformation: (state, action) => {
      state.userDetailInformation = action.payload
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload
    }
  }
})

export const { setUserState, setUserDetailInformation, setUserPermissions } = userStateSlice.actions

export default userStateSlice
