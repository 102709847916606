import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ThankYouPage = () => {
  const { t } = useTranslation()
  const localization = 'pages.userRegister.thankYouPage.'

  return (
    <div>
      <Box
        sx={{
          py: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <CheckCircleIcon color='success' sx={{ fontSize: 40, mb: 4 }} />
        <Typography
          variant='h4'
          gutterBottom
          sx={{
            fontSize: '2em',
            fontWeight: 'bold'
          }}
        >
          {t(`${localization}title`)}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ py: 1 }}>{t(`${localization}thankYouForRegister`)}</Typography>
        <Typography sx={{ py: 1 }}>{t(`${localization}yourSubmission`)}</Typography>
        <Typography sx={{ py: 1 }}>{t(`${localization}whenApproved`)}</Typography>
        <Typography sx={{ py: 2 }}>
          {t('pages.userRegister.bodyContent3')}
          <a href='mailto:itservicedesk@rentcompany.nl'>itservicedesk@rentcompany.nl</a>
          &nbsp;
          {t('pages.userRegister.or')}
          &nbsp;
          <a href='mailto:itservicedesk@rentcompany.be'>itservicedesk@rentcompany.be</a>
        </Typography>
      </Box>
    </div>
  )
}

export default ThankYouPage
