import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const useTable = ({
  query,
  initialStates = { order: 'asc', page: 1, orderBy: 'id', search: '', filter: '' },
  queryAttributes = {}
}) => {
  const [order, setOrder] = useState(initialStates?.order ?? 'desc')
  const [page, setPage] = useState(initialStates?.page ?? 1)
  const [orderBy, setOrderBy] = useState(initialStates?.orderBy ?? 'id')
  const [search, setSearch] = useState(initialStates?.search)
  const [filter, setFilter] = useState(initialStates?.filter)

  const { data, isLoading, isSuccess } = query({
    order,
    page,
    orderBy,
    search,
    filter,
    ...queryAttributes
  })

  const refValue = useRef({
    search: initialStates?.search,
    filter: initialStates?.filter
  })

  useEffect(() => {
    if (search !== refValue.current.search) {
      setPage(1)
      refValue.current.search = search
    }
  }, [search])

  useEffect(() => {
    if (filter !== refValue.current.filter) {
      setPage(1)
      refValue.current.filter = filter
    }
  }, [filter])

  const results = useMemo(() => {
    return data?.data || []
  }, [data])

  const sortHandler = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const searchHandler = useMemo(() => {
    return debounce(event => {
      if (!(event.target.value.length < 3)) {
        setSearch(event.target.value)
      }
    }, 500)
  }, [])

  const filterHandler = useCallback(value => {
    setFilter(value)
  }, [])

  const onEnterSearch = useCallback(event => {
    if (event.key === 'Enter') {
      setSearch(event.target.value)
    }
  }, [])

  const changePageHandler = (_, value) => {
    setPage(value)
  }
  return {
    order,
    page,
    orderBy,
    search,
    setOrder,
    setPage,
    setOrderBy,
    setSearch,
    isLoading,
    isSuccess,
    data,
    results,
    sortHandler,
    searchHandler,
    onEnterSearch,
    changePageHandler,
    filterHandler
  }
}

export default useTable
