import adminAxiosClient from './adminBase'

class OverviewOrganisationApi {
  showOrganisationsApi = id => adminAxiosClient.get(`api/overview/organisations/${id}`)
  intuneEnrollTriggerList = () =>
    adminAxiosClient.get('api/overview/organisations/intune-enroll-triggers')

  updateGroupTagApi = (id, tags) =>
    adminAxiosClient.post(`api/overview/organisations/${id}/update-group-tag`, {
      group_tag: tags.group_tag,
      group_tag_staff: tags.group_tag_staff,
      group_tag_loaner: tags.group_tag_loaner
    })

  updatePreprovisionTokenApi = (id, token) =>
    adminAxiosClient.post(`api/overview/organisations/${id}/update-provision-token`, {
      token
    })

  removeOrganisationFromTenantApi = id =>
    adminAxiosClient.post(`api/overview/organisations/${id}/remove-organisation-from-tenant`)

  updateintuneEnrollTrigger = (id, payload) =>
    adminAxiosClient.put(`api/overview/organisations/${id}/intune-enroll-triggers`, { ...payload })

  switchRepairFlow = id =>
    adminAxiosClient.put(`api/overview/organisations/${id}/switch-repair-flow`)
}

export default new OverviewOrganisationApi()
