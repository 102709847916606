/* eslint-disable react/no-unescaped-entities */
import { Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const MissingPermission = () => {
  const { t } = useTranslation()
  return (
    <>
      <Divider
        style={{
          marginBottom: 20
        }}
      />
      <Typography variant='h5' gutterBottom>
        {t('general.missingPermission')}
      </Typography>
    </>
  )
}

export default MissingPermission
