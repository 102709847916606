import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { selectIsGoogleRegistrationEmail } from '~/store/registration/selector'
import { useGoogleUserRegistrationMutation } from '../mutation'

const localization = 'pages.userRegister.'
const AdditionalForm = ({ languages, language, setLanguage, setSubmitted }) => {
  const captchaRef = useRef()
  const { t, i18n } = useTranslation()
  const registrationEmail = useSelector(selectIsGoogleRegistrationEmail)
  const { mutate, isLoading, isSuccess } = useGoogleUserRegistrationMutation()
  const [email, setEmail] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const recaptchaKey = import.meta.env.VITE_GOOGLE_RECAPTCHA_CLIENT_KEY

  const schema = yup
    .object({
      fullname: yup.string().required(t(`${localization}fullnameError`)),
      email: yup
        .string()
        .required(t(`${localization}emailError`))
        .email(t(`${localization}emailNotValid`)),
      school_name: yup.string().required(t(`${localization}schoolNameError`)),
      tenant_domain: yup
        .string()
        .required(t(`${localization}tenantDomainError`))
        .trim()
        .matches(
          /^(?!-)[a-z0-9-.]{1,253}(?<!-)$/,
          'Invalid domain: Only accept normal lowercase letters and number'
        ),
      mobile_number: yup
        .string()
        .required(t(`${localization}mobileNumberError`))
        .matches(
          /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          t(`${localization}mobileNumberInvalid`)
        ),
      pre_provisioning_token: yup.string().required(t(`${localization}zteError`)),
      customer_id: yup.string().required(t(`${localization}customerError`))
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullname: '',
      email: '',
      school_name: '',
      tenant_domain: '',
      mobile_number: '',
      language: 'nl',
      captcha_verification_code: '',
      customer_id: ''
    }
  })

  const onSubmit = async => {
    mutate(getValues(), {
      onSuccess: () => {
        enqueueSnackbar({
          message: 'Your submission has been received',
          variant: 'success'
        })
        setSubmitted(true)
      },
      onError: error => {
        enqueueSnackbar({
          message: error?.response?.data?.message ?? 'Something went wrong',
          variant: 'error'
        })
        setCanSubmit(false)
        captchaRef.current.reset()
      }
    })
  }

  useEffect(() => {
    if (i18n.language) {
      const appLang = languages.find(lang => lang.id === i18n.language) || languages[0]
      setLanguage(appLang)
      setValue('language', appLang.id)
    }
  }, [i18n.language, languages, setLanguage, setValue])

  useEffect(() => {
    if (registrationEmail) {
      setValue('email', registrationEmail)
      setEmail(registrationEmail)
    }
  }, [registrationEmail, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 2 }}>
        <Typography>
          {t(`${localization}fullname`)} <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('fullname')}
          error={!!errors.fullname}
          helperText={<>{errors.fullname?.message}</>}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>
          {t(`${localization}email`)} <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          sx={{ width: '100%' }}
          {...register('email')}
          error={!!errors.email}
          helperText={<>{errors.email?.message}</>}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Typography variant='caption'>{t(`${localization}googleNote`)}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>
          {t(`${localization}schoolName`)} <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          sx={{ width: '100%' }}
          {...register('school_name')}
          error={!!errors.school_name}
          helperText={<>{errors.school_name?.message}</>}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>
          Customer Id <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField fullWidth variant='outlined' />
        <Typography variant='caption'>
          {t(`${localization}pleaseCheck`)}
          <a
            href='https://support.google.com/a/answer/10070793?hl=en'
            target='_blank'
            rel='noreferrer'
            {...register('customer_id')}
          >
            link
          </a>
          {t(`${localization}forGetCid`)}
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 2
        }}
      >
        <Typography>
          {t(`${localization}tenantDomain`)} <span style={{ color: 'red' }}>*</span>
        </Typography>

        <TextField
          fullWidth
          variant='outlined'
          {...register('tenant_domain')}
          error={!!errors.tenant_domain}
          helperText={<>{errors.tenant_domain?.message}</>}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>
          {t(`${localization}zte`)} <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('pre_provisioning_token')}
          error={!!errors.pre_provisioning_token}
          helperText={<>{errors.pre_provisioning_token?.message}</>}
        />
        <Typography variant='caption'>
          {t(`${localization}pleaseCheck`)}
          <a
            href='https://support.google.com/chrome/a/answer/10130175?hl=en#zippy=%2Cgenerate-a-pre-provisioning-token'
            target='_blank'
            rel='noreferrer'
          >
            link
          </a>
          {t(`${localization}forGetToken`)}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>
          {t(`${localization}mobileNumber`)} <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('mobile_number')}
          error={!!errors.mobile_number}
          helperText={<>{errors.mobile_number?.message}</>}
        />
      </Box>
      <Autocomplete
        id='language'
        name='language'
        freeSolo
        value={language}
        options={languages?.map(option => option)}
        onChange={(e, value) => {
          i18n.changeLanguage(value.id)
          setValue('language', value.id)
          setLanguage(value)
        }}
        sx={{ mt: 2 }}
        renderInput={params => <TextField {...params} label={t('pages.userRegister.language')} />}
      />

      <Box sx={{ mt: 2, mb: 2 }}>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={recaptchaKey}
          onChange={value => {
            setValue('captcha_verification_code', value)
            setCanSubmit(value)
          }}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <LoadingButton
          variant='contained'
          type={canSubmit ? 'submit' : 'button'}
          disabled={!canSubmit}
          loading={isLoading && !isSuccess}
          sx={{ mt: 1, mr: 1, textTransform: 'none', backgroundColor: '#76B72A' }}
        >
          {t(`${localization}submit`)}
        </LoadingButton>
      </Box>
    </form>
  )
}

AdditionalForm.propTypes = {
  languages: PropTypes.array,
  language: PropTypes.object,
  setLanguage: PropTypes.func,
  setSubmitted: PropTypes.func
}

AdditionalForm.defaultProps = {
  languages: [{ label: 'English', id: 'en' }],
  language: 'en',
  setLanguage: () => {},
  setSubmitted: () => {}
}

export default AdditionalForm
