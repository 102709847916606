import CloseIcon from '@mui/icons-material/Close'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, Divider, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { alpha, styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import SettingsIcon from '@mui/icons-material/Settings'
import PropTypes from 'prop-types'

const StyledMenu = styled(props => <Menu {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const localization = 'pages.adminOverview.registration.'

const propTypes = {
  row: PropTypes.object,
  onReject: PropTypes.func,
  onInvite: PropTypes.func
}

const defaultProps = {
  row: {},
  onReject: () => {},
  onInvite: () => {}
}

const ActionButton = ({ row, onReject, onInvite }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const actionClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        id='overview-tenant-action-button'
        aria-controls={open ? 'custom-action' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={actionClick}
        endIcon={<KeyboardArrowDown />}
      >
        {t(`${localization}actions`)}
      </Button>

      <StyledMenu
        id='custom-action'
        MenuListProps={{
          'aria-labelledby': 'overview-tenant-action-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box>
          <MenuItem
            onClick={e => {
              onInvite(e, row.id)
              setAnchorEl(null)
            }}
            disabled={false}
            disableRipple
          >
            {row.is_google ? (
              <>
                <SettingsIcon /> {t('button.setup')}
              </>
            ) : (
              <>
                <MarkEmailReadIcon /> {t(`${localization}invite`)}
              </>
            )}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={e => {
              onReject(e, row.id)
              setAnchorEl(null)
            }}
            disabled={false}
            disableRipple
          >
            <CloseIcon /> {t(`${localization}reject`)}
          </MenuItem>
        </Box>
      </StyledMenu>
    </>
  )
}

ActionButton.propTypes = propTypes
ActionButton.defaultProps = defaultProps

export default ActionButton
