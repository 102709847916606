export const GroupTagHeadCells = [
  {
    id: 'grouptag',
    numeric: false,
    disablePadding: false,
    label: 'groupTag'
  },
  {
    id: 'school',
    numeric: false,
    disablePadding: false,
    label: 'school'
  },
  {
    id: 'devices',
    numeric: false,
    disablePadding: false,
    label: 'devices'
  }
]
