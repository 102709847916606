import {
  Chip,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import { AzureDeviceHeadCells } from './AzureDeviceHeadCells'

const localization = 'pages.device.'

const DeviceTableBody = ({ isLoading, devices }) => {
  const { overviewUser } = useAdminPermission()

  if (isLoading) {
    return <TableLoading colSpan={AzureDeviceHeadCells.length} />
  }

  const renderSerialCol = row => (
    <>
      {overviewUser.devices.canViewDetail ? (
        <Link to={`/overview/devices/azure/${row.id}`}>
          <MuiLink>{row.serial}</MuiLink>
        </Link>
      ) : (
        <>{row.serial}</>
      )}
    </>
  )

  return (
    <TableBody>
      {devices.length === 0 && <DataNotFound colSpan={AzureDeviceHeadCells.length} />}
      {devices.length > 0 &&
        devices.map((row, index) => {
          let chipcolor = 'info'
          if (row.import_status === 'complete') {
            chipcolor = 'success'
          } else if (row.import_status === 'unknown') {
            chipcolor = 'default'
          } else {
            chipcolor = 'error'
          }
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align='left'>{renderSerialCol(row)}</TableCell>
              <TableCell align='left'>{row.azure_tenant?.tenant?.name}</TableCell>
              <TableCell component='th' scope='row'>
                {row.manufacture}
              </TableCell>
              <TableCell>{row.model}</TableCell>
              <TableCell align='left'>{row.group_tag}</TableCell>
              <TableCell align='center'>{row.os_system}</TableCell>
              <TableCell align='center'>{row.os_version}</TableCell>
              <TableCell align='center'>
                <Chip label={row.import_status} color={chipcolor} size='small' />
              </TableCell>
              <TableCell align='center'>
                {row.state && <Chip label={row.state} color='default' size='small' />}
              </TableCell>
              <TableCell align='center'>{row.import_error_name}</TableCell>
              <TableCell align='center'>{row.trc_managed && <></>}</TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

DeviceTableBody.defaultProps = {
  isLoading: false,
  devices: []
}

DeviceTableBody.propTypes = {
  isLoading: PropTypes.bool,
  devices: PropTypes.array
}

const AzureDeviceTable = ({ order, orderBy, isLoading, devices, handleRequestSort }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
        <TableHeader
          headCells={AzureDeviceHeadCells}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          localization={localization}
        />
        <DeviceTableBody isLoading={isLoading} devices={devices} />
      </Table>
    </TableContainer>
  )
}

AzureDeviceTable.defaultProps = {
  order: 'asc',
  orderBy: '',
  isLoading: false,
  devices: [],
  handleRequestSort: () => {}
}

AzureDeviceTable.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  isLoading: PropTypes.bool,
  devices: PropTypes.array,
  handleRequestSort: PropTypes.func
}

export default AzureDeviceTable
