import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { authenticateWithGoogle } from '~/api'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useGoogleAuthenticate = id => {
  return useQuery('google_auth', async () => {
    const { data } = await authenticateWithGoogle(id)
    return data
  })
}

export const useAuthenticateWithGoogleQuery = body => {
  const navigate = useNavigate()

  return useQuery(
    'authenticate_google',
    async () => {
      const { data } = await authenticateWithGoogle(body)
      return data
    },
    {
      onSuccess: async data => {
        // if (localStorage.getItem('google_redirect') === 'true') {
        //   dispatch(setUser(data))
        //   setApiClientAuthToken(token)
        // }
        if (window.opener) {
          window.opener.postMessage(
            {
              email: data?.email
            },
            window.opener.origin
          )
          window.close()
        }
      },
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error',
          autoHideDuration: 4000
        })
        setTimeout(() => {
          if (window.opener) {
            window.opener.location.reload()
            window.close()
          } else {
            navigate('/login')
          }
        }, 4000)
      },
      retry: false,
      retryDelay: false
    }
  )
}
