import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useUpdateContactInfoMutation } from '../mutation'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

const schema = yup
  .object({
    contact_email: yup.string().required(),
    contact_person_name: yup.string().required(),
    mobile_number: yup.string().required()
  })
  .required()

const CheckboxComponent = ({ field: props }) => (
  <Checkbox {...props} checked={props.value} onChange={e => props.onChange(e.target.checked)} />
)

CheckboxComponent.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

const AdditionalForm = ({ registrationToken, data, haveStep4 }) => {
  const { mutate, isLoading } = useUpdateContactInfoMutation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const onSubmit = async values => {
    mutate(
      { ...values, registration_token: registrationToken },
      {
        onSuccess: () => {
          enqueueSnackbar({
            message: 'Successfully Registered',
            variant: 'success'
          })
          if (haveStep4) {
            queryClient.invalidateQueries('registration_token')
          } else {
            navigate({ pathname: '/login' })
          }
        }
      }
    )
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      contact_email: data?.contact_email,
      contact_person_name: data?.contact_person_name,
      mobile_number: data?.mobile_number,
      store_device_details_not_under_contract: true,
      capture_device_entitlements_not_under_contract: true
    }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 2 }}>
        <Typography>
          Contact person name <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('contact_person_name')}
          error={!!errors.contact_person_name}
          helperText={<>{errors.contact_person_name?.message}</>}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography>
          Mobile phone <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('mobile_number')}
          error={!!errors.mobile_number}
          helperText={<>{errors.contact_person_name?.message}</>}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography>
          Contact Email <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          sx={{ width: '100%' }}
          {...register('contact_email')}
          error={!!errors.contact_email}
          helperText={<>{errors.contact_person_name?.message}</>}
        />
      </Box>

      <FormControl sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Controller
              name='store_device_details_not_under_contract'
              control={control}
              render={CheckboxComponent}
            />
          }
          style={{ fontStyle: 'italic', display: 'none' }}
          label='The Rent Company is also allowed to store device details of devices not under contract'
        />
      </FormControl>
      <FormControl>
        <FormControlLabel
          control={
            <Controller
              name='capture_device_entitlements_not_under_contract'
              control={control}
              render={CheckboxComponent}
            />
          }
          style={{ fontStyle: 'italic', display: 'none' }}
          label='The Rent Company is allowed to capture entitlements of devices not under contract'
        />
      </FormControl>

      <Box sx={{ mb: 2 }}>
        <LoadingButton
          variant='contained'
          type='submit'
          loading={isLoading}
          sx={{ mt: 1, mr: 1, textTransform: 'none', backgroundColor: '#76B72A' }}
        >
          Submit
        </LoadingButton>
      </Box>
    </form>
  )
}

AdditionalForm.propTypes = {
  registrationToken: PropTypes.string,
  data: PropTypes.object,
  haveStep4: PropTypes.bool
}

AdditionalForm.defaultProps = {
  haveStep4: false
}

export default AdditionalForm
