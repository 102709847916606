import FullPageLoading from '~/components/FullPageLoading'
import { useSearchParams } from 'react-router-dom'
import { useAuthenticateWithGoogleQuery } from './query'

const GoogleLoginRedirect = () => {
  const [searchParams] = useSearchParams()
  const searchQueries = Object.fromEntries([...searchParams])

  const { isLoading } = useAuthenticateWithGoogleQuery(searchQueries)

  return (
    <div>
      <div>{isLoading ? <FullPageLoading /> : ''}</div>
    </div>
  )
}

export default GoogleLoginRedirect
