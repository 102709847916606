export const DASHBOARD = '/dashboard'

// Google routes
export const GOOGLE_LOGIN_REDIRECT = '/google-login-redirect'
export const GOOGLE_REGISTRATION = '/google-registration'
export const GOOGLE_TENANT_REGISTRATION = '/google-tenant-registration'
export const GOOGLE_DEVICE_DETAIL = '/google/devices/:id'
export const GOOGLE_DEVICE_NEW = '/google/devices/new'
export const GOOGLE_DEVICES = '/google/devices'
export const PRE_PROVISIONING_TOKEN = '/google/provisioning-tokens'

// Azure routes
export const AZURE_DEVICES = '/azure/devices'
export const AZURE_DEVICE_NEW = '/azure/devices/new'
export const AZURE_DEVICE_DETAIL = '/azure/devices/:id'
export const GROUPTAGS = 'azure/group-tags'
export const ORG_OVERVIEW = 'azure/organisation-overview'
export const AZURE_TENANT_REGISTRATION = '/azure-tenant-registration'

export const ORGANISATION = '/organisations'

export const HEALTH_CHECK = '/health-check'

// USER SETTING
export const USER_SETTING = '/users/setting'

// ADMINITRATOR OVERVIEW ROUTES
// DASHBOARD
export const ADMIN_DASHBOARD = '/overview/dashboard'

export const ADMIN_LOGIN = '/overview/login'
export const ADMIN_OVERVIEW = '/overview/tenants'
export const OVERVIEW_TENANTS_DETAIL = '/overview/tenants/:tenantId'
export const OVERVIEW_DEVICE_REGISTER_SESSION_DETAIL =
  '/overview/devices-register-sessions/:sessionId'
export const ADMIN_DEVICES = '/overview/devices'
export const ADMIN_DEVICE_REGISTER_SESSIONS = '/overview/devices-register-sessions'
export const ADMIN_AZURE_DEVICE_DETAIL = '/overview/devices/azure/:id'
export const ADMIN_GOOGLE_DEVICE_DETAIL = '/overview/devices/google/:id'
export const ADMIN_INVITE_AZURE_TENANTS = '/overview/invite-azure-tenant'
export const ADMIN_INVITE_GOOGLE_DOMAIN = '/overview/invite-google-domain'
export const ADMIN_USERS = '/overview/users'
export const ADMIN_REGISTRATION = '/overview/registrations'
export const ADMIN_REGISTRATION_DETAIL = '/overview/registrations/:id'
export const ADMIN_VIEW_USER_DETAIL = '/overview/users/:id'
export const ADMIN_USERS_DISABLE = '/overview/users-disable'
export const ADMIN_SUPER_USERS = '/overview/super-users'
export const ADMIN_VIEW_SUPER_USER_DETAIL = '/overview/super-users/:id'
export const ADMIN_SUPER_USERS_DISABLE = '/overview/super-users-disable'

export const ADMIN_USER_COMPANY = '/overview/uses/companies'
export const ADMIN_USER_COMPANY_NEW = '/overview/uses/companies/new'
export const ADMIN_USER_COMPANY_EDIT = '/overview/uses/companies/edit/:id'
export const ADMIN_USER_COMPANY_DETAIL = '/overview/uses/companies/:id'

// Roles
export const ADMIN_USER_ROLE = '/overview/uses/roles'
export const ADMIN_USER_ROLE_NEW = '/overview/uses/roles/new'
export const ADMIN_USER_ROLE_DETAIL = '/overview/uses/roles/:id'

// Setting
export const ADMIN_SETTING = '/overview/settings'
export const ADMIN_USER_SETTING = '/overview/users/setting'
