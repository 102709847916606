import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Divider, Link, Typography } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const BreadCrumb = ({ breadcrumbs }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs.map((crumb, index) => {
          if (crumb.page || crumb.route === '#') {
            return (
              <Typography key={index} color='text.primary'>
                {crumb.title}
              </Typography>
            )
          }

          return (
            <Link
              component='button'
              key={index}
              variant='primary'
              underline='none'
              onClick={() => {
                navigate(crumb.route)
              }}
            >
              {crumb.notTranlsate ? crumb.title : t(`${crumb.title}`)}
            </Link>
          )
        })}
      </Breadcrumbs>
      <Divider sx={{ marginBottom: 3 }} />
    </>
  )
}

BreadCrumb.propTypes = {
  breadcrumbs: propTypes.array
}

export default BreadCrumb
