// SETUP COLORS
const GREEN = {
  0: '#FFFFFF',
  100: '#76B72A'
}

const BLUE = {
  100: '#102F44'
}

const palette = {
  common: { black: '#000', white: '#fff' },
  green: GREEN,
  blue: BLUE,
  primary: {
    main: '#76B72A',
    contrastText: '#ffffff'
  }
}

export default palette
