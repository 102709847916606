import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import organisationApi from '~/api/organisationApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useOrgOverviewQuery = () => {
  return useQuery(
    ['org_overview'],
    async () => {
      const { data } = await organisationApi.listOrgOverviewApi()
      return { data: data.data }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
