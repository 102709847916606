import LabelStatus from '~/components/LabelStatus'
import { useTranslation } from 'react-i18next'
import { capitalize, getUserStatusColor } from '~/utils/helpers'
import PropTypes from 'prop-types'

const LabelStatusUser = ({ status }) => {
  const { t } = useTranslation()

  return (
    <LabelStatus variant='ghost' color={getUserStatusColor(status)}>
      {capitalize(t(`pages.adminOverview.users.detail.${status}`))}
    </LabelStatus>
  )
}

LabelStatusUser.propTypes = {
  status: PropTypes.string
}

export default LabelStatusUser
