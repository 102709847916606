import { useQuery } from 'react-query'
import { overviewOrgs } from '~/api'

export const useGetOrgsQuey = (params, enabled = true) => {
  return useQuery(
    ['overview_orgs_listing', params],
    async () => {
      const { data } = await overviewOrgs(params)
      return data.data
    },
    {
      retry: false,
      enabled
    }
  )
}
