import PropTypes from 'prop-types'
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useTranslation } from 'react-i18next'

const TableHeader = ({
  headCells,
  onRequestSort,
  order,
  orderBy,
  localization,
  isSelectAllSelected = false,
  handleSelectAllClick = () => {}
}) => {
  const { t } = useTranslation()
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (headCell.hidden) {
            return <TableCell key={headCell.id} />
          }

          if (headCell.checkbox) {
            return <TableCell key={headCell.id} padding='checkbox' />
          }

          if (headCell.selectAll) {
            return (
              <TableCell key={headCell.id} padding='checkbox' align={'center'}>
                <Checkbox
                  color='primary'
                  onChange={handleSelectAllClick}
                  checked={isSelectAllSelected}
                  inputProps={{
                    'aria-labelledby': headCell.id
                  }}
                  disabled={!headCell.clickable}
                />
              </TableCell>
            )
          }

          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.orderable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {t(`${localization}${headCell.label}`)}
                  {orderBy === headCell.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <TableSortLabel hideSortIcon>
                  {t(`${localization}${headCell.label}`)}
                </TableSortLabel>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
  localization: PropTypes.string,
  isSelectAllSelected: PropTypes.bool,
  handleSelectAllClick: PropTypes.func
}

export default TableHeader
