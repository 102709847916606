import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { usePreProvisionTokensQuery } from '../query'
import { TokenHeadCells } from './TokenHeadCells'

const localization = 'pages.organisation.'

const TokenTableBody = ({ isLoading, tokens }) => {
  if (isLoading) {
    return <TableLoading colSpan={TokenHeadCells.length} />
  }

  return (
    <TableBody>
      {tokens.length > 0 ? (
        tokens.map((row, index) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align='left'>{row.name}</TableCell>
              <TableCell align='left'>{row.pre_provisioning_token}</TableCell>
            </TableRow>
          )
        })
      ) : (
        <DataNotFound colSpan={TokenHeadCells.length} />
      )}
    </TableBody>
  )
}

TokenTableBody.propTypes = {
  isLoading: PropTypes.bool,
  tokens: PropTypes.array
}

TokenTableBody.defaultProps = {
  isLoading: false,
  tokens: []
}

const TokenTable = () => {
  const { data, isLoading } = usePreProvisionTokensQuery()
  return (
    <>
      <Paper elevation={1}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader headCells={TokenHeadCells} localization={localization} />
            <TokenTableBody isLoading={isLoading} tokens={data ? data.data : []} />
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}
export default TokenTable
