import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Stack, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { TIMEZONES } from '~/utils/helpers'
import { useUpdateSettingUserMutation } from './mutation'

const Setting = ({ user }) => {
  const { t } = useTranslation()

  const schema = yup
    .object({
      timezone: yup.string().required()
    })
    .required()

  // States
  const [timezone, setTimezone] = useState(user.timezone)

  // Mutations
  const { mutate: updateSetting } = useUpdateSettingUserMutation()

  const defaultValues = useMemo(() => {
    return {
      timezone
    }
  }, [timezone])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSubmit = async values => {
    updateSetting(values)
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field: { onChange, value }, ...props }) => (
            <Autocomplete
              sx={{
                mt: 2
              }}
              fullWidth
              value={timezone || null}
              onChange={(e, value) => {
                onChange(value)
                setTimezone(value)
              }}
              options={TIMEZONES}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Timezone'
                  error={!!errors.timezone}
                  helperText={<>{errors.timezone?.message}</>}
                />
              )}
            />
          )}
          name={'timezone'}
          control={control}
        />
        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton variant='contained' type='submit'>
            {t('pages.tenant.save')}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  )
}

Setting.propTypes = {
  user: PropTypes.object.isRequired
}

export default Setting
