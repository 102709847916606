export const AzureDeviceHeadCells = [
  {
    id: 'serial',
    numeric: false,
    disablePadding: false,
    label: 'serialNumber',
    orderable: true
  },
  {
    id: 'azureTenant',
    disablePadding: false,
    label: 'azureTenant',
    orderable: false
  },
  {
    id: 'manufacture',
    numeric: false,
    disablePadding: false,
    label: 'vendor',
    orderable: false
  },
  {
    id: 'model_name',
    numeric: false,
    disablePadding: false,
    label: 'model',
    orderable: false
  },
  {
    id: 'group_tag',
    numeric: false,
    disablePadding: false,
    label: 'groupTag',
    orderable: true
  },
  {
    id: 'os_system',
    numeric: true,
    disablePadding: false,
    label: 'osSystem',
    orderable: false
  },
  {
    id: 'os_version',
    numeric: true,
    disablePadding: false,
    label: 'osVersion',
    orderable: false
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'status',
    orderable: false
  },
  {
    id: 'state',
    numeric: true,
    disablePadding: false,
    label: 'state',
    orderable: false
  },
  {
    id: 'error_name',
    numeric: true,
    disablePadding: false,
    label: 'error_name',
    orderable: false
  }
]
