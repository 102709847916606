import { enqueueSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { authenticateWithMicrosoft, requestGoogleOAuth } from '~/api'
import { setApiClientAuthToken } from '~/api/base'
import { KEY_AUTH_TOKEN, REGISTRATION_STATE } from '~/constants/constants'
import { setIsAuthenticating, setUser } from '~/store/auth/slice'
import { setIsGoogleRegistration, setRegistrationEmail } from '~/store/registration/slices'
import { setUserPermissions } from '~/store/user/slice'
import { parseApiErrorMessage } from '~/utils/helpers'
import msalLoginPopup from '~/utils/msal'

export const useAuthenticateWithMicrosoftMutation = () => {
  const dispatch = useDispatch()

  const { mutate, isLoading } = useMutation(
    async () => {
      dispatch(setIsAuthenticating(true))
      const { accessToken } = await msalLoginPopup()
      const { data } = await authenticateWithMicrosoft(accessToken)
      return data
    },
    {
      onSuccess: ({ access_token: token, data }) => {
        localStorage.setItem(KEY_AUTH_TOKEN, token)
        localStorage.setItem(REGISTRATION_STATE, data.registration_token)
        setApiClientAuthToken(token)
        dispatch(setUser(data))
        dispatch(setIsAuthenticating(false))
        const permissions = data.permissions?.reduce((acc, cur) => {
          return [...acc, cur.name]
        }, [])
        dispatch(setUserPermissions(permissions || []))
      },
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
        dispatch(setIsAuthenticating(false))
      }
    }
  )

  return { login: mutate, isLoading }
}

export const useLoginWithGoogleMutation = (redirect = true) => {
  const dispatch = useDispatch()
  const { mutate, isLoading } = useMutation(
    async () => {
      const { data } = await requestGoogleOAuth()
      return data
    },
    {
      onSuccess: async ({ url }) => {
        localStorage.setItem('google_redirect', redirect)
        const width = 400
        const height = 600
        const left = window.outerWidth / 2 + window.screenX - width / 2
        const top = window.outerHeight / 2 + window.screenY - height / 2

        dispatch(setIsAuthenticating(true))
        const popup = window.open(
          url,
          'oauthWindow',
          `width=${width},height=${height},top=${top},left=${left}`
        )

        let timer = null

        window.addEventListener(
          'message',
          function (event) {
            const data = event?.data
            if (typeof data === 'object') {
              clearInterval(timer)
              dispatch(setIsAuthenticating(false))
              dispatch(setIsGoogleRegistration(true))
              dispatch(setRegistrationEmail(data.email ?? ''))
            }
          },
          false
        )

        timer = setInterval(function () {
          if (popup.closed) {
            clearInterval(timer)
            dispatch(setIsAuthenticating(false))
            // enqueueSnackbar({
            //   message: 'user_cancelled: User cancelled the flow.',
            //   variant: 'error',
            //   autoHideDuration: 1000
            // })
          }
        }, 1000)
      },
      onError: error => {
        dispatch(setIsAuthenticating(false))
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      }
    }
  )

  return { login: mutate, isLoading }
}
