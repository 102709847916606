import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import overviewUserApi from '~/api/overviewUserApi'

export const useUpdateRoleMutation = props => {
  const urlParams = useParams()
  const queryClient = useQueryClient()

  return useMutation(
    ['role_update'],
    async values => {
      return await overviewUserApi.updateRole(urlParams.id, { role_name: values })
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message ? data.message : 'Save successful!',
          variant: 'success'
        })
        queryClient.invalidateQueries('overview_user_info')

        return data
      },
      onError: error => {
        enqueueSnackbar({
          message: error.message ? error.message : 'Save failed!',
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useChangeActiveTenantMutation = props => {
  const urlParams = useParams()
  const queryClient = useQueryClient()

  return useMutation(
    ['change_active_tenant'],
    async data => {
      return await overviewUserApi.overviewChangeActiveTenant(urlParams.id, {
        name: data.name,
        type: data.type
      })
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message ? data.message : 'Save successful!',
          variant: 'success'
        })
        queryClient.invalidateQueries('overview_user_info')

        return data
      },
      onError: error => {
        enqueueSnackbar({
          message: error.message ? error.message : 'Save failed!',
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useUpdateDisableUserMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ['update_disable'],
    async ({ id, disabled }) => {
      const { data } = await overviewUserApi.updateDisableUser(id, { disabled })
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message ? data.message : 'Save successful!',
          variant: 'success'
        })
        queryClient.invalidateQueries('overview_user_info')

        return data
      },
      onError: error => {
        enqueueSnackbar({
          message: error.message ? error.message : 'Save failed!',
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
