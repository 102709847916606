import styled from '@emotion/styled'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const ErrorBox = styled(Box)({
  overflow: 'auto'
})

const ErrorTypography = styled(Typography)({
  color: '#d32f2f',
  fontFamily: "'Lato',sans-serif",
  fontSize: '0.75rem',
  fontWeightL: 400,
  lineHeight: 1.66
})

const localization = 'pages.device.'

const ConfirmDialog = ({
  openConfirm,
  handleConfirmClose,
  errorConfirm,
  confirmText,
  setConfirmText,
  handleConfirm
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={openConfirm} onClose={handleConfirmClose}>
      <DialogTitle>{t(`${localization}type`)}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          type='text'
          fullWidth
          value={confirmText}
          onChange={e => setConfirmText(e.target.value)}
          placeholder='Type YES to confirm'
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleConfirm()
            }
          }}
        />
        {errorConfirm && (
          <ErrorBox>
            <ErrorTypography>{errorConfirm}</ErrorTypography>
          </ErrorBox>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmClose} color='primary'>
          {t(`${localization}cancel`)}
        </Button>
        <Button onClick={handleConfirm} color='error'>
          {t(`${localization}confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  openConfirm: PropTypes.bool.isRequired,
  handleConfirmClose: PropTypes.func.isRequired,
  errorConfirm: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  setConfirmText: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
}

export default ConfirmDialog
