import { LoadingButton } from '@mui/lab'
import { Breadcrumbs, Divider, Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import FullPageLoading from '~/components/FullPageLoading'
import CustomModal from '~/components/Modal/CustomModal'
import TenantIcon from '~/components/layout/IconComponent'
import {
  ADMIN_INVITE_AZURE_TENANTS,
  ADMIN_INVITE_GOOGLE_DOMAIN,
  ADMIN_REGISTRATION
} from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { selectSnackbar } from '~/store/snackbar/selector'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { useRejectAzUserMutation } from './mutation'
import { useAzureRegistrationDetailQuery } from './query'
import DateTimeLocale from '~/components/DateTimeLocale'

const RegistrationDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { data, isLoading } = useAzureRegistrationDetailQuery(id)
  const { mutate: rejectUser } = useRejectAzUserMutation()

  const snackbarData = useSelector(selectSnackbar)
  const { showSnackbar, snackbar } = useSnackbar()
  const dispatch = useDispatch()
  useEffect(() => {
    if (snackbarData) {
      showSnackbar(snackbarData.message, snackbarData.type)
    }
    return () => {
      dispatch(unsetSnackbar({}))
    }
  })

  const localization = 'pages.adminOverview.registration.'

  if (isLoading) {
    return <FullPageLoading />
  }

  const onReject = () => {
    setOpen(true)
  }

  const onInvite = async () => {
    let url = ADMIN_INVITE_GOOGLE_DOMAIN
    if (data?.is_azure) {
      url = ADMIN_INVITE_AZURE_TENANTS
    }

    navigate(`${url}?registration-id=${id}`)
  }

  const handleOnClose = () => {
    setOpen(false)
  }

  const onSubmit = async () => {
    rejectUser(id, {
      onSuccess: () => {
        setOpen(false)
        navigate(ADMIN_REGISTRATION)
      }
    })
  }

  if (!data) {
    return <FullPageLoading />
  }

  return (
    <>
      {snackbar}
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to={ADMIN_REGISTRATION}>{t('general.registrationDetail')}</NavLink>
        </Typography>
        <Typography>{data?.id}</Typography>
      </Breadcrumbs>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant='h6'>{t(`${localization}detail`)}</Typography>
      <TenantIcon item={data} />
      {Object.keys(data).map(key => {
        if (
          key === 'tenant_id' ||
          key === 'is_google' ||
          key === 'is_azure' ||
          key === 'service_type' ||
          (key === 'pre_provisioning_token' && data?.is_azure) ||
          (key === 'customer_id' && data?.is_azure)
        ) {
          return null
        }

        if (key === 'created_at' || key === 'updated_at') {
          return (
            <div key={key}>
              {key} : <DateTimeLocale datetime={data[key]} />
            </div>
          )
        }

        return (
          <div key={key}>
            {key} : {`${data[key]}`}
          </div>
        )
      })}
      {data?.status === 'pending' && (
        <Stack sx={{ mt: 2 }} direction='row' gap={1}>
          <LoadingButton variant='contained' onClick={onInvite}>
            {data?.is_azure ? t(`${localization}invite`) : t(`button.setup`)}
          </LoadingButton>
          <LoadingButton variant='contained' onClick={onReject}>
            {t(`${localization}reject`)}
          </LoadingButton>
        </Stack>
      )}

      <CustomModal open={open} onClose={handleOnClose}>
        <Grid item mb={5}>
          <Typography variant='h6' sx={{ fontWeight: 600 }}>
            {t(`${localization}rejectUser`)} #{id}
          </Typography>
        </Grid>
        <Grid item mb={5}>
          <Typography>
            {t(`${localization}rejectConfirm`)} <b>{data?.email}</b>?
          </Typography>
        </Grid>

        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton variant='contained' onClick={onSubmit}>
            {t(`${localization}reject`)}
          </LoadingButton>
          <LoadingButton variant='contained' onClick={handleOnClose}>
            {t(`${localization}cancel`)}
          </LoadingButton>
        </Stack>
      </CustomModal>
    </>
  )
}
export default RegistrationDetail
