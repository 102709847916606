import { Box, Typography, FormControlLabel, Switch, TextField, Card } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAppointmentLinkSettingQuery } from './query'
import { useUpdateAppointmentLinkSettingsMutation } from './mutation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const localization = 'pages.overview.settings.'
const RegistrationSettings = () => {
  const { t } = useTranslation()
  const [enabled, setEnabled] = useState(false)
  const [value, setValue] = useState('')

  const { data } = useAppointmentLinkSettingQuery()
  const { mutate } = useUpdateAppointmentLinkSettingsMutation()

  const handleSave = () => {
    mutate({ enabled, value })
  }

  useEffect(() => {
    if (data) {
      setEnabled(data?.enabled === '1')
      setValue(data?.value)
    }
  }, [data])

  return (
    <Card>
      <Box p={2}>
        <Typography variant='h5' mb={3}>
          {t(`${localization}registrations`)}
        </Typography>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Switch
                name='enableStep4'
                checked={enabled}
                onChange={e => setEnabled(e.target.checked)}
              />
            }
            label={t(`${localization}enableStep4`)}
          />
        </Box>
        <Box mt={2}>
          <TextField
            label={t(`${localization}appointmentLink`)}
            variant='outlined'
            fullWidth
            value={value}
            InputLabelProps={{
              shrink: true
            }}
            placeholder='https://example.com/'
            onChange={e => setValue(e.target.value)}
          />
        </Box>
        <Box mt={2} display='flex' justifyContent='flex-end'>
          <LoadingButton
            variant='contained'
            onClick={handleSave}
            sx={{ mt: 1, mr: 1, textTransform: 'none', backgroundColor: '#76B72A' }}
          >
            {t(`${localization}save`)}
          </LoadingButton>
        </Box>
      </Box>
    </Card>
  )
}

export default RegistrationSettings
