import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import ToolBarButton from '~/components/Button/ToolBarButton'
import FullPageLoading from '~/components/FullPageLoading'
import { ADMIN_USER_COMPANY, ADMIN_USER_COMPANY_EDIT } from '~/constants/Routes'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import ComponentSchools from './CompanySchools'
import CompanyTenant from './CompanyTenants'
import CompanyUsers from './CompanyUsers'
import { useOverviewCompanyQuery } from './query'

const localization = 'pages.overview.company'

const Detail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { overviewUser } = useAdminPermission()

  const { data, isLoading: retrievingData } = useOverviewCompanyQuery(id)
  if (retrievingData) {
    return <FullPageLoading />
  }

  const BreadComponents = [
    {
      title: 'Administrator'
    },
    {
      route: ADMIN_USER_COMPANY,
      title: 'Company Overview'
    },
    {
      page: 'detail',
      title: data?.name || 'N/A'
    }
  ]

  return (
    <>
      <BreadCrumb breadcrumbs={BreadComponents} />
      <Divider />
      <Typography
        display='flex'
        alignItems='center'
        variant='h5'
        textAlign='left'
        sx={{ justifyContent: 'space-between' }}
      >
        <Box>{data?.name}</Box>
        <ToolBarButton
          name={t(`${localization}.edit`)}
          handleAction={() => {
            navigate(ADMIN_USER_COMPANY_EDIT.replace(':id', id))
          }}
          disabled={!overviewUser.administrator.companies.canEdit}
        />
      </Typography>
      <Grid item xs={12} lg={8}>
        <Stack direction='column' spacing={3}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper'
                }}
              >
                <Box sx={{ m: 2 }}>
                  <Stack direction='column'>
                    <Grid item xs>
                      <Typography gutterBottom variant='h4' component='div'>
                        {t(`${localization}.users`)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom variant='h8' component='div'>
                        <CompanyUsers />
                      </Typography>
                    </Grid>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper'
                }}
              >
                <Box sx={{ m: 2 }}>
                  <Stack direction='column'>
                    <Grid item xs>
                      <Typography gutterBottom variant='h4' component='div'>
                        {t(`${localization}.tenants`)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom variant='h8' component='div'>
                        <CompanyTenant />
                      </Typography>
                    </Grid>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper'
                }}
              >
                <Box sx={{ m: 2 }}>
                  <Stack direction='column'>
                    <Grid item xs>
                      <Typography gutterBottom variant='h4' component='div'>
                        {t(`${localization}.schools`)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom variant='h8' component='div'>
                        <ComponentSchools />
                      </Typography>
                    </Grid>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </>
  )
}

export default Detail
