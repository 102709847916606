import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete, Breadcrumbs, Divider, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import * as yup from 'yup'
import { LoadingButton } from '~/components/Button/LoadingButton'
import { AZURE_DEVICES } from '~/constants/Routes'
import { useOrganisationListOverviewQuery } from '~/pages/Organisations/Organisations/query'
import { useCreateDeviceMutation } from '../../mutation'
import { useAzureGroupTagQuery } from '../query'

const RegisterAzureDevice = () => {
  const { mutate: createNewDevices, isLoading } = useCreateDeviceMutation()
  const { data } = useAzureGroupTagQuery()
  const { data: schools } = useOrganisationListOverviewQuery()
  const [externalId, setExternalId] = useState(null)
  const [entityId, setEntityId] = useState(null)

  const { t } = useTranslation()

  const schema = yup.object().shape(
    {
      hash: yup.string().required(),
      school: yup.string().required(t('validation.orgRequired')),
      serial: yup
        .string()
        .trim()
        .when('pkid', (val, i) => {
          if (val?.length > 0 && val[0] !== '') {
            return yup.string().notRequired()
          }
          return yup.string().required(t('validation.requiredOneSerialOrPKID'))
        }),
      pkid: yup
        .string()
        .trim()
        .when('serial', (val, i) => {
          if (val?.length > 0 && val[0] !== '') {
            return yup.string().notRequired()
          }
          return yup.string().required(t('validation.requiredOneSerialOrPKID'))
        })
    },
    [['serial', 'pkid']]
  )

  const onSubmit = async values => {
    const submitData = {
      ...values,
      external_id: externalId,
      entity_id: entityId
    }
    createNewDevices(submitData)
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const localization = 'pages.device.detail'

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to={AZURE_DEVICES}>{t(`${localization}.deviceOverview`)}</NavLink>
        </Typography>
        <Typography>{t('button.register')}</Typography>
      </Breadcrumbs>
      <Divider style={{ marginBottom: 20 }} />
      <Paper
        sx={{
          px: 2,
          py: 2
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <TextField
              label='Device Serial'
              name='serial'
              {...register('serial')}
              error={!!errors.pkid && !!errors.serial}
              helperText={<>{!!errors.pkid && !!errors.serial && errors.serial?.message}</>}
            />
            <TextField
              label={t('pages.device.create.hardwareHash')}
              name='hash'
              render={() => <TextField multiline rows={3} />}
              {...register('hash')}
              error={!!errors.hash}
              helperText={<>{errors.hash?.message}</>}
            />
            <TextField
              label='Product Key ID (PKID)'
              name='pkid'
              {...register('pkid')}
              error={!!errors.pkid && !!errors.serial}
              helperText={<>{!!errors.pkid && !!errors.serial && errors.pkid?.message}</>}
            />
            <Autocomplete
              id='school'
              name='school'
              freeSolo
              options={
                schools?.map(option => ({
                  label: option.name + ' - ' + option.external_id,
                  external_id: option.external_id,
                  entity_id: option.entity_id
                })) || []
              }
              onChange={(e, value) => {
                setExternalId(value.external_id)
                setEntityId(value.entity_id)
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Organisation'
                  error={!!errors.school}
                  helperText={<>{errors.school?.message}</>}
                  {...register('school')}
                />
              )}
            />
            <Autocomplete
              id='az-group-tags'
              name='group_tag'
              freeSolo
              options={data?.data.map(option => option.group_tag)}
              renderInput={params => (
                <TextField {...params} label='Group Tag' {...register('group_tag')} />
              )}
            />
            <Stack direction='row-reverse'>
              <LoadingButton variant='contained' type='submit' loading={isLoading}>
                {t('button.create')}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </>
  )
}

export default RegisterAzureDevice
