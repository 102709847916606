import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material'
import TableHeader from '~/components/TableHeader'
import { CompanySchoolsHeadCells } from './CompanyHeadCells'
import TableLoading from '~/components/Table/TableLoading'
import DataNotFound from '~/components/DataNotFound'
import { useParams } from 'react-router-dom'
import { useSchoolsByCompanyIdQuery } from './query'
import PaginationTable from '~/components/PaginationTable'
import useTable from '~/hooks/useTable'
import PropTypes from 'prop-types'

const ComponentSchoolsTableBody = ({ isLoading, schools }) => {
  if (isLoading) {
    return <TableLoading colSpan={CompanySchoolsHeadCells.length} />
  }

  return (
    <TableBody>
      {schools.length === 0 && <DataNotFound colSpan={CompanySchoolsHeadCells.length} />}
      {schools.length > 0 &&
        schools.map((row, index) => {
          return (
            <TableRow hover key={index}>
              <TableCell align='left'>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.tenant?.name}</TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

ComponentSchoolsTableBody.propTypes = {
  isLoading: PropTypes.bool,
  schools: PropTypes.array
}

ComponentSchoolsTableBody.defaultProps = {
  isLoading: false,
  schools: []
}

const ComponentSchools = () => {
  const { id } = useParams()

  const {
    data: schools,
    isLoading,
    order,
    page,
    orderBy,
    sortHandler,
    setPage
  } = useTable({
    query: useSchoolsByCompanyIdQuery,
    queryAttributes: {
      id
    },
    initialStates: {
      order: 'asc',
      page: 1,
      orderBy: 'name'
    }
  })

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
          <TableHeader
            headCells={CompanySchoolsHeadCells}
            onRequestSort={sortHandler}
            order={order}
            orderBy={orderBy}
            localization=''
          />
          <ComponentSchoolsTableBody isLoading={isLoading} schools={schools?.data || []} />
        </Table>
      </TableContainer>
      <PaginationTable
        data={schools}
        page={page}
        handleChangePage={(_, value) => setPage(value)}
        size='medium'
      />
    </Paper>
  )
}

export default ComponentSchools
