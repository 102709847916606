import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectAdminPermissions } from '~/store/adminAuth/selector'
import { pathToRegexp } from 'path-to-regexp'
import {
  TENANT,
  TENANT_VIEW,
  TENANT_VIEW_DETAIL,
  REGISTER_SESSION,
  REGISTER_SESSION_VIEW,
  REGISTER_SESSION_VIEW_DETAIL,
  DEVICE,
  DEVICE_VIEW,
  DEVICE_VIEW_DETAIL,
  ADMINISTRATOR,
  ADMINISTRATOR_INVITE,
  ADMINISTRATOR_INVITE_AZURE,
  ADMINISTRATOR_INVITE_GOOGLE,
  USER,
  USER_CHANGE_ROLE,
  USER_CHANGE_TENANT,
  USER_CHANGE_DOMAIN,
  SCHOOL_USER,
  SCHOOL_USER_VIEW,
  SCHOOL_USER_VIEW_DETAIL,
  SCHOOL_USER_DISABLE,
  USER_ADMIN,
  USER_ADMIN_CHANGE_ROLE,
  USER_ADMIN_VIEW,
  USER_ADMIN_VIEW_DETAIL,
  USER_ADMIN_DISABLE,
  COMPANY,
  COMPANY_VIEW,
  COMPANY_CREATE,
  COMPANY_EDIT,
  COMPANY_VIEW_DETAIL,
  ROLE,
  ROLE_VIEW,
  ROLE_CREATE,
  ROLE_EDIT,
  SETTING,
  TENANT_SYNC_DEVICES,
  TENANT_CHECK_STATUS,
  TENANT_REMOVE_INACTIVE,
  DEVICE_REGISTER,
  DEVICE_DEREGISTER,
  ADMINISTRATOR_VIEW
} from '~/constants/adminPermission'

const ROUTE_PERMISSION_MAPPING = {
  '/overview/login': [],
  '/overview/tenants': [TENANT, TENANT_VIEW],
  '/overview/tenants/:tenantId': [TENANT, TENANT_VIEW_DETAIL],
  '/overview/devices-register-sessions/:sessionId': [
    REGISTER_SESSION,
    REGISTER_SESSION_VIEW_DETAIL
  ],
  '/overview/devices': [DEVICE, DEVICE_VIEW],
  '/overview/devices-register-sessions': [REGISTER_SESSION, REGISTER_SESSION_VIEW],
  '/overview/devices/azure/:id': [DEVICE, DEVICE_VIEW_DETAIL],
  '/overview/devices/google/:id': [DEVICE, DEVICE_VIEW_DETAIL],
  '/overview/uses/companies': [ADMINISTRATOR, COMPANY_VIEW, COMPANY],
  '/overview/uses/companies/new': [ADMINISTRATOR, COMPANY_CREATE, COMPANY],
  '/overview/uses/companies/edit/:id': [ADMINISTRATOR, COMPANY_EDIT, COMPANY],
  '/overview/uses/companies/:id': [ADMINISTRATOR, COMPANY_VIEW_DETAIL, COMPANY],
  '/overview/uses/roles': [ADMINISTRATOR, ROLE_VIEW, ROLE],
  '/overview/uses/roles/new': [ADMINISTRATOR, ROLE_CREATE, ROLE],
  '/overview/uses/roles/:id': [ADMINISTRATOR, ROLE_EDIT, ROLE],
  '/overview/invite-azure-tenant': [
    ADMINISTRATOR,
    ADMINISTRATOR_INVITE,
    ADMINISTRATOR_INVITE_AZURE
  ],
  '/overview/invite-google-domain': [
    ADMINISTRATOR,
    ADMINISTRATOR_INVITE,
    ADMINISTRATOR_INVITE_GOOGLE
  ],
  '/overview/users': [ADMINISTRATOR, USER, SCHOOL_USER, SCHOOL_USER_VIEW],
  '/overview/users/:id': [ADMINISTRATOR, USER, SCHOOL_USER, SCHOOL_USER_VIEW_DETAIL],
  '/overview/users-disable': [ADMINISTRATOR, USER, SCHOOL_USER, SCHOOL_USER_DISABLE],
  '/overview/super-users': [ADMINISTRATOR, USER, USER_ADMIN, USER_ADMIN_VIEW],
  '/overview/super-users/:id': [ADMINISTRATOR, USER, USER_ADMIN, USER_ADMIN_VIEW_DETAIL],
  '/overview/super-users-disable': [ADMINISTRATOR, USER, USER_ADMIN, USER_ADMIN_DISABLE],
  '/overview/settings': [ADMINISTRATOR, SETTING]
}

export const useAdminPermission = () => {
  const adminPermissions = useSelector(selectAdminPermissions)
  const location = useLocation()
  const pathname = location.pathname

  const hasPermission = useMemo(() => {
    if (!pathname.startsWith('/overview/') || adminPermissions.includes('super_admin')) {
      return true
    }

    // check if user have permission to access this route,
    // if the url not exist, dont need to check permission
    for (const url in ROUTE_PERMISSION_MAPPING) {
      const pattern = pathToRegexp(url)
      const match = pattern.exec(pathname)

      if (match) {
        const permissions = ROUTE_PERMISSION_MAPPING[url]
        return permissions.some(permission => adminPermissions.includes(permission))
      }
    }

    return true
  }, [adminPermissions, pathname])

  const canEvery = permissions => {
    if (adminPermissions.includes('super_admin') || adminPermissions.includes('overview.*')) {
      return true
    }

    if (Array.isArray(permissions)) {
      return permissions.every(permission => adminPermissions.includes(permission))
    }
    return adminPermissions.includes(permissions)
  }

  const canAny = permissions => {
    if (adminPermissions.includes('super_admin')) {
      return true
    }

    if (Array.isArray(permissions)) {
      return !!permissions.some(permission => adminPermissions.includes(permission))
    }
    return !!adminPermissions.includes(permissions)
  }

  const overviewUser = {
    tenants: {
      canCheckStatus: canAny([TENANT, TENANT_CHECK_STATUS]),
      canRemoveTenant: canAny([TENANT, TENANT_REMOVE_INACTIVE]),
      canView: canAny([TENANT, TENANT_VIEW]),
      canViewDetail: canAny([TENANT, TENANT_VIEW_DETAIL]),
      canSyncDevices: canAny([TENANT, TENANT_SYNC_DEVICES])
    },
    devices: {
      canView: canAny([DEVICE, DEVICE_VIEW]),
      canViewDetail: canAny([DEVICE, DEVICE_VIEW_DETAIL]),
      canRegister: canAny([DEVICE, DEVICE_REGISTER]),
      canDeregister: canAny([DEVICE, DEVICE_DEREGISTER])
    },
    devicesRegisterSession: {
      canView: canAny([REGISTER_SESSION, REGISTER_SESSION_VIEW]),
      canViewDetail: canAny([REGISTER_SESSION, REGISTER_SESSION_VIEW_DETAIL])
    },
    administrator: {
      canView: canAny([ADMINISTRATOR, ADMINISTRATOR_VIEW]),
      canInviteAzureTenants: canAny([
        ADMINISTRATOR,
        ADMINISTRATOR_INVITE,
        ADMINISTRATOR_INVITE_AZURE
      ]),
      canInviteGoogleDomain: canAny([
        ADMINISTRATOR,
        ADMINISTRATOR_INVITE,
        ADMINISTRATOR_INVITE_GOOGLE
      ]),
      users: {
        canView: canAny([ADMINISTRATOR, USER, SCHOOL_USER, SCHOOL_USER_VIEW]),
        canViewDetail: canAny([ADMINISTRATOR, USER, SCHOOL_USER, SCHOOL_USER_VIEW_DETAIL]),
        canChangeRole: canAny([ADMINISTRATOR, USER, USER_CHANGE_ROLE]),
        canChangeAzureTenant: canAny([ADMINISTRATOR, USER, USER_CHANGE_TENANT]),
        canChangeGoogleDomain: canAny([ADMINISTRATOR, USER, USER_CHANGE_DOMAIN]),
        canDisable: canAny([ADMINISTRATOR, USER, SCHOOL_USER, SCHOOL_USER_DISABLE]),
        canViewAdmin: canAny([ADMINISTRATOR, USER, USER_ADMIN, USER_ADMIN_VIEW]),
        canViewAdminDetail: canAny([ADMINISTRATOR, USER, USER_ADMIN, USER_ADMIN_VIEW_DETAIL]),
        canDisableAdmin: canAny([ADMINISTRATOR, USER, USER_ADMIN, USER_ADMIN_DISABLE]),
        canChangeAdminRole: canAny([ADMINISTRATOR, USER, USER_ADMIN, USER_ADMIN_CHANGE_ROLE])
      },
      companies: {
        canView: canAny([ADMINISTRATOR, COMPANY, COMPANY_VIEW]),
        canViewDetail: canAny([ADMINISTRATOR, COMPANY, COMPANY_VIEW_DETAIL]),
        canCreate: canAny([ADMINISTRATOR, COMPANY, COMPANY_CREATE]),
        canEdit: canAny([ADMINISTRATOR, COMPANY, COMPANY_EDIT])
      },
      roles: {
        canView: canAny([ADMINISTRATOR, ROLE, ROLE_VIEW]),
        canCreate: canAny([ADMINISTRATOR, ROLE, ROLE_CREATE]),
        canEdit: canAny([ADMINISTRATOR, ROLE, ROLE_EDIT])
      },
      settings: {
        canView: canAny([ADMINISTRATOR, SETTING])
      }
    }
  }

  return {
    hasPermission,
    canEvery,
    canAny,
    overviewUser
  }
}
