export const GooleDeviceHeadCells = [
  {
    id: 'serial',
    numeric: false,
    disablePadding: false,
    label: 'serialNumber',
    orderable: true
  },
  {
    id: 'googleDomain',
    disablePadding: false,
    label: 'googleDomain',
    orderable: false
  },
  {
    id: 'Provisioning Token',
    numeric: false,
    disablePadding: false,
    label: 'preProvisioningToken',
    orderable: false
  },
  {
    id: 'Pre Provisioned Device_id',
    numeric: false,
    disablePadding: false,
    label: 'preProvisionedDeviceId',
    orderable: false
  },
  {
    id: 'Brand Code',
    numeric: false,
    disablePadding: false,
    label: 'brandCode',
    orderable: false
  },
  {
    id: 'State',
    numeric: true,
    disablePadding: false,
    label: 'state',
    orderable: false
  }
]
