import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import ToolBarButton from '~/components/Button/ToolBarButton'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale'
import CustomModal from '~/components/Modal/CustomModal'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import useLanguage from '~/hooks/useLanguage'
import { getUserStatusColor } from '~/utils/helpers'
import { UserHeadCells } from './UserHeadCells'
import { useAddExtraUserToTenantMutation } from './mutate'
import { useActiveTenantsQuery, useUsersQuery } from './query'

const localization = 'pages.adminOverview.users.'
const schema = yup
  .object({
    tenant_id: yup.string().required(),
    email: yup.string().email().required(),
    language: yup.string().required()
  })
  .required()

const UsersTableBody = ({ isLoading, users }) => {
  const { overviewUser } = useAdminPermission()

  if (isLoading) {
    return <TableLoading colSpan={UserHeadCells.length} />
  }

  const renderIdCol = row =>
    overviewUser.administrator.users.canViewDetail ? (
      <Link href={`/overview/users/${row.id}`} sx={{ ml: 2 }}>
        {row.id}
      </Link>
    ) : (
      row.id
    )

  return (
    <TableBody>
      {users?.data?.length === 0 && <DataNotFound colSpan={UserHeadCells.length} />}
      {users?.data?.length > 0 &&
        users?.data?.map(row => {
          const chipcolor = getUserStatusColor(row?.status)
          return (
            <TableRow key={row.id}>
              <TableCell>{renderIdCol(row)}</TableCell>
              <TableCell>{row?.name}</TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.role}</TableCell>
              <TableCell
                sx={{
                  textAlign: 'center'
                }}
              >
                <Tooltip title={<DateTimeLocale datetime={row?.last_signed_in} />}>
                  <Box>{row?.last_signed_in_at}</Box>
                </Tooltip>
              </TableCell>
              <TableCell>{row?.ip_address}</TableCell>
              <TableCell>
                <Chip label={row?.status} color={chipcolor} size='small' />
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row?.created_at} />
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

UsersTableBody.defaultProps = {
  isLoading: false,
  users: {}
}

UsersTableBody.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.object
}

const UsersTable = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [page, setPage] = useState(1)
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const { languages, language, setLanguage } = useLanguage()

  const { data, isLoaing } = useUsersQuery({
    page,
    search,
    order,
    orderBy,
    locale: i18n.language
  })
  const { data: tenants, isSuccess } = useActiveTenantsQuery()

  const { mutate } = useAddExtraUserToTenantMutation()

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const searchHandler = debounce(event => {
    setSearchValue(event.target.value)
    setPage(1)
  }, 500)

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  const handleOnClose = () => {
    setOpen(false)
  }

  const onSubmit = async payload => {
    mutate(payload, {
      onSuccess: () => {
        setOpen(false)
        reset()
        setSelected(null)
      }
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tenant_id: '',
      email: '',
      language: 'en'
    }
  })

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Users</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={3}>
            <ToolBarButton
              icon={<AddIcon />}
              name={t(`${localization}add`)}
              handleAction={() => {
                setOpen(true)
              }}
            />
          </Grid>
          <Box sx={{ flexGrow: 0.01 }} />
          <Grid item xs={6} sm={3}>
            <SearchToolBar filterSearch={search} onChange={searchHandler} placeholder='users' />
          </Grid>
        </Toolbar>
        <Divider />

        <TableContainer>
          <Table>
            <TableHeader
              headCells={UserHeadCells}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <UsersTableBody isLoaing={isLoaing} users={data} />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={handleChangePage}
        />
      </Paper>
      <CustomModal open={open} onClose={handleOnClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item mb={5}>
            <Typography>{t(`${localization}add`)}</Typography>
          </Grid>

          <Grid item mb={2}>
            <TextField
              fullWidth
              variant='outlined'
              label={t(`${localization}email`)}
              onChange={() => {}}
              error={!!errors.email}
              {...register('email')}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item mb={2}>
            {isSuccess && (
              <Autocomplete
                id='tenant'
                name='tenant'
                value={selected}
                options={tenants?.map(option => ({
                  label: option.default_domain_name,
                  id: option.id
                }))}
                getOptionLabel={option => option.label}
                onChange={(e, value) => {
                  setSelected(value)
                  setValue('tenant_id', value.id)
                }}
                sx={{ mt: 2 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t(`${localization}selectTenant`)}
                    onChange={() => {}}
                    error={!!errors.tenant_id}
                    helperText={errors.tenant_id?.message}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item mb={2}>
            <Autocomplete
              id='language'
              name='language'
              freeSolo
              value={language}
              options={languages.map(option => option)}
              onChange={(e, value) => {
                setValue('language', value.id)
                setLanguage(value)
              }}
              sx={{ mt: 1 }}
              renderInput={params => <TextField {...params} label={t(`${localization}language`)} />}
            />
          </Grid>
          <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
            <LoadingButton variant='contained' type='submit'>
              {t(`${localization}save`)}
            </LoadingButton>
            <LoadingButton variant='contained' type='submit' onClick={handleOnClose}>
              {t(`${localization}cancel`)}
            </LoadingButton>
          </Stack>
        </form>
      </CustomModal>
    </>
  )
}

export default UsersTable
