import Axios from 'axios'
import { KEY_ADMIN_AUTH_TOKEN } from '~/constants/constants'

const REQUEST_TIMEOUT = 30000
const REQUEST_FORM_TIMEOUT = 30000

export const adminAxiosClient = Axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json'
  },
  timeout: REQUEST_TIMEOUT
})

export const adminAxiosFormClient = Axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    contentType: 'multipart/form-data'
  },
  processData: false,
  mimeType: 'multipart/form-data',
  contentType: false,
  timeout: REQUEST_FORM_TIMEOUT
})

adminAxiosClient.interceptors.request.use(config => {
  // If the request is a GET request and the 'locale' param is missing, add it
  if (config.method === 'get' && !config.params?.locale) {
    config.params = {
      ...config.params,
      locale: localStorage.getItem('LOCALE') || 'nl'
    }
  }
  return config
})

export const setApiAdminAuthToken = t => {
  adminAxiosClient.defaults.headers.common.Authorization = `Bearer ${t}`
  adminAxiosClient.defaults.headers.common['X-CSRF-TOKEN'] = t
  adminAxiosFormClient.defaults.headers.common.Authorization = `Bearer ${t}`
  adminAxiosFormClient.defaults.headers.common['X-CSRF-TOKEN'] = t
}

setApiAdminAuthToken(localStorage.getItem(KEY_ADMIN_AUTH_TOKEN))

export default adminAxiosClient
