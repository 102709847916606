import { enqueueSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { overviewCreateRole } from '~/api'
import { ADMIN_USER_ROLE } from '~/constants/Routes'

export const useCreateMutation = props => {
  const navigate = useNavigate()

  return useMutation(
    ['user_create'],
    async values => {
      const permissions = values.permissions
        .filter(permission => {
          return permission
        })
        .map(permission => {
          return permission
        })
      return await overviewCreateRole({
        ...values,
        permissions
      })
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message ? data.message : 'Create successful!',
          variant: 'success'
        })
        navigate(ADMIN_USER_ROLE)
        return data
      },
      onError: error => {
        let message = 'Create failed!'
        if (error.response.data.message) {
          message = error.response.data.message
        } else if (error.message) {
          message = error.message
        }
        enqueueSnackbar({
          message,
          variant: 'error'
        })
        navigate(ADMIN_USER_ROLE)
      },
      retry: false
    }
  )
}
