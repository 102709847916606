import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useReRegistrationAppMutation } from './mutation'

const ReRegisterApplication = ({ tenantId, visible, suspendAppRegister, onSuccess }) => {
  const reRegistrationAppMutation = useReRegistrationAppMutation()

  const doRegisterApplication = useCallback(() => {
    reRegistrationAppMutation.mutate(
      { tenant_id: tenantId },
      {
        onSuccess
      }
    )
  }, [onSuccess, reRegistrationAppMutation, tenantId])

  if (!visible) {
    return null
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <div>
          <LoadingButton
            loading={reRegistrationAppMutation.isLoading || suspendAppRegister}
            onClick={doRegisterApplication}
            sx={{ mt: 1, mr: 1 }}
            variant='contained'
          >
            Register Application
          </LoadingButton>
        </div>
      </Box>
    </>
  )
}

ReRegisterApplication.propTypes = {
  tenantId: PropTypes.number,
  visible: PropTypes.bool,
  suspendAppRegister: PropTypes.bool,
  onSuccess: PropTypes.func
}

ReRegisterApplication.defaultProps = {
  tenantId: null,
  visible: true,
  suspendAppRegister: false,
  onSuccess: () => {}
}

export default ReRegisterApplication
