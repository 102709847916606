import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import {} from '~/api'
import { getRegistrationDetail, getRegistrations } from '~/api/admin'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useAzureRegistrationQuery = params => {
  return useQuery(
    ['tenant_registrations', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await getRegistrations(p)
      return { data: data?.data, total: data?.meta?.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useAzureRegistrationDetailQuery = id => {
  return useQuery(
    ['tenant_registration_detail', id],
    async () => {
      const { data } = await getRegistrationDetail(id)
      return data?.data
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false,
      enabled: !!id
    }
  )
}
