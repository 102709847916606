import { useEffect, useMemo, useRef, useState } from 'react'
// material
import { alpha } from '@mui/material/styles'
import { Box, MenuItem, Stack, IconButton } from '@mui/material'
// components
import MenuPopover from './MenuPopover'
import { useTranslation } from 'react-i18next'
import { DEFAULT_LANGUAGE, MULTI_LANGUAGES } from '~/constants/constants'

export default function LanguagePopover() {
  const anchorRef = useRef(null)
  const { i18n } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const chooseLanguage = value => {
    setOpen(false)
    i18n.changeLanguage(value)
    localStorage.setItem('LOCALE', value)
  }
  if (!localStorage.getItem('LOCALE')) {
    localStorage.setItem('LOCALE', DEFAULT_LANGUAGE)
  }
  const locale = localStorage.getItem('LOCALE')

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale, i18n])

  const flag = useMemo(() => {
    if (i18n.language !== locale) {
      return MULTI_LANGUAGES.find(x => x.locale === i18n.language) ?? MULTI_LANGUAGES[0]
    } else {
      return MULTI_LANGUAGES.find(x => x.locale === locale) ?? MULTI_LANGUAGES[0]
    }
  }, [i18n.language, locale])

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img src={flag.icon} alt={flag.language} width={30} height={30} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75
          }
        }}
      >
        <Stack spacing={0.75}>
          {MULTI_LANGUAGES.map(option => (
            <MenuItem
              key={option.locale}
              selected={option.locale === i18n.language ?? DEFAULT_LANGUAGE}
              onClick={() => chooseLanguage(option.locale)}
            >
              <Box
                component='img'
                alt={option.language}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.language}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
