import { enqueueSnackbar } from 'notistack'
import { getAppointmentLinkSettings } from '~/api/admin'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useAppointmentLinkSettingQuery = () => {
  return useQuery(
    ['appointment_link_setting'],
    async () => {
      const { data } = await getAppointmentLinkSettings()
      return data?.data
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}
