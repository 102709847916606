import { PublicClientApplication } from '@azure/msal-browser'
import {
  FETCHING_HEALTHCHECK_TENANT_INTERVAL,
  FETCHING_HEALTHCHECK_TENANT_INTERVAL_KEY
} from '~/pages/HealthCheck/HealthCheck'
const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common/'
  }
}

const msalInstance = new PublicClientApplication(msalConfig)

const msalLoginPopup = async (scopes = ['User.Read']) => {
  const loginResponse = await msalInstance.loginPopup({
    scopes,
    prompt: 'select_account'
  })
  return loginResponse
}

export default msalLoginPopup

export const grantPermissionRedirect = (tenantUdid, redirectUrl) => {
  const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID
  const state = Math.random().toString().substring(2)
  const url = `https://login.microsoftonline.com/${tenantUdid}/adminconsent?client_id=${clientId}&redirect_uri=${redirectUrl}&state=${state}`
  window.location.href = url
  localStorage.setItem(
    FETCHING_HEALTHCHECK_TENANT_INTERVAL_KEY,
    FETCHING_HEALTHCHECK_TENANT_INTERVAL
  ) // Continious fetching permission state
  localStorage.setItem('GRANT_PERMISSION_STATE', state)

  return state
}
