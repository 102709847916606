import { Button } from '@mui/material'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const ToolBarButton = ({ icon, color, handleAction, name, disabled, loading }) => {
  const colorButton = useMemo(() => {
    if (color === 'error') {
      return '#FF0000'
    }
    if (color === 'close' || color === 'cancel') {
      return '#a59f9f'
    }

    return '#76B72A'
  }, [color])

  return (
    <Button
      variant='contained'
      size='small'
      startIcon={icon}
      onClick={handleAction}
      disabled={disabled}
      loading={loading}
      sx={{
        backgroundColor: colorButton
      }}
    >
      {name}
    </Button>
  )
}

ToolBarButton.defaultProps = {
  color: 'success',
  disabled: false,
  loading: false
}

ToolBarButton.propTypes = {
  icon: PropTypes.element.isRequired,
  color: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default ToolBarButton
