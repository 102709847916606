import { useSelector } from 'react-redux'
import { selectUser } from '../../store/auth/selector'
import HealthCheck from './HealthCheck'
import {
  getHealthCheckStatuses,
  getTenantInfo,
  grantApplicationPermission,
  healthCheckSyncData,
  syncDevices
} from '~/api'

const HealthCheckPage = () => {
  const user = useSelector(selectUser)

  if (user == null) {
    return null
  }

  return (
    <HealthCheck
      tenantId={user.tenant?.id}
      getTenantInfoApi={getTenantInfo}
      healthCheckApi={getHealthCheckStatuses}
      healthCheckSyncDataApi={healthCheckSyncData}
      syncDevicesApi={syncDevices}
      grantApplicationPermissionApi={grantApplicationPermission}
      canGrantPermission
    />
  )
}

export default HealthCheckPage
