export const SUPER_ADMIN = 'super_admin'
export const DASHBOARD = 'dashboard.*'
export const DASHBOARD_VIEW = 'dashboard.view'
export const HEALTHCHECK = 'dashboard.healthcheck.*'
export const HEALTHCHECK_VIEW = 'dashboard.healthcheck.view'
export const DEVICES = 'devices.*'
export const DEVICES_REPAIR = 'devices.repair'
export const DEVICES_VIEW = 'devices.view'
export const DEVICES_VIEW_DETAIL = 'devices.view_detail'
export const DEVICES_REGISTER = 'devices.register'
export const DEVICES_DEREGISTER = 'devices.deregister'
export const DEVICES_BULK_DEREGISTER = 'devices.bulk_deregister'
export const DEVICES_BULK_DEREGISTER_BY_CSV = 'devices.bulk_deregister_by_csv'
export const ORGANISATIONS = 'organisations.*'
export const ORGANISATIONS_VIEW = 'organisations.view'
export const ORGANISATIONS_OVERVIEW = 'organisations.overview'
export const ORGANISATIONS_REMOVE_TENANT = 'organisations.remove_tenant'
export const GROUP_TAG = 'organisations.group_tag.*'
export const GROUP_TAG_VIEW = 'organisations.group_tag.view'
export const GROUP_TAG_UPDATE = 'organisations.group_tag.edit'
export const ZTE_TOKEN = 'organisations.zte_token.*'
export const ZTE_TOKEN_VIEW = 'organisations.zte_token.view'
export const ZTE_TOKEN_UPDATE = 'organisations.zte_token.edit'
