import { Breadcrumbs, Divider, Typography } from '@mui/material'
import OrganisationTree from './OrganisationOverViewTable/OrganisationTree'

const Organisations = () => {
  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Organisations</Typography>
      </Breadcrumbs>
      <Divider />
      <OrganisationTree />
    </>
  )
}

export default Organisations
