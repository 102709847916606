import PropTypes from 'prop-types'
import Azure from '~/assets/images/organisations/Azure.svg'
import Google from '~/assets/images/organisations/Google.svg'
import { Icon } from '@mui/material'

const TenantIcon = ({ item }) => {
  if (item.is_azure) {
    return (
      <Icon>
        <img src={Azure} alt='Azure' />
      </Icon>
    )
  }

  if (item.is_google) {
    return (
      <Icon>
        <img src={Google} alt='Google' />
      </Icon>
    )
  }

  return <Icon style={{ visibility: 'hidden' }}></Icon>
}

TenantIcon.propTypes = {
  item: PropTypes.object.isRequired
}

export default TenantIcon
