import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useGroupTagsQuery } from '../query'
import { GroupTagHeadCells } from './GroupTagHeadCells'

const localization = 'pages.organisation.'

const GroupTagTableBody = ({ isLoading, groupTags }) => {
  if (isLoading) {
    return <TableLoading colSpan={GroupTagHeadCells.length} />
  }

  return (
    <TableBody>
      {groupTags.length > 0 ? (
        groupTags.map((row, index) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align='left'>{row.grouptag}</TableCell>
              <TableCell align='left'>{row.school}</TableCell>
              <TableCell align='left'>{row.devices}</TableCell>
            </TableRow>
          )
        })
      ) : (
        <DataNotFound colSpan={GroupTagHeadCells.length} />
      )}
    </TableBody>
  )
}

GroupTagTableBody.propTypes = {
  isLoading: PropTypes.bool,
  groupTags: PropTypes.array
}

GroupTagTableBody.defaultProps = {
  isLoading: false,
  groupTags: []
}

const GroupTagTable = () => {
  const { data, isLoading } = useGroupTagsQuery()
  return (
    <>
      <Paper elevation={1}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader headCells={GroupTagHeadCells} localization={localization} />
            <GroupTagTableBody isLoading={isLoading} groupTags={data ? data.data : []} />
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}
export default GroupTagTable
