import {
  Chip,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import { GooleDeviceHeadCells } from './GoogleDeviceHeadCells'

const localization = 'pages.device.'

const DeviceTableBody = ({ isLoading, devices }) => {
  const { overviewUser } = useAdminPermission()

  if (isLoading) {
    return <TableLoading colSpan={GooleDeviceHeadCells.length} />
  }

  const renderSerialCol = row => (
    <>
      {overviewUser.devices.canViewDetail ? (
        <Link to={'/overview/devices/google/' + row.id}>
          <MuiLink>{row.serial}</MuiLink>
        </Link>
      ) : (
        <>{row.serial}</>
      )}
    </>
  )

  return (
    <TableBody>
      {devices.length === 0 && <DataNotFound colSpan={GooleDeviceHeadCells.length} />}
      {devices.length > 0 &&
        devices.map((row, index) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align='left'>{renderSerialCol(row)}</TableCell>
              <TableCell align='left'>{row.google_domain?.hosted_domain}</TableCell>
              <TableCell>{row.google_domain?.pre_provisioning_token}</TableCell>
              <TableCell>{row.pre_provisioned_device_id}</TableCell>
              <TableCell>{row.brand_code}</TableCell>
              <TableCell align='center'>
                {row.state && <Chip label={row.state} color='default' size='small' />}
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

DeviceTableBody.propTypes = {
  isLoading: PropTypes.bool,
  devices: PropTypes.array
}

DeviceTableBody.defaultProps = {
  isLoading: false,
  devices: []
}

const GoogleDeviceTable = ({ order, orderBy, isLoading, devices, handleRequestSort }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
        <TableHeader
          headCells={GooleDeviceHeadCells}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          localization={localization}
        />
        <DeviceTableBody isLoading={isLoading} devices={devices} />
      </Table>
    </TableContainer>
  )
}

GoogleDeviceTable.defaultProps = {
  order: 'asc',
  orderBy: '',
  isLoading: false,
  devices: [],
  handleRequestSort: () => {}
}

GoogleDeviceTable.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  isLoading: PropTypes.bool,
  devices: PropTypes.array,
  handleRequestSort: PropTypes.func
}

export default GoogleDeviceTable
