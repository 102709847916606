export const azureHeadCells = [
  {
    id: 'default_domain_name',
    numeric: false,
    disablePadding: false,
    label: 'defaultDomainName',
    orderable: false
  },
  {
    id: 'initial_domain_name',
    numeric: false,
    disablePadding: false,
    label: 'initialDomainName',
    orderable: false
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'active',
    orderable: true
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'updatedAt',
    orderable: false
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'status',
    orderable: false
  }
]

export const GoogleHeadCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'name',
    orderable: false
  },
  {
    id: 'hosted_domain',
    numeric: false,
    disablePadding: false,
    label: 'hostedDomain',
    orderable: false
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'active',
    orderable: true
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'updatedAt',
    orderable: false
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'status',
    orderable: false
  }
]
