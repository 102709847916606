import { CircularProgress, TableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

const TableLoading = ({ colSpan }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell align='center' colSpan={colSpan} sx={{ py: 3 }}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

TableLoading.defaultProps = {
  colSpan: 1
}

TableLoading.propTypes = {
  colSpan: PropTypes.number
}

export default TableLoading
