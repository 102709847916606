export const RegisterSessionHeadCells = [
  {
    id: 'checkbox',
    checkbox: true
  },
  {
    id: 'serial',
    numeric: false,
    disablePadding: false,
    label: 'serialNumber',
    orderable: true
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'organisation',
    orderable: true
  },
  {
    id: 'success_code',
    numeric: false,
    disablePadding: false,
    label: 'successCode',
    orderable: true
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'state',
    orderable: true
  },
  {
    id: 'is_completed',
    numeric: false,
    disablePadding: false,
    label: 'completed',
    orderable: true
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'message',
    orderable: true
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'createdAt',
    orderable: true
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'updatedAt',
    orderable: true
  }
]
