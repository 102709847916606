import { useTranslation } from 'react-i18next'
import BreadCrumb from '~/components/BreadCrumb'

const AdminDashboard = () => {
  const { t } = useTranslation()
  const BreadComponents = [
    {
      title: t('general.dashboard')
    }
  ]

  return <BreadCrumb breadcrumbs={BreadComponents} />
}

export default AdminDashboard
