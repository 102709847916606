import { useQuery } from 'react-query'
import {
  overviewGetCompany,
  overviewGetListCompanies,
  overviewGetSchoolsByCompanyId,
  overviewGetTenantsByCompanyId,
  overviewGetUsersByCompanyId
} from '~/api'

export const useOverviewCompaniesQuery = params => {
  return useQuery(
    ['overview_companies', params],
    async () => {
      const { data } = await overviewGetListCompanies(params)
      return { data: data.data, total: data.meta.total }
    },
    {
      retry: false
    }
  )
}

export const useOverviewCompanyQuery = id => {
  return useQuery(
    ['overview_company', id],
    async () => {
      const { data } = await overviewGetCompany(id)
      return data?.data
    },
    {
      retry: false
    }
  )
}

export const useUserByCompanyIdQuery = params => {
  return useQuery(
    ['overview_users_by_company', params],
    async () => {
      const { data } = await overviewGetUsersByCompanyId(params.id, params)
      return { data: data?.data || [], total: data?.meta?.total || 0 }
    },
    {
      retry: false
    }
  )
}

export const useSchoolsByCompanyIdQuery = params => {
  return useQuery(
    ['overview_schools_by_company', params],
    async () => {
      const { data } = await overviewGetSchoolsByCompanyId(params.id, params)
      return { data: data?.data || [], total: data?.meta?.total || 0 }
    },
    {
      retry: false
    }
  )
}

export const useTenantsByCompanyIdQuery = params => {
  return useQuery(
    ['overview_tenants_by_company', params],
    async () => {
      const { data } = await overviewGetTenantsByCompanyId(params.id, params)
      return { data: data?.data || [], total: data?.meta?.total || [] }
    },
    {
      retry: false
    }
  )
}
