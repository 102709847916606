import { yupResolver } from '@hookform/resolvers/yup'
import { Breadcrumbs, Divider, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'

import { Controller, useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { useMemo, useState } from 'react'
import * as yup from 'yup'
import { LoadingButton } from '~/components/Button/LoadingButton'
import { useCreateGoogleDeviceMutation } from '../../mutation'
import { useGoogleHardwareModelListQuery } from '../../Overview/query'
import { GOOGLE_DEVICES } from '~/constants/Routes'
import { useTranslation } from 'react-i18next'
import { useAllPreProvisionTokensQuery } from '~/pages/Organisations/PreprovisionToken/query'
import EntitySection from '~/components/EntitySection'

const RegisterGoogleDevice = () => {
  const { t } = useTranslation()
  const schema = yup.object().shape({
    serial_number: yup.string().required(),
    hardware_model: yup.string().required(),
    domain: yup.string(),
    customer_id: yup.string(),
    token: yup.string().nullable(true),
    external_id: yup
      .string()
      .nullable()
      .test({
        name: 'schoolEntityCheck',
        message: t('validation.schoolAndEntityReuiredTogether'),
        test: function (value) {
          const { entity_id } = this.parent
          if ((value && !entity_id) || (!value && entity_id)) {
            return false
          }
          return true
        }
      }),
    entity_id: yup
      .string()
      .nullable()
      .test({
        name: 'entitySchoolCheck',
        message: t('validation.schoolAndEntityReuiredTogether'),
        test: function (value) {
          const { external_id } = this.parent
          if ((value && !external_id) || (!value && external_id)) {
            return false
          }
          return true
        }
      })
  })
  const [entity, setEntity] = useState({ label: '', id: '' })

  const { mutate: createNewDevices, isLoading } = useCreateGoogleDeviceMutation()
  const onSubmit = async values => {
    createNewDevices(values)
  }

  const { data } = useGoogleHardwareModelListQuery()

  const { data: tokenData, refetch } = useAllPreProvisionTokensQuery()

  const hardwareModels = useMemo(() => {
    return (
      data?.map(v => {
        return { label: v.name, value: v.name }
      }) || [{ label: 'No options', value: '' }]
    )
  }, [data])

  const tokens = useMemo(() => {
    return (
      tokenData?.data?.map(v => {
        return { label: v.pre_provisioning_token, description: v.description }
      }) || [{ label: 'No options', value: '' }]
    )
  }, [tokenData])

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(schema)
  })

  const localization = 'pages.device'

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to={GOOGLE_DEVICES}>{t(`${localization}.detail.deviceOverview`)}</NavLink>
        </Typography>
        <Typography>{t('button.register')}</Typography>
      </Breadcrumbs>
      <Divider style={{ marginBottom: 20 }} />
      <Paper
        sx={{
          px: 2,
          py: 2
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <TextField
              label={t(`${localization}.create.serialNumber`)}
              name='serial_number'
              {...register('serial_number')}
              error={!!errors.serial_number}
              helperText={<>{!!errors.serial_number && errors.serial_number?.message}</>}
            />
            <Controller
              name='hardware_model'
              control={control}
              defaultValue={null}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  options={hardwareModels}
                  renderInput={params => (
                    <TextField
                      inputRef={ref}
                      error={!!errors.hardware_model}
                      helperText={<>{!!errors.hardware_model && errors.hardware_model?.message}</>}
                      name='hardware_model'
                      label={t(`${localization}.create.hardwareModel`)}
                      {...params}
                    />
                  )}
                  {...register('hardware_model')}
                />
              )}
            />
            <TextField
              label={t(`${localization}.create.externalID`)}
              name='external_id'
              {...register('external_id')}
              error={!!errors.external_id}
              helperText={<>{!!errors.external_id && errors.external_id?.message}</>}
            />
            <EntitySection
              errors={errors}
              setValue={setValue}
              control={control}
              entity={entity}
              setEntity={setEntity}
            />
            <Controller
              name='token'
              control={control}
              defaultValue={null}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  options={tokens}
                  freeSolo
                  getOptionLabel={option => option.label}
                  renderOption={(props, option) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <b>{option.label}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{option.description}
                    </Box>
                  )}
                  renderInput={params => (
                    <TextField
                      inputRef={ref}
                      error={!!errors.token}
                      helperText={<>{!!errors.token && errors.token?.message}</>}
                      name='token'
                      label={t(`${localization}.preProvisioningToken`)}
                      {...params}
                    />
                  )}
                  onOpen={() => {
                    refetch()
                  }}
                  {...register('token')}
                />
              )}
            />
            <TextField
              label={t(`${localization}.create.customerId`)}
              name='customer_id'
              {...register('customer_id')}
              error={!!errors.customer_id}
              helperText={<>{!!errors.customer_id && errors.customer_id?.message}</>}
            />
            <TextField
              label={t(`${localization}.create.domain`)}
              name='domain'
              {...register('domain')}
              error={!!errors.domain}
              helperText={<>{!!errors.domain && errors.domain?.message}</>}
            />
            <Stack direction='row-reverse'>
              <LoadingButton variant='contained' type='submit' loading={isLoading}>
                {t('button.create')}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </>
  )
}

export default RegisterGoogleDevice
