import { useQuery } from 'react-query'
import { adminGetSyncDeviceStatus, getHealthCheckStatuses, overviewGetAllEntities } from '~/api'
import overviewOrganisationApi from '~/api/overviewOrganisationApi'

export const useGetHealthCheckStatusesQuery = (tenantId, refetchInterval = 0) => {
  return useQuery(
    ['health_check_statuses', tenantId, refetchInterval],
    async () => {
      const { data } = await getHealthCheckStatuses(tenantId)
      return data
    },
    {
      refetchInterval
    }
  )
}

export const useSyncDeviceQuery = (tenantId, entityId = null) => {
  return useQuery(['sync-device', tenantId], async () => {
    const { data } = await adminGetSyncDeviceStatus(tenantId)
    return data
  })
}

export const useEntitiesQuery = () => {
  return useQuery(['company-entites'], async () => {
    const { data } = await overviewGetAllEntities()
    return data?.data
  })
}

export const useIntuneEnrollTriggerListQuery = (enabled = true) => {
  return useQuery(
    ['iet_list'],
    async () => {
      const { data } = await overviewOrganisationApi.intuneEnrollTriggerList(enabled)
      return data.data
    },
    {
      enabled
    }
  )
}
