import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useUpdateGoogleUserMutation } from '../mutation'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const schema = yup
  .object({
    pre_provisioning_token: yup.string().required(),
    contact_email: yup.string().required(),
    contact_person_name: yup.string().required(),
    mobile_number: yup.string().required(),
    customer_id: yup.string().nullable()
  })
  .required()

const propTypes = {
  data: PropTypes.object,
  registrationToken: PropTypes.string
}

const defaultProps = {
  data: null,
  registrationToken: null
}

const AdditionalForm = ({ data, registrationToken }) => {
  const { mutate, isLoading } = useUpdateGoogleUserMutation()
  const navigate = useNavigate()
  const onSubmit = async values => {
    mutate(
      { ...values, registration_token: registrationToken },
      {
        onSuccess: () => {
          enqueueSnackbar({
            message: 'Successfully Registered',
            variant: 'success'
          })

          navigate({ pathname: '/login' })
        }
      }
    )
  }

  const defaultValues = useMemo(() => {
    return {
      contact_email: data?.contact_email,
      contact_person_name: data?.contact_person_name,
      mobile_number: data?.mobile_number,
      pre_provisioning_token: data?.serviceTenant?.pre_provisioning_token
    }
  }, [data])

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  if (data == null) {
    return null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='caption'>
        Please input the pre provisioning token to allow Enroll manage devices on your tenant
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography>
          Device pre-provisioning token <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('pre_provisioning_token')}
          error={!!errors.pre_provisioning_token}
          helperText={<>{errors.pre_provisioning_token?.message}</>}
        />
        <Typography variant='caption'>
          NOTED please check this{' '}
          <a
            href='https://support.google.com/chrome/a/answer/10130175?hl=en#zippy=%2Cgenerate-a-pre-provisioning-token'
            target='_blank'
            rel='noreferrer'
          >
            link
          </a>{' '}
          for get pre-provisioning token
        </Typography>
      </Box>
      <Typography variant='caption'>Please fill out your contact details below</Typography>
      <Box sx={{ mb: 2 }}>
        <Typography>
          Contact person name <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('contact_person_name')}
          error={!!errors.contact_person_name}
          helperText={<>{errors.contact_person_name?.message}</>}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography>
          Mobile phone <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          {...register('mobile_number')}
          error={!!errors.mobile_number}
          helperText={<>{errors.mobile_number?.message}</>}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography>
          Google Admin Email <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          sx={{ width: '100%' }}
          {...register('contact_email')}
          error={!!errors.contact_email}
          helperText={<>{errors.contact_email?.message}</>}
        />
        <Typography variant='caption'>
          NOTE: The email will be used to login to easy4Enroll
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <LoadingButton
          variant='contained'
          type='submit'
          loading={isLoading}
          sx={{ mt: 1, mr: 1, textTransform: 'none', backgroundColor: '#76B72A' }}
        >
          Submit
        </LoadingButton>
      </Box>
    </form>
  )
}

AdditionalForm.propTypes = propTypes
AdditionalForm.defaultProps = defaultProps

export default AdditionalForm
