import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ProductionLogo from '~/assets/images/logo/easy4E.png'
import StagingLogo from '~/assets/images/logo/easy4E_staging.png'
import useLanguage from '~/hooks/useLanguage'
import AdditionalForm from './Steps/AdditionalForm'
import ThankYouPage from '../../components/ThankYouPage'
import LanguagePopover from '~/components/language/LanguagePopover'

const localization = 'pages.userRegister.'
const AzureTenantRegistration = () => {
  const enableRegistration = import.meta.env.VITE_ENABLE_REGISTRATION_FORM === 'true'
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { languages, language, setLanguage } = useLanguage()
  const [showThankYou, setShowThankYou] = useState(false)

  useEffect(() => {
    if (!enableRegistration) {
      navigate('/')
    }
  }, [enableRegistration, navigate])

  const registrationForm = () => (
    <>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.5em'
          }}
        >
          {t(`${localization}title`)}
        </Typography>
        <Box style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <LanguagePopover />
        </Box>
      </Box>

      <Typography sx={{ py: 2 }}>{t(`${localization}greeting`)}</Typography>
      <Typography sx={{ py: 2 }}>{t(`${localization}bodyContent1`)}</Typography>
      <Typography sx={{ py: 2 }}>{t(`${localization}bodyContent2`)}</Typography>
      <Typography sx={{ py: 2 }}>
        {t(`${localization}bodyContent3`)}
        <a href='mailto:itservicedesk@rentcompany.nl'>itservicedesk@rentcompany.nl</a>
        &nbsp;
        {t(`${localization}or`)}
        &nbsp;
        <a href='mailto:itservicedesk@rentcompany.be'>itservicedesk@rentcompany.be</a>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <AdditionalForm
          language={language}
          setLanguage={setLanguage}
          languages={languages}
          setSubmitted={setShowThankYou}
        />
      </Box>
    </>
  )

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        bgcolor: '#f0f0f0',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mb: 2,
          mt: 2
        }}
      >
        <Box sx={{ mb: 2, mt: 2 }}>
          {import.meta.env.VITE_ENVIRONMENT === 'development' ? (
            <img src={StagingLogo} width={150} alt='' />
          ) : (
            <img src={ProductionLogo} width={150} alt='' />
          )}
        </Box>

        <Box
          sx={{
            gap: 8,
            bgcolor: '#fff',
            borderRadius: 8,
            height: 'fit-content',
            px: 4,
            py: 4,
            width: 550
          }}
        >
          {showThankYou ? <ThankYouPage /> : registrationForm()}
        </Box>
      </Box>
    </Box>
  )
}

export default AzureTenantRegistration
