export const OrgOverviewHeadCells = [
  {
    id: 'school',
    numeric: false,
    disablePadding: false,
    label: 'school'
  },
  {
    id: 'external-id',
    numeric: false,
    disablePadding: false,
    label: 'externalId'
  },
  {
    id: 'devices',
    numeric: false,
    disablePadding: false,
    label: 'devices'
  }
]
