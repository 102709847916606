import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import { showErrorMessage } from '~/utils/helpers'
import { useRegistrationOptInMutation } from '../mutation'

const RegisterApplication = ({ registrationToken }) => {
  const registrationOptInMutation = useRegistrationOptInMutation()
  const queryClient = useQueryClient()
  const doRegisterApplication = useCallback(() => {
    registrationOptInMutation.mutate(registrationToken, {
      onSuccess: () => {
        queryClient.invalidateQueries('registration_token')
      },
      onError: showErrorMessage
    })
  }, [queryClient, registrationOptInMutation, registrationToken])

  return (
    <>
      <Typography>Click the button below to register your application.</Typography>
      <Box sx={{ mb: 2 }}>
        <div>
          <LoadingButton
            loading={registrationOptInMutation.isLoading}
            onClick={doRegisterApplication}
            sx={{ mt: 1, mr: 1 }}
            variant='contained'
          >
            Register Application
          </LoadingButton>
        </div>
      </Box>
    </>
  )
}

RegisterApplication.propTypes = {
  registrationToken: PropTypes.string
}

export default RegisterApplication
