import { Check, Error } from '@mui/icons-material'
import { Box, Checkbox, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const localization = 'pages.device.'

const AdditionalUploadPopup = ({ result, checked, setChecked }) => {
  const { t } = useTranslation()

  if (!result) return null
  return (
    <Grid container spacing={3} mt={1} mb={4}>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' gap={1}>
          <Check sx={{ color: '#76B72A' }} />
          <Typography color='primary'>
            {t(`${localization}totalRecognizedSerials`)} {result.total_recognized.toLocaleString()}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' alignItems='center' gap={1}>
          <Error sx={{ color: 'red' }} />
          <Typography color={'red'}>
            {t(`${localization}totalNotManaged`)} {result.total_not_managed.toLocaleString()}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' alignItems='center' gap={1}>
          <Error sx={{ color: 'orange' }} />
          <Typography>
            {t(`${localization}totalNotRecognized`)} {result.total_not_recognized.toLocaleString()}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' alignItems='center' gap={1}>
          <Checkbox
            color='primary'
            checked={checked}
            onClick={() => setChecked(!checked)}
            sx={{
              padding: 0
            }}
          />
          <Typography>{t(`${localization}deleteAllNotManagedi`)} </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

AdditionalUploadPopup.propTypes = {
  result: PropTypes.object,
  checked: PropTypes.bool,
  setChecked: PropTypes.func
}

export default AdditionalUploadPopup
