import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
  name: 'adminAuth',
  initialState: {
    isAdminAuthenticating: false,
    admin: null,
    adminPermissions: []
  },
  reducers: {
    setAdmin: (state, action) => {
      state.admin = action.payload
    },
    setIsAdminAuthenticating: (state, action) => {
      state.isAdminAuthenticating = action.payload
    },
    setAdminPermissions: (state, action) => {
      state.adminPermissions = action.payload
    }
  }
})

export const { setAdmin, setIsAdminAuthenticating, setAdminPermissions } = adminSlice.actions

export default adminSlice
