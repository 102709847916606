import { Checkbox, Chip, Link as MuiLink, TableBody, TableCell, TableRow } from '@mui/material'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import { AzureDeviceHeadCells } from './AzureDeviceHeadCells'
import DeviceActionButton from './DeviceActionButton'
import { useUserPermission } from '~/hooks/useUserPermission'

const DeviceTableBody = ({
  isLoading,
  devices,
  onDeregisClick,
  onRepairClick,
  onIsRepairClick,
  selected,
  setSelected
}) => {
  const { userAbility } = useUserPermission()
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = [...selected]

    if (selectedIndex === -1) {
      newSelected.push(id)
    } else {
      newSelected = newSelected.filter(item => item !== id)
    }

    setSelected(newSelected)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  if (isLoading) {
    return <TableLoading colSpan={AzureDeviceHeadCells.length} />
  }

  return (
    <TableBody>
      {devices.length === 0 && <DataNotFound colSpan={AzureDeviceHeadCells.length} />}
      {devices.length > 0 &&
        devices.map((row, index) => {
          const isItemSelected = isSelected(row.id)
          const labelId = `device-table-checkbox-${index}`
          let chipcolor = 'info'
          if (row.import_status === 'complete') {
            chipcolor = 'success'
          } else if (row.import_status === 'unknown') {
            chipcolor = 'default'
          } else {
            chipcolor = 'error'
          }

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={index}
              aria-checked={isItemSelected}
              selected={isItemSelected}
            >
              <TableCell padding='checkbox'>
                {row.trc_managed && (
                  <Checkbox
                    color='primary'
                    checked={isItemSelected}
                    disabled={row?.state === 'deleting' || !userAbility.devices.canBulkDeregister}
                    onClick={event => handleClick(event, row.id)}
                    inputProps={{
                      'aria-labelledby': labelId
                    }}
                  />
                )}
              </TableCell>
              <TableCell align='left'>
                {userAbility.devices.canViewDetail ? (
                  <Link to={'/azure/devices/' + row.id}>
                    <MuiLink>{row.serial}</MuiLink>
                  </Link>
                ) : (
                  row.serial
                )}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.aad_device_id}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.manufacture}
              </TableCell>
              <TableCell>{row.model}</TableCell>
              <TableCell align='left'>{row.group_tag}</TableCell>
              <TableCell align='center'>{row.os_system}</TableCell>
              <TableCell align='center'>{row.os_version}</TableCell>
              <TableCell align='center'>
                <Chip label={row.import_status} color={chipcolor} size='small' />
              </TableCell>
              <TableCell align='center'>
                {row.state && <Chip label={row.state} color='default' size='small' />}
              </TableCell>
              <TableCell align='center'>{row.import_error_name}</TableCell>
              <TableCell align='center'>
                {row.trc_managed && (
                  <DeviceActionButton
                    row={row}
                    onDeregisClick={onDeregisClick}
                    onRepairClick={onRepairClick}
                    onIsRepairClick={onIsRepairClick}
                  />
                )}
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

DeviceTableBody.propTypes = {
  isLoading: PropTypes.bool,
  devices: PropTypes.array,
  onDeregisClick: PropTypes.func.isRequired,
  onRepairClick: PropTypes.func.isRequired,
  onIsRepairClick: PropTypes.func.isRequired,
  selected: PropTypes.array,
  setSelected: PropTypes.func
}

DeviceTableBody.defaultProps = {
  isLoading: false,
  devices: [],
  selected: []
}

export default DeviceTableBody
