import PropTypes from 'prop-types'
import { useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import FullPageLoading from '~/components/FullPageLoading'
import Navigator from '~/components/Navigator'
import { KEY_ADMIN_AUTH_TOKEN, KEY_AUTH_TOKEN, REGISTRATION_STATE } from '~/constants/constants'
import AdminLogin from '~/pages/Admin/login'
import Login from '~/pages/Login'
import Registration from '~/pages/Registration'
import { useAdminProfileQuery, useProfileQuery } from './query'
import GoogleRegistration from '~/pages/GoogleRegistration'
import {
  AZURE_TENANT_REGISTRATION,
  GOOGLE_LOGIN_REDIRECT,
  GOOGLE_REGISTRATION,
  GOOGLE_TENANT_REGISTRATION
} from '~/constants/Routes'
import GoogleLoginRedirect from '~/pages/Login/GoogleLoginRedirect'
import { useSelector } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import AzureTenantRegistration from '~/pages/AzureTenantRegistration'
import GoogleTenantRegistration from '~/pages/GoogleTenantRegistration'

const MasterLayout = ({ isAuthenticated, isAdminAuthenticated }) => {
  const search = useLocation()
  const isAdminRoute = search.pathname.startsWith('/overview')
  const isUserRoute = !isAdminRoute

  const [cachedAuthToken] = useState(localStorage.getItem(KEY_AUTH_TOKEN))
  const [cachedAdminAuthToken] = useState(localStorage.getItem(KEY_ADMIN_AUTH_TOKEN))
  const { isLoading: isLoadingProfile } = useProfileQuery(!!cachedAuthToken && isUserRoute)
  const { isLoading: isAdminLoadingProfile } = useAdminProfileQuery(
    !!cachedAdminAuthToken && isAdminRoute
  )

  const state = new URLSearchParams(search.search).get('state')
  const error = new URLSearchParams(search.search).get('error')
  const user = useSelector(selectUser)
  if (state && !error) {
    user === null
      ? localStorage.setItem(REGISTRATION_STATE, state)
      : localStorage.removeItem(REGISTRATION_STATE)
  }

  if (isAdminLoadingProfile || isLoadingProfile) {
    return <FullPageLoading />
  }

  // ADMIN AUTH
  if (!isAdminAuthenticated && isAdminRoute) {
    return (
      <>
        <Routes>
          <Route path='/overview/login' element={<AdminLogin />} />
          <Route path='/overview/*' element={<Navigate to='/overview/login' />} />
        </Routes>
      </>
    )
  }

  // USER AUTH
  if (!isAuthenticated && isUserRoute) {
    return (
      <>
        <Routes>
          <Route path={GOOGLE_LOGIN_REDIRECT} element={<GoogleLoginRedirect />} />
          <Route path={GOOGLE_REGISTRATION} element={<GoogleRegistration />} />
          <Route path={AZURE_TENANT_REGISTRATION} element={<AzureTenantRegistration />} />
          <Route path={GOOGLE_TENANT_REGISTRATION} element={<GoogleTenantRegistration />} />
          <Route path='/registration' element={<Registration />} />
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
      </>
    )
  }

  return <Navigator />
}

MasterLayout.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAdminAuthenticated: PropTypes.bool
}

MasterLayout.defaultProps = {
  isAuthenticated: false,
  isAdminAuthenticated: false
}

export default MasterLayout
