import { Box, Breadcrumbs, Divider, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import FullPageLoading from '~/components/FullPageLoading'
import { ADMIN_DEVICE_REGISTER_SESSIONS } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { selectSnackbar } from '~/store/snackbar/selector'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { useRegisterSessionDetailQuery } from './query'
import { isValidDateTimeString } from '~/utils/helpers'
import DateTimeLocale from '~/components/DateTimeLocale'

const DeviceRegisterSessionDetail = () => {
  const { t } = useTranslation()
  const { sessionId } = useParams()

  const { data, isLoading } = useRegisterSessionDetailQuery(sessionId)

  const snackbarData = useSelector(selectSnackbar)
  const { showSnackbar, snackbar } = useSnackbar()
  const dispatch = useDispatch()
  useEffect(() => {
    if (snackbarData) {
      showSnackbar(snackbarData.message, snackbarData.type)
    }
    return () => {
      dispatch(unsetSnackbar({}))
    }
  })

  const localization = 'pages.device.detail'

  if (isLoading) {
    return <FullPageLoading />
  }

  return (
    <>
      {snackbar}
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to={ADMIN_DEVICE_REGISTER_SESSIONS}>
            {t('general.registerSessionOverview')}
          </NavLink>
        </Typography>
        <Typography>{data?.data?.serial}</Typography>
      </Breadcrumbs>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant='h6'>{t(`${localization}.deviceSessionInformation`)}</Typography>
      {Object.keys(data.data).map(key => {
        const value = data.data[key]
        if (key === 'hash') {
          return (
            <Box sx={{ wordBreak: 'break-word' }} key={key}>
              hash: {`${value}`}
            </Box>
          )
        }

        if (isValidDateTimeString(value)) {
          return (
            <div key={key}>
              {key} : <DateTimeLocale datetime={value} />
            </div>
          )
        }

        return (
          <div key={key}>
            {key} : {`${value}`}
          </div>
        )
      })}
    </>
  )
}
export default DeviceRegisterSessionDetail
