import styled from '@emotion/styled'
import { AppBar, Avatar, Box, Stack, Toolbar, Typography } from '@mui/material'
import { Container } from '@mui/system'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import LanguagePopover from '../../../components/language/LanguagePopover'
import LoginPopup from '../LoginPopup'

const LoginHeader = () => {
  const { t } = useTranslation()
  const [openPopup, setOpenPopup] = React.useState(false)

  const AvatarUser = styled(Avatar)(() => ({
    color: '#76B82A',
    backgroundColor: '#ffffff',
    transition: '0.5s',
    cursor: 'pointer',
    '&:hover': {
      color: '#102F44'
    }
  }))

  const toggleLoginPopup = () => {
    setOpenPopup(!openPopup)
  }

  return (
    <AppBar position='static' style={{ background: '#102F44', height: '90px' }}>
      <Container sx={{ marginTop: '20px' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                width: '170px',
                height: '65px'
              }}
            />
          </Box>
          <Box sx={{ paddingBottom: '25px', marginRight: '25px' }}>
            <LanguagePopover />
          </Box>

          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            onClick={toggleLoginPopup}
            minWidth='46px'
          >
            <AvatarUser src='/broken-image.jpg' />
            <Typography variant='caption' display='block' gutterBottom>
              {t('login.loginButton')}
            </Typography>
            <LoginPopup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default LoginHeader
