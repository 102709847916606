import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import { getDisabledSuperUsers, overviewSuperUsers } from '~/api'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useSuperUsersQuery = params => {
  return useQuery(
    ['super_users_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await overviewSuperUsers(p)
      return { data: data?.data, total: data?.meta?.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useDisableUsersQuery = params => {
  return useQuery(
    ['disable_super_users_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await getDisabledSuperUsers(p)
      return { data: data?.data, total: data?.meta?.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}
