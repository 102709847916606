export const TokenHeadCells = [
  {
    id: 'school',
    numeric: false,
    disablePadding: false,
    label: 'school',
    orderable: false
  },
  {
    id: 'preprovisioning_token',
    numeric: false,
    disablePadding: false,
    label: 'preprovisioningToken'
  }
]
