import { LoadingButton } from '@mui/lab'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import MicrosoftLogoImage from '~/assets/images/microsoft.png'
import { useAuthenticateWithMicrosoftMutation } from '../mutation'

const useStyle = makeStyles()(theme => ({
  button: {
    width: '100%',
    marginTop: '10px',
    justifyContent: 'left',
    textTransform: 'none',
    backgroundColor: '#76B72A',
    '&:hover': {
      backgroundColor: '#102F44',
      color: 'white'
    }
  }
}))

const MicrosoftLoginButton = ({ ...props }) => {
  const { t } = useTranslation()
  const { classes } = useStyle()

  const { login, isLoading } = useAuthenticateWithMicrosoftMutation()
  const msLogin = useCallback(async () => {
    await login()
  }, [login])

  return (
    <LoadingButton
      variant='contained'
      className={classes.button}
      isLoading={isLoading}
      onClick={msLogin}
      {...props}
    >
      <img
        src={MicrosoftLogoImage}
        alt=''
        style={{
          marginRight: '0.5rem',
          padding: '2px',
          background: '#fff'
        }}
      />
      {t('button.SignInWithMicrosoft')}
    </LoadingButton>
  )
}

export default MicrosoftLoginButton
