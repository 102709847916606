import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import { parseApiErrorMessage } from '~/utils/helpers'

// Custom query to not retry if 403 error
export const useCustomQuery = (queryKey, queryFn, options = {}) => {
  return useQuery(queryKey, queryFn, {
    ...options,
    onError: error => {
      if (error.response?.status === 403) {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
        options.retry = false
      } else if (options.onError) {
        options.onError(error)
      }
    }
  })
}
