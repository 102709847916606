import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ToolBarButton from '~/components/Button/ToolBarButton'
import ToggleButton from './Button/ToggleButton'
import ListToolbarWrapper from './ListToolbarWrapper'
import PropTypes from 'prop-types'

const ListToolbar = ({ toolBarButton }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ListToolbarWrapper>
      {toolBarButton &&
        toolBarButton.map(
          (item, index) =>
            item.isPermission &&
            (item.isToggle ? (
              <ToggleButton
                content={item.icon}
                value='check'
                selected={item.selected}
                onChange={e => item.onClick(e)}
              />
            ) : (
              <ToolBarButton
                key={index}
                icon={item.icon}
                handleAction={e => {
                  if (item.navigateLink) navigate(`${item.navigateLink}`)
                  else {
                    item.onClick(e)
                  }
                }}
                name={t(`button.${item.button}`)}
                disabled={item.disabled}
                loading={item.loading}
              />
            ))
        )}
    </ListToolbarWrapper>
  )
}

ListToolbar.propTypes = {
  toolBarButton: PropTypes.array
}

export default ListToolbar
