import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import organisationApi from '~/api/organisationApi'
import overviewOrganisationApi from '~/api/overviewOrganisationApi'
import { isOverviewPage } from '~/utils/helpers'

export const useRemoveOrganisationFromTenantMutation = organisationId => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const api = isOverviewPage ? overviewOrganisationApi : organisationApi
      const { data } = await api.removeOrganisationFromTenantApi(organisationId)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully remove organisation from tenant',
          variant: 'success'
        })
        queryClient.invalidateQueries(['organisation_detail', data.id])
        if (isOverviewPage) {
          queryClient.invalidateQueries('tenant_details')
        } else {
          queryClient.invalidateQueries(['organisation_overview'])
        }
      },
      retry: false
    }
  )
}

export const useUpdateProvisionTokenMutation = organisationId => {
  const queryClient = useQueryClient()
  return useMutation(
    async token => {
      const api = isOverviewPage ? overviewOrganisationApi : organisationApi
      const { data } = await api.updatePreprovisionTokenApi(organisationId, token)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully updated token',
          variant: 'success'
        })

        queryClient.invalidateQueries(['organisation_detail', data.id])
        if (isOverviewPage) {
          queryClient.invalidateQueries('tenant_details')
        } else {
          queryClient.invalidateQueries(['organisation_overview'])
        }
      },
      retry: false
    }
  )
}
