// material
import { Box, TableCell, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

// ----------------------------------------------------------------------

const DataNotFound = ({ colSpan }) => {
  const { t } = useTranslation()
  return (
    <TableRow>
      <TableCell align='center' colSpan={colSpan} sx={{ py: 3 }}>
        <Box>
          <Typography gutterBottom align='center' variant='subtitle1'>
            {t('message.noData')}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  )
}

DataNotFound.propTypes = {
  colSpan: PropTypes.number.isRequired
}

export default DataNotFound
