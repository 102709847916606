import { enqueueSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { updateAppointmentLinkSettings } from '~/api/admin'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useUpdateAppointmentLinkSettingsMutation = () => {
  return useMutation(
    async payload => {
      const { data } = await updateAppointmentLinkSettings(payload)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.message || 'Appointment link settings has been updated',
          variant: 'success'
        })
      },
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message: message || 'Failed to update appointment link settings',
          variant: 'error'
        })
      }
    }
  )
}
