import { useQuery } from 'react-query'
import axiosClient from '~/api/base'
import { showErrorMessage } from '~/utils/helpers'

export const useRegistrationInfoQuery = registrationToken => {
  return useQuery(
    ['registration_token', registrationToken],
    async ({ signal }) => {
      const { data } = await axiosClient.get('api/registration-info?token=' + registrationToken, {
        signal
      })
      return data.data
    },
    {
      onError: showErrorMessage,
      refetchOnWindowFocus: false,
      retry: false
    }
  )
}

export const useAppointmentLinkQuery = () => {
  return useQuery(
    ['registration_appointment_link'],
    async () => {
      return await axiosClient.get('api/configs/appointment-link')
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  )
}
