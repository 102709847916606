import MuiLoadingButton from '@mui/lab/LoadingButton'
import PropTypes from 'prop-types'

export const LoadingButton = ({ children, ...props }) => {
  return (
    <MuiLoadingButton
      variant='contained'
      size='small'
      {...props}
      sx={{
        textTransform: 'none',
        backgroundColor: '#76B72A',
        fontSize: 'smaller',
        '& .MuiButton-startIcon': { margin: '0px' },
        minWidth: 0
      }}
    >
      {children}
    </MuiLoadingButton>
  )
}

LoadingButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  type: PropTypes.string
}

LoadingButton.defaultProps = {
  disabled: false,
  loading: false,
  type: 'button'
}
