import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { updateActiveTenantApi } from '~/api'
import { DASHBOARD } from '~/constants/Routes'

export const useUpdateActiveTenantMutation = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async id => {
      const { data } = await updateActiveTenantApi(id)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully updated active tenant',
          variant: 'success',
          autoHideDuration: 2000
        })
        queryClient.invalidateQueries('profile')
        navigate(DASHBOARD)
      },
      onError: error => {
        enqueueSnackbar({
          message: error.response?.data?.message || 'Failed to update active tenant',
          variant: 'error',
          autoHideDuration: 2000
        })
      },
      retry: false
    }
  )
}
