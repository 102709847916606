import { ADMIN_USER_ROLE } from '~/constants/Routes'
import { useCreateMutation } from './mutate'
import { useGetAllGuardsQuery, useGetAllPermissionsQuery } from './query'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import BreadCrumb from '~/components/BreadCrumb'
import Toolbar from './Toolbar'

const breadCrumbConfig = [
  { title: 'Overview', route: ADMIN_USER_ROLE },
  {
    title: 'Roles',
    route: ADMIN_USER_ROLE
  },
  {
    title: 'Role Create',
    page: 'detail'
  }
]

const schema = yup
  .object({
    guard_name: yup.string().required(),
    name: yup.string().required(),
    permissions: yup.array().of(yup.string().nullable())
  })
  .required()

const RoleCreate = () => {
  const localization = 'pages.overview.role'
  const { t } = useTranslation()
  const { mutate: createRole } = useCreateMutation({})
  const [guardName, setGuardName] = useState(null)
  const { data: permissions } = useGetAllPermissionsQuery(guardName)

  const defaultValues = useMemo(
    () => ({
      name: '',
      guard_name: guardName,
      permissions: permissions?.map(permission => ({ name: permission.name, value: false })) || []
    }),
    [guardName, permissions]
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSubmit = async values => {
    createRole(values)
  }

  const { data: guards } = useGetAllGuardsQuery()
  useEffect(() => {
    if (guards) setGuardName(guards[0])
  }, [guards])

  // to reset the permission checked checkboxes when list permissions changed
  useEffect(() => {
    if (permissions) {
      reset({ permissions: permissions.map(() => null) })
      setValue('permissions', [])
    }
  }, [permissions, reset, setValue])

  return (
    <>
      <BreadCrumb breadcrumbs={breadCrumbConfig} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <Toolbar localization={localization} r></Toolbar>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t(`${localization}.guard_name`)} <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              name='guard_name'
              control={control}
              render={({ field: { ref, onChange, value, ...field }, fieldState: { error } }) => {
                if (guardName && !value) {
                  onChange(guardName)
                }

                return (
                  <Autocomplete
                    {...field}
                    options={guards}
                    value={guardName}
                    onChange={(e, newValue) => {
                      onChange(newValue)
                      setGuardName(newValue)
                    }}
                    renderInput={params => (
                      <TextField
                        inputRef={ref}
                        error={!!errors.guard_name}
                        helperText={!!errors.guard_name && errors.guard_name?.message}
                        name='guard_name'
                        label={t(`${localization}.guard_name`)}
                        {...params}
                      />
                    )}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>
              Name <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant='outlined'
              {...register('name')}
              error={!!errors.name}
              helperText={<>{errors.name?.message}</>}
            />
          </Grid>

          <Grid container spacing={2}>
            {permissions?.map((field, index) => (
              <Grid item xs={12} md={6} key={field.id}>
                <Controller
                  name={`permissions[${index}]`}
                  control={control}
                  render={({ field: controlField }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...controlField}
                            checked={!!controlField.value}
                            onChange={e => {
                              if (e.target.checked) {
                                controlField.onChange(field.name)
                              } else {
                                controlField.onChange(undefined)
                              }
                            }}
                          />
                        }
                        label={field.name}
                      />
                    </>
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </form>
    </>
  )
}
export default RoleCreate
