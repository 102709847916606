import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { adminAddGoogleTenant, overviewRemoveInactiveTenant } from '~/api'
import { parseApiErrorMessage, showErrorMessage } from '~/utils/helpers'

export const useOverviewRemoveInactiveTenantMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ['overview_remove_inactive_tenant'],
    async payload => {
      return await overviewRemoveInactiveTenant(payload)
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully remove tenant',
          variant: 'success'
        })
        queryClient.invalidateQueries('tenant_overview')
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useAddGoogleTenantMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ['overview_google_domain_registration'],
    async payload => {
      return await adminAddGoogleTenant(payload)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tenant_overview')
        enqueueSnackbar({
          message: 'Succesfully added',
          variant: 'success'
        })
      },
      onError: showErrorMessage
    }
  )
}
