import { Box, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ProductionLogo from '~/assets/images/logo/easy4E.png'
import StagingLogo from '~/assets/images/logo/easy4E_staging.png'
import FullPageLoading from '~/components/FullPageLoading'
import AdditionalForm from './Steps/AdditionalForm'
import GrantAppPermissions from './Steps/GrantAppPermissions'
import RegisterApplication from './Steps/RegisterApplication'
import { useAppointmentLinkQuery, useRegistrationInfoQuery } from './query'
import { enqueueSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'

const Registration = () => {
  const search = useLocation()
  const registrationToken = new URLSearchParams(search.search).get('state')
  const navigate = useNavigate()
  const { data, isFetching } = useRegistrationInfoQuery(registrationToken)
  const { data: appointmentLink } = useAppointmentLinkQuery()
  const [completed, setCompleted] = useState(false)

  const appointmentLinkEnabled = useMemo(() => {
    return appointmentLink?.data?.enabled ?? false
  }, [appointmentLink])

  useEffect(() => {
    if (data?.registered_at != null && !appointmentLinkEnabled) {
      navigate({ pathname: '/login' })
      enqueueSnackbar({
        message: 'The tenant has been registered, please use your microsoft admin account to login',
        variant: 'success'
      })
    }
  }, [appointmentLinkEnabled, data, navigate])

  const activeStep = useMemo(() => {
    // move to grant permission step
    if (data?.serviceTenant?.tenant_id == null) {
      return 0
    }

    // move to additional form step
    if (data?.serviceTenant?.is_granted_permission === false) {
      return 1
    }

    if (!appointmentLinkEnabled) {
      return 2
    }

    return data?.registered_at != null ? 3 : 2
  }, [appointmentLinkEnabled, data])

  if (!data || isFetching) {
    return <FullPageLoading />
  }

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        bgcolor: '#f0f0f0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <div style={{ marginBottom: 20 }}>
        {import.meta.env.VITE_ENVIRONMENT === 'development' ? (
          <img src={StagingLogo} width={150} alt='' />
        ) : (
          <img src={ProductionLogo} width={150} alt='' />
        )}
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
          bgcolor: '#fff',
          borderRadius: 8,
          height: 'fit-content',
          px: 4,
          py: 4
        }}
      >
        <Typography>Welcome to easy4Enroll</Typography>
        <Box sx={{ width: 550 }}>
          <Stepper activeStep={activeStep} orientation='vertical'>
            <Step>
              <StepLabel>Register application</StepLabel>
              <StepContent>
                <RegisterApplication registrationToken={registrationToken} />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Grant app permisions</StepLabel>
              <StepContent>
                <GrantAppPermissions
                  registrationToken={registrationToken}
                  msTenantId={data.serviceTenant.tenant_id}
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Please fill out your information in this form</StepLabel>
              <StepContent>
                <Typography variant='caption'>
                  Please fill out your contact details below
                </Typography>
                <AdditionalForm
                  data={data}
                  registrationToken={registrationToken}
                  haveStep4={appointmentLinkEnabled}
                />
              </StepContent>
            </Step>
            {appointmentLinkEnabled && (
              <Step>
                <StepLabel>
                  Please make an onboarding appointment with one of our specialists
                </StepLabel>
                <StepContent>
                  <Box>
                    <LoadingButton
                      variant='contained'
                      onClick={() => {
                        setCompleted(true)
                        window.open(appointmentLink.data.url, '_blank')
                      }}
                      sx={{ mt: 1, mr: 1, textTransform: 'none', backgroundColor: '#76B72A' }}
                    >
                      Make Appointment
                    </LoadingButton>
                    {completed && (
                      <LoadingButton
                        variant='contained'
                        onClick={() => navigate('/login')}
                        sx={{ mt: 1, mr: 1, textTransform: 'none', backgroundColor: '#76B72A' }}
                      >
                        Finished
                      </LoadingButton>
                    )}
                  </Box>
                </StepContent>
              </Step>
            )}
          </Stepper>
        </Box>
      </Box>
    </Box>
  )
}

export default Registration
