import { useQuery } from 'react-query'
import {
  overviewGetListOrganisations,
  overviewGetListRegisterDeviceSessions,
  overviewGetRegisterDeviceSessionDetail
} from '~/api'
import { removeEmptyValue } from '~/utils/helpers'

export const useRegisterSessionQuery = params => {
  return useQuery(['overview_register_sessions', params], async () => {
    const p = removeEmptyValue(params)
    const { data } = await overviewGetListRegisterDeviceSessions(p)
    return { data: data.data, total: data.meta.total }
  })
}

export const useRegisterSessionDetailQuery = id => {
  return useQuery(['register_session_detail', id], async () => {
    const { data } = await overviewGetRegisterDeviceSessionDetail(id)
    return data
  })
}

export const useOrganisationListQuery = () => {
  return useQuery(['organisations'], async () => {
    const { data } = await overviewGetListOrganisations()
    return data
  })
}
