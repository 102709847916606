import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import { Stack } from '@mui/system'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LoadingButton } from '~/components/Button/LoadingButton'
import DataNotFound from '~/components/DataNotFound'
import ListToolbar from '~/components/ListToolbar'
import CustomModal from '~/components/Modal/CustomModal'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { GOOGLE_DEVICE_NEW } from '~/constants/Routes'
import {
  useBulkDeleteGoogleDeviceMutation,
  useDeregisterGoogleDeviceMutation,
  useGoogleDeviceListOverviewQuery
} from '../query'
import { GooleDeviceHeadCells } from './GooleDeviceHeadCells'
import { useUserPermission } from '~/hooks/useUserPermission'
import DateTimeLocale from '~/components/DateTimeLocale'

const localization = 'pages.device.'

const DeviceTableBody = ({
  isLoading,
  devices,
  onDeregisClick,
  isLoadingForBtn,
  selected,
  setSelected
}) => {
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  if (isLoading) {
    return <TableLoading colSpan={GooleDeviceHeadCells.length} />
  }

  return (
    <TableBody>
      {devices.length === 0 && <DataNotFound colSpan={GooleDeviceHeadCells.length} />}
      {devices.length > 0 &&
        devices.map((row, index) => {
          const isItemSelected = isSelected(row.id)
          return (
            <TableRow aria-checked={isItemSelected} hover key={index}>
              <TableCell padding='checkbox'>
                <Checkbox
                  color='primary'
                  checked={isItemSelected}
                  onClick={event => handleClick(event, row.id)}
                  inputProps={{
                    'aria-labelledby': `device-table-checkbox-${index}`
                  }}
                />
              </TableCell>
              <TableCell align='left'>
                <Link
                  style={{ color: 'rgb(118, 183, 42)' }}
                  to={'/google/devices/' + row.id}
                  component={MuiLink}
                >
                  {row.serial}
                </Link>
              </TableCell>
              <TableCell>{row.pre_provisioned_device_id}</TableCell>
              <TableCell>{row.brand_code}</TableCell>
              <TableCell align='center'>
                {row.state && <Chip label={row.state} color='default' size='small' />}
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row.google_device_created} />
              </TableCell>
              <TableCell align='center'>
                <LoadingButton loading={isLoadingForBtn} onClick={e => onDeregisClick(e, row.id)}>
                  Deregister
                </LoadingButton>
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

DeviceTableBody.propTypes = {
  devices: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoadingForBtn: PropTypes.bool,
  onDeregisClick: PropTypes.func,
  selected: PropTypes.array,
  setSelected: PropTypes.func.isRequired
}

DeviceTableBody.defaultProps = {
  devices: [],
  isLoading: false,
  isLoadingForBtn: false,
  selected: []
}

const GoogleDeviceTable = () => {
  const { t } = useTranslation()
  const { userAbility } = useUserPermission()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [page, setPage] = useState(1)

  const [selected, setSelected] = useState([])

  const [open, setOpen] = useState(false)
  const [deviceToDeregist, setDeviceToDeregist] = useState(null)

  const {
    data,
    isLoading,
    refetch: refetchDeviceList
  } = useGoogleDeviceListOverviewQuery({
    page,
    search,
    order,
    orderBy
  })

  const devices = useMemo(() => {
    return data ? data.data : []
  }, [data])

  const { isLoading: isDeletingADevice, mutateAsync: deregistDevice } =
    useDeregisterGoogleDeviceMutation()

  const { mutateAsync: bulkdDeregistDevices, isLoading: isBulkDeletingDevices } =
    useBulkDeleteGoogleDeviceMutation()

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const searchHandler = useCallback(event => {
    if (event.key === 'Enter') {
      setSearchValue(event.target.value)
      setPage(1)
    }
  }, [])

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  const deregistADivice = useCallback((_, value) => {
    setOpen(true)
    setDeviceToDeregist(value)
  }, [])

  const toolBarButtons = useMemo(() => {
    return [
      {
        button: 'bulkDeregisBtn',
        icon: <DeleteIcon />,
        isPermission: true,
        disabled: _.isEmpty(selected) || userAbility.devices.canBulkDeregister,
        loading: isBulkDeletingDevices,
        onClick: () => {
          setOpen(true)
          setDeviceToDeregist(selected)
        }
      },
      {
        button: 'create',
        navigateLink: GOOGLE_DEVICE_NEW,
        icon: <AddIcon />,
        isPermission: true,
        disabled: !userAbility.devices.canRegister
      }
    ]
  }, [isBulkDeletingDevices, selected, userAbility])

  const handleCloseModal = () => {
    setOpen(false)
    setDeviceToDeregist(null)
  }

  const handleSubmitModal = async () => {
    if (deviceToDeregist === null) {
      setOpen(false)
      return
    }

    if (_.isArray(deviceToDeregist)) {
      await bulkdDeregistDevices(selected)
    } else {
      await deregistDevice(deviceToDeregist)
    }

    await refetchDeviceList()
    setSelected([])
    setDeviceToDeregist(null)
    setOpen(false)
  }

  return (
    <>
      <ListToolbar toolBarButton={toolBarButtons} />
      <Paper elevation={1}>
        <Toolbar>
          <Grid container spacing={0.5}>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item xs={12} sm={3}>
              <SearchToolBar
                filterSearch={search}
                onFilterSearch={searchHandler}
                placeholder='serials_or_preProvisioningToken'
              />
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader
              headCells={GooleDeviceHeadCells}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <DeviceTableBody
              isLoading={isLoading}
              devices={devices}
              onDeregisClick={deregistADivice}
              isLoadingForBtn={isDeletingADevice}
              setSelected={setSelected}
              selected={selected}
            />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={handleChangePage}
        />
      </Paper>
      <CustomModal open={open} onClose={handleCloseModal}>
        <Typography variant='h6'>
          {Array.isArray(deviceToDeregist)
            ? t('pages.device.deleteDevices')
            : `${t('pages.device.deleteDevice')} ${deviceToDeregist}?`}
        </Typography>
        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton onClick={handleCloseModal}>{t('pages.organisation.cancel')}</LoadingButton>
          <LoadingButton
            onClick={handleSubmitModal}
            loading={isDeletingADevice || isBulkDeletingDevices}
          >
            {t('pages.organisation.confirm')}
          </LoadingButton>
        </Stack>
      </CustomModal>
    </>
  )
}
export default GoogleDeviceTable
