import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import { getAzureTenantsWithoutPaging } from '~/api'
import overviewUserApi from '~/api/overviewUserApi'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useUsersQuery = params => {
  return useQuery(
    ['users_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await overviewUserApi.overviewUsers(p)
      return { data: data?.data, total: data?.meta?.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useDisableUsersQuery = params => {
  return useQuery(
    ['disable_users_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await overviewUserApi.getDisabledUsers(p)
      return { data: data?.data, total: data?.meta?.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useActiveTenantsQuery = () => {
  return useQuery(
    ['active_tenants_overview'],
    async () => {
      const { data } = await getAzureTenantsWithoutPaging()
      return data?.data
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
