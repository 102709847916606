import {
  AZURE_DEVICES,
  DASHBOARD,
  GROUPTAGS,
  HEALTH_CHECK,
  ORGANISATION,
  ORG_OVERVIEW
} from '~/constants/Routes'
import {
  DASHBOARD_VIEW,
  DEVICES_VIEW,
  GROUP_TAG_VIEW,
  HEALTHCHECK_VIEW,
  ORGANISATIONS_OVERVIEW,
  ORGANISATIONS_VIEW
} from '~/constants/userPermissions'

export const AZURE_MENU_ITEMS = [
  {
    title: 'userMenu.dashboard',
    url: DASHBOARD,
    icon: 'flutter_dash',
    permission: DASHBOARD_VIEW,
    children: [
      {
        title: 'userMenu.dashboardOverview',
        url: DASHBOARD,
        icon: 'dashboard',
        permission: DASHBOARD_VIEW
      },
      {
        title: 'userMenu.healthCheck',
        url: HEALTH_CHECK,
        icon: 'favorite',
        permission: HEALTHCHECK_VIEW
      }
    ]
  },
  {
    title: 'userMenu.devices',
    url: AZURE_DEVICES,
    icon: 'devices',
    permission: DEVICES_VIEW
  },
  {
    title: 'userMenu.organisations',
    url: ORGANISATION,
    icon: 'groups',
    permission: ORGANISATIONS_VIEW,
    children: [
      {
        title: 'userMenu.organisations',
        url: ORGANISATION,
        icon: 'dvr',
        permission: ORGANISATIONS_VIEW
      },
      {
        title: 'userMenu.organisationsOverview',
        url: ORG_OVERVIEW,
        icon: 'overview',
        permission: ORGANISATIONS_OVERVIEW
      },
      {
        title: 'userMenu.groupTags',
        url: GROUPTAGS,
        icon: 'tag',
        permission: GROUP_TAG_VIEW
      }
    ]
  }
]
