import { Breadcrumbs, Divider, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import FullPageLoading from '~/components/FullPageLoading'
import { GOOGLE_DEVICES } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { selectSnackbar } from '~/store/snackbar/selector'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { useGoogleDeviceDetailQuery } from '../query'
import { isValidDateTimeString } from '~/utils/helpers'
import DateTimeLocale from '~/components/DateTimeLocale'

const GoogleDevice = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { data, isLoading } = useGoogleDeviceDetailQuery(id)

  const snackbarData = useSelector(selectSnackbar)
  const { showSnackbar, snackbar } = useSnackbar()
  const dispatch = useDispatch()
  useEffect(() => {
    if (snackbarData) {
      showSnackbar(snackbarData.message, snackbarData.type)
    }
    return () => {
      dispatch(unsetSnackbar({}))
    }
  })

  const localization = 'pages.device.detail'

  if (isLoading) {
    return <FullPageLoading />
  }

  return (
    <>
      {snackbar}
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to={GOOGLE_DEVICES}>{t(`${localization}.deviceOverview`)}</NavLink>
        </Typography>
        <Typography>{data?.data?.serial}</Typography>
      </Breadcrumbs>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant='h6'>{t(`${localization}.deviceInformation`)}</Typography>
      {Object.keys(data.data).map(key => {
        const value = data.data[key]
        if (isValidDateTimeString(value)) {
          return (
            <div key={key}>
              {key} : <DateTimeLocale datetime={value} />
            </div>
          )
        }

        return (
          <div key={key}>
            {key} : {value === true ? 'true' : value === false ? 'false' : value}
          </div>
        )
      })}
    </>
  )
}
export default GoogleDevice
