import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography
} from '@mui/material'
import AzureDeviceTable from './Azure/DeviceOverview/AzureDeviceTable'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAzureDeviceListOverviewQuery, useGoogleDeviceListOverviewQuery } from './query'
import { AZURE, GOOGLE } from '~/constants/constants'
import SearchToolBar from '~/components/SearchToolBar'
import PaginationTable from '~/components/PaginationTable'
import GoogleDeviceTable from './GoogleDeviceOverview/GoogleDeviceTable'
import ToggleButton from '~/components/Button/ToggleButton'
import LoopIcon from '~/components/Button/LoopIcon'

const DeviceOverview = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState({ label: 'Pending', id: 'pending' })
  const [deviceType, setDeviceType] = useState(AZURE)
  const [fetching, setFetching] = useState(
    localStorage.getItem('ADMIN_IS_FETCHING_DEVICE') === 'true'
  )
  const [refetchInterval, setRefectchInterval] = useState(0)

  const { data, isLoading, refetch } = useAzureDeviceListOverviewQuery(
    {
      page,
      search,
      order,
      orderBy,
      filter: selected?.id
    },
    deviceType === AZURE,
    refetchInterval
  )

  const { data: googleData } = useGoogleDeviceListOverviewQuery(
    {
      page,
      search,
      order,
      orderBy,
      filter: selected?.id
    },
    deviceType === GOOGLE
  )

  const azureDevices = useMemo(() => {
    return data ? data.data : []
  }, [data])

  const googleDevices = useMemo(() => {
    return googleData ? googleData.data : []
  }, [googleData])

  useEffect(() => {
    if (fetching) {
      refetch()
      setRefectchInterval(5000)
    } else {
      setRefectchInterval(0)
    }
  }, [fetching, refetch])

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const searchHandler = useCallback(event => {
    if (event.key === 'Enter') {
      setSearchValue(event.target.value)
      setPage(1)
    }
  }, [])

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  const listOptions = [
    { label: 'Pending', id: 'pending' },
    { label: 'Last 5 minutes', id: 'last5Minutes' },
    { label: 'Last 30 minutes', id: 'last30Minutes' },
    { label: 'Last hour', id: 'lastHour' },
    { label: 'Today', id: 'today' },
    { label: 'Last week', id: 'lastWeek' }
  ]

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Device Overview</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <Toolbar style={{ minHeight: 80 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3}>
              <Autocomplete
                id='type'
                disableClearable
                options={[
                  { label: AZURE, id: AZURE },
                  { label: GOOGLE, id: GOOGLE }
                ]}
                value={deviceType}
                isOptionEqualToValue={(option, value) => option.id === value}
                onChange={(event, newValue) => {
                  setDeviceType(newValue.id)
                }}
                renderInput={params => <TextField {...params} label='Type' />}
              />
            </Grid>
            <Grid item>
              <ToggleButton
                content={<LoopIcon isFetching={fetching} />}
                value='check'
                selected={fetching}
                onChange={e => {
                  localStorage.setItem('ADMIN_IS_FETCHING_DEVICE', !fetching)
                  setFetching(v => !v)
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item xs={6} sm={6}>
              <Autocomplete
                id='az-filter'
                name='filter'
                options={listOptions}
                value={selected}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  setSelected(newValue)
                }}
                disableClearable
                renderInput={params => <TextField {...params} label='Filter' />}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SearchToolBar
                filterSearch={search}
                onFilterSearch={searchHandler}
                placeholder='serials_or_importStatus'
              />
            </Grid>
          </Grid>
        </Toolbar>

        <Divider />
        {deviceType === AZURE && (
          <>
            <AzureDeviceTable
              order={order}
              orderBy={orderBy}
              isLoading={isLoading}
              devices={azureDevices}
              handleRequestSort={handleRequestSort}
            />
            <PaginationTable
              data={data}
              page={page}
              size='medium'
              handleChangePage={handleChangePage}
            />
          </>
        )}

        {deviceType === GOOGLE && (
          <>
            <GoogleDeviceTable
              order={order}
              orderBy={orderBy}
              isLoading={isLoading}
              devices={googleDevices}
              handleRequestSort={handleRequestSort}
            />
            <PaginationTable
              data={googleData}
              page={page}
              size='medium'
              handleChangePage={handleChangePage}
            />
          </>
        )}
      </Paper>
    </>
  )
}

export default DeviceOverview
