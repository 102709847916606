export const registrationHeadCells = [
  { id: 'id', numeric: true, disablePadding: false, label: 'id', orderable: true },
  { id: 'type', numeric: false, disablePadding: false, label: 'type', orderable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'name', orderable: true },
  { id: 'email', numeric: false, disablePadding: false, label: 'email', orderable: true },
  {
    id: 'tenant_domain',
    numeric: false,
    disablePadding: false,
    label: 'tenantDomain',
    orderable: true
  },
  {
    id: 'organisation_name',
    numeric: false,
    disablePadding: false,
    label: 'organisationName',
    orderable: true
  },
  {
    id: 'mobile_number',
    numeric: false,
    disablePadding: false,
    label: 'mobileNumber',
    orderable: true
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'status', orderable: true },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'createdAt', orderable: true },
  { id: 'actions', numeric: false, disablePadding: false, label: 'actions', orderable: false }
]
