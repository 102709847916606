import { Breadcrumbs, Divider, Typography } from '@mui/material'
import TokenTable from './TokenTable/TokenTable'
import { useTranslation } from 'react-i18next'

const ProvisionTokensTable = () => {
  const { t } = useTranslation()

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('pages.organisation.preprovisioningToken')}</Typography>
      </Breadcrumbs>
      <Divider />
      <TokenTable />
    </>
  )
}

export default ProvisionTokensTable
