import { Box, Pagination } from '@mui/material'
import { PER_PAGE } from '~/constants/constants'
import PropTypes from 'prop-types'

const PaginationTable = ({ data, page, handleChangePage, size }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        py: 1,
        bgcolor: '#f4f4f4'
      }}
    >
      <Pagination
        count={Math.ceil(data?.total / PER_PAGE) >= 1 ? Math.ceil(data?.total / PER_PAGE) : 0}
        page={page}
        size={size}
        onChange={handleChangePage}
      />
    </Box>
  )
}

PaginationTable.defaultProps = {
  size: 'small'
}

PaginationTable.propTypes = {
  data: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  size: PropTypes.string
}

export default PaginationTable
