import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useOrgOverviewQuery } from '../query'
import { OrgOverviewHeadCells } from './OrgOverviewHeadCells'

const localization = 'pages.organisation.'

const OrgOverviewTableBody = ({ isLoading, orgOverviewData }) => {
  if (isLoading) {
    return <TableLoading colSpan={OrgOverviewHeadCells.length} />
  }

  return (
    <TableBody>
      {orgOverviewData.length > 0 ? (
        orgOverviewData.map((row, index) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align='left'>{row.organisation_name}</TableCell>
              <TableCell align='left'>{row.external_id}</TableCell>
              <TableCell align='left'>{row.devices}</TableCell>
            </TableRow>
          )
        })
      ) : (
        <DataNotFound colSpan={OrgOverviewHeadCells.length} />
      )}
    </TableBody>
  )
}

OrgOverviewTableBody.propTypes = {
  isLoading: PropTypes.bool,
  orgOverviewData: PropTypes.array
}

OrgOverviewTableBody.defaultProps = {
  isLoading: false,
  orgOverviewData: []
}

const OrgOverviewTable = () => {
  const { data, isLoading } = useOrgOverviewQuery()
  return (
    <>
      <Paper elevation={1}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader headCells={OrgOverviewHeadCells} localization={localization} />
            <OrgOverviewTableBody isLoading={isLoading} orgOverviewData={data ? data.data : []} />
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}
export default OrgOverviewTable
