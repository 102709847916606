import { Paper, Table, TableContainer } from '@mui/material'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import TableHeader from '~/components/TableHeader'
import { useUserQuery } from '../Detail/query'
import UserBodyAzureTenant from './Tenant/UserBodyAzureTenant'
import UserBodyGoogleDomain from './Tenant/UserBodyGoogleDomain'
import { GoogleHeadCells, azureHeadCells } from './UserTenantHeadCell'
import PropTypes from 'prop-types'

const localization = 'pages.adminOverview.users.detail.'

const UserTenant = ({ data }) => {
  const { id } = useParams()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')

  const { data: selectUser, isLoading } = useUserQuery(id)
  const currentTenantId = selectUser?.active_tenant_id

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )
  const headCells = selectUser?.is_azure ? azureHeadCells : GoogleHeadCells

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHeader
            headCells={headCells}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            localization={localization}
          />
          {selectUser?.is_azure && (
            <UserBodyAzureTenant
              isLoading={isLoading}
              selectUser={selectUser}
              currentTenantId={currentTenantId}
            />
          )}
          {selectUser?.is_google && (
            <UserBodyGoogleDomain
              isLoading={isLoading}
              selectUser={selectUser}
              currentTenantId={currentTenantId}
            />
          )}
        </Table>
      </TableContainer>
    </>
  )
}

UserTenant.propTypes = {
  data: PropTypes.object
}

export default UserTenant
