import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  Grid,
  Link as MuiLink,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import DataNotFound from '~/components/DataNotFound'
import CustomModal from '~/components/Modal/CustomModal'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import TenantIcon from '~/components/layout/IconComponent'
import { ADMIN_INVITE_AZURE_TENANTS, ADMIN_INVITE_GOOGLE_DOMAIN } from '~/constants/Routes'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import useTable from '~/hooks/useTable'
import { getUserStatusColor } from '~/utils/helpers'
import ActionButton from './ActionButton'
import { useRejectAzUserMutation } from './mutation'
import { useAzureRegistrationQuery } from './query'
import { registrationHeadCells } from './registrationHeadCells'
import DateTimeLocale from '~/components/DateTimeLocale'

const localization = 'pages.adminOverview.registration.'

const RegistrationTableBody = ({ isLoading, registrations, setOpen, setSelected }) => {
  const { overviewUser } = useAdminPermission()
  const navigate = useNavigate()
  if (isLoading) {
    return <TableLoading colSpan={1} />
  }

  const renderIdCol = row =>
    overviewUser.administrator.users.canViewDetail ? (
      <Link to={`/overview/registrations/${row.id}`} sx={{ ml: 2 }}>
        <MuiLink>{row.id}</MuiLink>
      </Link>
    ) : (
      row.id
    )

  const renderActionButtonCol = row => {
    if (row.status === 'pending') {
      return (
        <ActionButton
          row={row}
          onInvite={() => {
            const url = row.is_azure ? ADMIN_INVITE_AZURE_TENANTS : ADMIN_INVITE_GOOGLE_DOMAIN
            navigate(`${url}?registration-id=${row.id}`)
          }}
          onReject={() => {
            setOpen(true)
            setSelected(row)
          }}
        />
      )
    }
    return null
  }

  return (
    <TableBody>
      {registrations?.length === 0 && <DataNotFound colSpan={registrationHeadCells.length} />}
      {registrations?.length > 0 &&
        registrations?.map(row => {
          const chipcolor = getUserStatusColor(row?.status)
          return (
            <TableRow key={row.id}>
              <TableCell>{renderIdCol(row)}</TableCell>
              <TableCell>
                <TenantIcon item={row} />
              </TableCell>
              <TableCell>{row?.name}</TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.tenant_domain}</TableCell>
              <TableCell>{row?.organisation_name}</TableCell>
              <TableCell>{row?.mobile_number}</TableCell>
              <TableCell>
                <Chip label={row?.status} color={chipcolor} size='small' />
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row?.created_at} />
              </TableCell>
              <TableCell sx={{ width: '150px' }}>{renderActionButtonCol(row)}</TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

RegistrationTableBody.defaultProps = {
  isLoading: false,
  registrations: []
}

RegistrationTableBody.propTypes = {
  isLoading: PropTypes.bool,
  registrations: PropTypes.array,
  setOpen: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired
}

const RegistrationTable = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const { mutate: rejectUser } = useRejectAzUserMutation()
  const [filter, setFilter] = useState({ label: 'All', id: 'all' })

  const {
    results,
    data,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    sortHandler,
    changePageHandler,
    filterHandler
  } = useTable({
    query: useAzureRegistrationQuery,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at',
      filter: ''
    }
  })

  const handleOnClose = () => {
    setOpen(false)
    setSelected(null)
  }

  const onSubmit = async () => {
    rejectUser(selected?.id, {
      onSuccess: () => {
        setOpen(false)
        setSelected(null)
      }
    })
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Registrations</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <Toolbar
          sx={{
            mt: 2,
            mb: 2
          }}
        >
          <Grid item xs={6} sm={3}>
            <Autocomplete
              id='type'
              disableClearable
              sx={{ width: 200 }}
              options={[
                { label: 'Pending', id: 'pending' },
                { label: 'Invited', id: 'invited' },
                { label: 'Accepted', id: 'accepted' },
                { label: 'Rejected', id: 'rejected' },
                { label: 'All', id: 'all' }
              ]}
              value={filter}
              isOptionEqualToValue={(option, value) => option.id === value}
              onChange={(event, v) => {
                if (v && v.id === 'all') {
                  filterHandler('')
                } else {
                  setFilter(v)
                  filterHandler(v.id)
                }
              }}
              renderInput={params => <TextField {...params} label='Filter' />}
            />
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={3}>
            <SearchToolBar filterSearch={search} onChange={searchHandler} placeholder='Search' />
          </Grid>
        </Toolbar>
        <Divider />

        <TableContainer>
          <Table>
            <TableHeader
              headCells={registrationHeadCells}
              onRequestSort={sortHandler}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <RegistrationTableBody
              isLoaing={isLoading}
              registrations={results}
              setSelected={setSelected}
              setOpen={setOpen}
            />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={changePageHandler}
        />
      </Paper>
      <CustomModal open={open} onClose={handleOnClose}>
        <Grid item mb={5}>
          <Typography variant='h6' sx={{ fontWeight: 600 }}>
            {t(`${localization}rejectUser`)} #{selected?.id}
          </Typography>
        </Grid>
        <Grid item mb={5}>
          <Typography>
            {t(`${localization}rejectConfirm`)} <b>{selected?.email}</b>?
          </Typography>
        </Grid>
        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton variant='contained' onClick={onSubmit}>
            {t(`${localization}reject`)}
          </LoadingButton>
          <LoadingButton variant='contained' onClick={handleOnClose}>
            {t(`${localization}cancel`)}
          </LoadingButton>
        </Stack>
      </CustomModal>
    </>
  )
}

export default RegistrationTable
