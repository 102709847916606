import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableContainer,
  TextField,
  Toolbar
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableHeader from '~/components/TableHeader'
import { useAzureDeviceListOverviewQuery } from '../query'
import { AzureDeviceHeadCells } from './AzureDeviceHeadCells'
import DeviceTableBody from './AzureDeviceTableBody'
import DeviceToolbarButtonsAndModals from './DeviceToolbarButtons'
import { useUserPermission } from '~/hooks/useUserPermission'

const localization = 'pages.device.'

const AzureDeviceTable = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [fillterStatus, setFillterStatus] = useState('')
  const [page, setPage] = useState(1)

  const [selected, setSelected] = useState([])
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false)

  const [open, setOpen] = useState(false)
  const [deviceToDeregist, setDeviceToDeregist] = useState(null)

  const [openInRepairModal, setOpenInRepairModal] = useState(false)
  const [deviceToInRepair, setDeviceToInRepair] = useState(null)

  const [openIsRepairModal, setOpenIsRepairModal] = useState(false)

  const [isFetchingDevice, setIsFetchingDevice] = useState(
    localStorage.getItem('IS_FETCHING_DEVICE') === 'true'
  )
  const [refectchInterval, setRefectchInterval] = useState(0)

  const { userAbility } = useUserPermission()
  AzureDeviceHeadCells[0].clickable = userAbility.devices.canBulkDeregister

  const {
    data,
    isLoading,
    refetch: refetchDeviceList
  } = useAzureDeviceListOverviewQuery(
    {
      page,
      search,
      order,
      orderBy
    },
    refectchInterval
  )

  useEffect(() => {
    if (isFetchingDevice) {
      refetchDeviceList()
      setRefectchInterval(5000)
    } else {
      setRefectchInterval(0)
    }
  }, [isFetchingDevice, refetchDeviceList])

  const devices = useMemo(() => {
    return data ? data.data : []
  }, [data])

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const searchHandler = useCallback(event => {
    if (event.key === 'Enter') {
      setSearchValue(event.target.value)
      setPage(1)
    }
  }, [])

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  const deregistADivice = useCallback((_, value) => {
    setOpen(true)
    setDeviceToDeregist(value)
  }, [])

  // SELECT ALL CLICK
  const handleSelectAllClick = event => {
    setIsSelectAllSelected(prevIsSelectAllSelected => {
      const newIsSelectAllSelected = !prevIsSelectAllSelected
      const deviceIds = newIsSelectAllSelected
        ? devices
            .filter(item => item.trc_managed === true && item.state !== 'deleting')
            .map(device => device.id)
        : []
      setSelected(deviceIds)
      return newIsSelectAllSelected
    })
  }

  // HANDLE IN-REPAIR SECTION
  const repairDevice = useCallback((_, value) => {
    setOpenInRepairModal(true)
    setDeviceToInRepair(value)
  }, [])

  // HANDLE IS-REPAIR SECTION
  const isRepairDevice = useCallback((_, value) => {
    setOpenIsRepairModal(true)
    setDeviceToInRepair(value)
  }, [])

  return (
    <>
      <DeviceToolbarButtonsAndModals
        selected={selected}
        open={open}
        openInRepairModal={openInRepairModal}
        deviceToDeregist={deviceToDeregist}
        deviceToInRepair={deviceToInRepair}
        openIsRepairModal={openIsRepairModal}
        setOpen={setOpen}
        setSelected={setSelected}
        setDeviceToInRepair={setDeviceToInRepair}
        setIsSelectAllSelected={setIsSelectAllSelected}
        setDeviceToDeregist={setDeviceToDeregist}
        setOpenIsRepairModal={setOpenIsRepairModal}
        setOpenInRepairModal={setOpenInRepairModal}
        refetchDeviceList={refetchDeviceList}
        isFetchingDevice={isFetchingDevice}
        setIsFetchingDevice={setIsFetchingDevice}
      />

      <Paper elevation={1}>
        <Toolbar>
          <Grid container spacing={0.5}>
            <Grid item xs={6} sm={3}>
              <Autocomplete
                id='status'
                disableClearable
                options={[
                  { label: 'All', id: '' },
                  { label: 'Complete', id: 'complete' },
                  { label: 'Error', id: 'error' }
                ]}
                value={fillterStatus}
                isOptionEqualToValue={(option, value) => option.id === value}
                onChange={(event, newValue) => {
                  setSearchValue(newValue.id)
                  setFillterStatus(newValue.label)
                  setPage(1)
                }}
                renderInput={params => <TextField {...params} label='Status' />}
              />
            </Grid>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item xs={12} sm={3}>
              <SearchToolBar
                filterSearch={search}
                onFilterSearch={searchHandler}
                placeholder='serials_or_AadDeviceId'
              />
            </Grid>
          </Grid>
        </Toolbar>

        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader
              headCells={AzureDeviceHeadCells}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              localization={localization}
              isSelectAllSelected={isSelectAllSelected}
              handleSelectAllClick={handleSelectAllClick}
            />
            <DeviceTableBody
              isLoading={isLoading}
              devices={devices}
              onDeregisClick={deregistADivice}
              onRepairClick={repairDevice}
              onIsRepairClick={isRepairDevice}
              setSelected={setSelected}
              selected={selected}
            />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={handleChangePage}
        />
      </Paper>
    </>
  )
}
export default AzureDeviceTable
