import BadgeIcon from '@mui/icons-material/Badge'
import { LoadingButton } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomModal from '~/components/Modal/CustomModal'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import HealthCheckBox from '../HealthCheckBox'
import { useUpdateGoogleCustomerIdMutation } from '../mutation'

const CustomerSection = ({ tenant }) => {
  const { t } = useTranslation()

  // Constants
  const serviceTenant = tenant.serviceTenant
  const hasCustomerID = Boolean(serviceTenant?.customer_id)

  // States
  const [open, setOpen] = useState(false)
  const [customerId, setCustomerId] = useState(serviceTenant.customer_id)

  const { overviewUser } = useAdminPermission()

  // Mutations
  const { mutate: updateCustomerId } = useUpdateGoogleCustomerIdMutation()

  // Handlers
  const handleClose = () => setOpen(false)
  const handleSaveCustomerId = () => {
    updateCustomerId({ id: serviceTenant?.id, customerId })
    setOpen(false)
  }

  return (
    <>
      <HealthCheckBox
        icon={<BadgeIcon />}
        title={t('pages.healthCheck.customerId')}
        data={{
          is_success: hasCustomerID,
          message: hasCustomerID
            ? `Id: ${serviceTenant?.customer_id}`
            : t('pages.healthCheck.customerIdFail')
        }}
      >
        <LoadingButton
          onClick={() => setOpen(true)}
          sx={{ mt: 1, mr: 1 }}
          variant='contained'
          disabled={!overviewUser.tenants.canCheckStatus}
        >
          {t('pages.tenant.update')}
        </LoadingButton>
      </HealthCheckBox>

      <CustomModal title='Add Customer ID' open={open} onClose={handleClose}>
        <Box>
          <Typography>{t('pages.tenant.updateCustomerId')}</Typography>
          <TextField
            fullWidth
            sx={{ mt: 1, mb: 1 }}
            id='customer_id'
            label='Customer ID'
            variant='outlined'
            value={customerId}
            onChange={e => setCustomerId(e.target.value)}
          />
          <LoadingButton onClick={handleClose} sx={{ mt: 1, mr: 1 }} variant='contained'>
            {t('pages.tenant.cancel')}
          </LoadingButton>
          <LoadingButton
            onClick={handleSaveCustomerId}
            sx={{ mt: 1, mr: 1 }}
            variant='contained'
            disabled={!customerId}
          >
            {t('pages.tenant.save')}
          </LoadingButton>
        </Box>
      </CustomModal>
    </>
  )
}

CustomerSection.propTypes = {
  tenant: PropTypes.object.isRequired
}

export default CustomerSection
