export const GooleDeviceHeadCells = [
  {
    id: 'checkbox',
    checkbox: true
  },
  {
    id: 'serial',
    numeric: false,
    disablePadding: false,
    label: 'serialNumber',
    orderable: true
  },
  {
    id: 'Pre Provisioned Device Id',
    numeric: false,
    disablePadding: false,
    label: 'preProvisionedDeviceId',
    orderable: false
  },
  {
    id: 'Brand Code',
    numeric: false,
    disablePadding: false,
    label: 'brandCode',
    orderable: false
  },
  {
    id: 'State',
    numeric: true,
    disablePadding: false,
    label: 'state',
    orderable: false
  },
  {
    id: 'google_device_created',
    numeric: false,
    disablePadding: false,
    label: 'googleDeviceCreated',
    orderable: false
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'action',
    orderable: false
  }
]
