import PropTypes from 'prop-types'
import {
  useAzureGroupTagQuery,
  useGroupTagLoanerQuery,
  useGroupTagStaffQuery
} from '~/pages/Device/New/query'
import { isOverviewPage } from '~/utils/helpers'
import StudentGroupTag from '.'

const EditGroupTag = ({ open, setOpen, organisationId, organisationDetail }) => {
  const { data: studentGroupTags } = useAzureGroupTagQuery(!isOverviewPage)
  const { data: loanerGroupTags } = useGroupTagLoanerQuery(!isOverviewPage)
  const { data: staffGroupTags } = useGroupTagStaffQuery(!isOverviewPage)

  return (
    <StudentGroupTag
      defaultStudentGroupTags={studentGroupTags}
      groupTagsLoaner={loanerGroupTags}
      grouptagStaff={staffGroupTags}
      open={open}
      setOpen={setOpen}
      organisationId={organisationId}
      organisationDetail={organisationDetail}
    />
  )
}

EditGroupTag.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  organisationId: PropTypes.number,
  organisationDetail: PropTypes.object
}

export default EditGroupTag
