import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { overviewUpdateRole } from '~/api'
import { ADMIN_USER_ROLE } from '~/constants/Routes'

export const useUpdateMutation = props => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(
    ['role_update'],
    async values => {
      const permissions = values.permissions
        .filter(permission => {
          return permission
        })
        .map(permission => {
          return permission
        })
      return await overviewUpdateRole(values.id, {
        ...values,
        permissions
      })
    },
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['role_detail', values.id])
        enqueueSnackbar({
          message: data.message ? data.message : 'Save successful!',
          variant: 'success'
        })
        navigate(ADMIN_USER_ROLE)
        return data
      },
      onError: error => {
        enqueueSnackbar({
          message: error.message ? error.message : 'Save failed!',
          variant: 'error'
        })
        navigate(ADMIN_USER_ROLE)
      },
      retry: false
    }
  )
}
