import { useQuery } from 'react-query'
import { showDeviceApi, showGoogleDeviceApi } from '~/api'

export const useAzureDeviceDetailQuery = id => {
  return useQuery(['azure_device_detail', id], async () => {
    const { data } = await showDeviceApi(id)
    return data
  })
}

export const useGoogleDeviceDetailQuery = id => {
  return useQuery(['google_device_detail', id], async () => {
    const { data } = await showGoogleDeviceApi(id)
    return data
  })
}
