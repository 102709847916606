export const UserHeadCells = [
  { id: 'id', numeric: true, disablePadding: false, label: 'id', orderable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'name', orderable: true },
  { id: 'email', numeric: false, disablePadding: false, label: 'email', orderable: true },
  { id: 'role', numeric: false, disablePadding: false, label: 'role', orderable: true },
  {
    id: 'last_signed_in',
    numeric: true,
    disablePadding: false,
    label: 'lastSignedIn',
    orderable: true
  },
  {
    id: 'ip_address',
    numeric: false,
    disablePadding: false,
    label: 'ipAddress',
    orderable: true
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'status', orderable: true },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'createdAt', orderable: true }
]
