import styled from '@emotion/styled'
import { Box, Modal, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import MicrosoftLoginButton from './Header/MicrosoftLoginButton'
import GoogleLoginButton from './Header/GoogleLoginButton'

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
const LoginPopup = ({ openPopup, setOpenPopup }) => {
  const handleClosePopup = () => {
    setOpenPopup(false)
  }

  return (
    <StyledModal
      open={openPopup}
      onClose={e => handleClosePopup()}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box bgcolor='background.default' color='text.primary' p={3} width={300}>
        <Typography variant='h6' color='gray' textAlign='center'>
          Sign In
        </Typography>
        <MicrosoftLoginButton />
        <GoogleLoginButton />
      </Box>
    </StyledModal>
  )
}

LoginPopup.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired
}

export default LoginPopup
