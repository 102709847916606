import { LoadingButton } from '@mui/lab'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const CancelButton = ({ onClick, ...props }) => {
  const { t } = useTranslation()
  return (
    <LoadingButton
      color='inherit'
      size='small'
      variant='contained'
      {...props}
      sx={{
        textTransform: 'none',
        fontSize: 'smaller',
        '& .MuiButton-startIcon': { margin: '0px' },
        minWidth: 0
      }}
      onClick={onClick}
    >
      {t(`button.cancel`)}
    </LoadingButton>
  )
}

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
