import { enqueueSnackbar } from 'notistack'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'
import { overviewGetListRoles } from '~/api'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useRolesQuery = params => {
  return useQuery(
    ['roles', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await overviewGetListRoles(p)
      return { data: data?.data || [], total: data?.meta?.total || 0 }
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
