import { Alert, Box, Breadcrumbs, Button, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getHealthCheckStatuses } from '~/api'
import { selectUser } from '../../store/auth/selector'
import { useUserPermission } from '~/hooks/useUserPermission'
import MissingPermission from '../Admin/MissingPermission'

const Dashboard = () => {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const serviceTenant = user?.tenant?.service_tenant
  const { userAbility } = useUserPermission()

  const { data: healthCheck } = useQuery(['health_check_statuses', user?.tenant?.id], async () => {
    const { data } = await getHealthCheckStatuses(user?.tenant?.id)
    return data
  })

  const isAllSet =
    serviceTenant?.tenant_id != null &&
    healthCheck?.permissionCheck?.is_success &&
    healthCheck?.applicationConnectionCheck?.is_success

  if (!userAbility.dashboard.canView) {
    return <MissingPermission />
  }

  if (user?.is_google) {
    return (
      <>
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography>{t('general.dashboard')}</Typography>
        </Breadcrumbs>
        <Divider
          style={{
            marginBottom: 20
          }}
        />
      </>
    )
  }

  if (healthCheck == null) {
    return null
  }
  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.dashboard')}</Typography>
      </Breadcrumbs>
      <Divider
        style={{
          marginBottom: 20
        }}
      />

      {isAllSet && (
        <Alert severity='success' style={{ marginBottom: 20 }}>
          {t('general.yourAccountAreAllSet')}
        </Alert>
      )}

      {!isAllSet && (
        <Alert severity='warning' style={{ marginBottom: 20 }}>
          <Box marginBottom={2}>
            The service is not working normally, please check Health Check page for further details
          </Box>
          <Link to='/health-check'>
            <Button variant='contained'>Go to Health Check</Button>
          </Link>
        </Alert>
      )}

      {!serviceTenant?.has_intune_licence_valid && (
        <Alert severity='warning' style={{ marginBottom: 20 }}>
          {t('general.hasIntuneLicenceValid')}
        </Alert>
      )}
    </>
  )
}

export default Dashboard
