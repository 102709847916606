import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import GoogleLogoImage from '~/assets/images/google.png'
import { useLoginWithGoogleMutation } from '../mutation'
import { KEY_AUTH_TOKEN } from '~/constants/constants'
import { useLocation } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'

const useStyle = makeStyles()(theme => ({
  button: {
    width: '100%',
    marginTop: '10px',
    justifyContent: 'left',
    textTransform: 'none',
    backgroundColor: '#76B72A',
    '&:hover': {
      backgroundColor: '#102F44',
      color: 'white'
    }
  }
}))

const GoogleLoginButton = ({ ...props }) => {
  const { t } = useTranslation()
  const { classes } = useStyle()
  const location = useLocation()

  const googleRedirect = localStorage.getItem('google_redirect')
  useEffect(() => {
    if (googleRedirect) {
      localStorage.removeItem('google_redirect')
      localStorage.removeItem(KEY_AUTH_TOKEN)
    }
  }, [googleRedirect])

  const { login, isLoading } = useLoginWithGoogleMutation()
  const ggLogin = useCallback(async () => {
    if (location.pathname.includes('/login')) {
      enqueueSnackbar({
        message: 'Not supported for now',
        variant: 'error'
      })
    } else {
      await login()
    }
  }, [location, login])

  return (
    <LoadingButton
      variant='contained'
      className={classes.button}
      isLoading={isLoading}
      onClick={() => ggLogin()}
      {...props}
    >
      <img
        src={GoogleLogoImage}
        alt=''
        style={{
          marginRight: '0.5rem',
          padding: '2px',
          background: '#fff'
        }}
      />
      {t('button.SignInWithGoogle')}
    </LoadingButton>
  )
}

export default GoogleLoginButton
