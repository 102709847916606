import { createSlice } from '@reduxjs/toolkit'

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    user: null,
    isGGRegistration: false,
    registrationEmail: ''
  },
  reducers: {
    setRegistrationUser: (state, action) => {
      state.user = action.payload
    },
    setIsGoogleRegistration: (state, action) => {
      state.isGGRegistration = action.payload
    },
    setRegistrationEmail: (state, action) => {
      state.registrationEmail = action.payload
    }
  }
})

export const { setRegistrationUser, setIsGoogleRegistration, setRegistrationEmail } =
  registrationSlice.actions

export default registrationSlice

export const selectRegistrationUser = state => state.registration.user
