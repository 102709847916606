import PropTypes from 'prop-types'
import StudentGroupTag from '.'
import {
  useOvervbiewGroupTagLoanerQuery,
  useOverviewGroupTagsQuery,
  useOverviewGroupTagStaffQuery
} from '../../GroupTag/query'

const OverviewEditGroupTag = ({ open, setOpen, organisationId, organisationDetail }) => {
  const { data: overviewStudentGroupTags } = useOverviewGroupTagsQuery(organisationDetail.tenant_id)
  const { data: overviewGroupTagsLoaner } = useOvervbiewGroupTagLoanerQuery(
    organisationDetail.tenant_id
  )
  const { data: overviewGrouptagStaff } = useOverviewGroupTagStaffQuery(
    organisationDetail.tenant_id
  )

  return (
    <StudentGroupTag
      defaultStudentGroupTags={overviewStudentGroupTags}
      groupTagsLoaner={overviewGroupTagsLoaner}
      grouptagStaff={overviewGrouptagStaff}
      open={open}
      setOpen={setOpen}
      organisationId={organisationId}
      organisationDetail={organisationDetail}
    />
  )
}

OverviewEditGroupTag.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  organisationId: PropTypes.number,
  organisationDetail: PropTypes.object
}

export default OverviewEditGroupTag
