import { useParams } from 'react-router-dom'
import {
  getHealthCheckStatuses,
  getTenantDetail,
  healthCheckSyncData,
  syncDeviceApi
} from '~/api/admin'
import HealthCheck from '~/pages/HealthCheck/HealthCheck'

const OverviewHealthCheck = () => {
  const { tenantId } = useParams()

  return (
    <HealthCheck
      tenantId={tenantId}
      getTenantInfoApi={getTenantDetail}
      healthCheckApi={getHealthCheckStatuses}
      healthCheckSyncDataApi={healthCheckSyncData}
      syncDevicesApi={syncDeviceApi}
      grantApplicationPermissionApi={() => {
        console.info('admin is not allowed to grant app permissions')
      }}
    />
  )
}

export default OverviewHealthCheck
