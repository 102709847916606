import { Breadcrumbs, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import OrgOverviewTable from './OrgOverviewTable/OrgOverviewTable'

const Overview = () => {
  const { t } = useTranslation()
  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.overview')}</Typography>
      </Breadcrumbs>
      <Divider />
      <OrgOverviewTable />
    </>
  )
}

export default Overview
