import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { pathToRegexp } from 'path-to-regexp'
import { selectUserPermissions } from '~/store/user/selector'
import {
  SUPER_ADMIN,
  DASHBOARD,
  DASHBOARD_VIEW,
  HEALTHCHECK_VIEW,
  HEALTHCHECK,
  DEVICES_VIEW,
  DEVICES,
  DEVICES_VIEW_DETAIL,
  DEVICES_REGISTER,
  DEVICES_DEREGISTER,
  DEVICES_BULK_DEREGISTER,
  DEVICES_BULK_DEREGISTER_BY_CSV,
  ORGANISATIONS_VIEW,
  ORGANISATIONS,
  ORGANISATIONS_OVERVIEW,
  ORGANISATIONS_REMOVE_TENANT,
  GROUP_TAG_VIEW,
  GROUP_TAG,
  GROUP_TAG_UPDATE,
  ZTE_TOKEN,
  ZTE_TOKEN_VIEW,
  ZTE_TOKEN_UPDATE,
  DEVICES_REPAIR
} from '~/constants/userPermissions'

const ROUTE_PERMISSION_MAPPING = {
  '/login': [],
  '/overview/login': [],
  '/dashboard': [DASHBOARD_VIEW, DASHBOARD],
  '/azure/devices': [DEVICES_VIEW, DEVICES],
  '/azure/devices/new': [DEVICES_REGISTER, DEVICES],
  '/azure/devices/:id': [DEVICES_VIEW_DETAIL, DEVICES],
  '/azure/group-tags': [GROUP_TAG_VIEW, GROUP_TAG],
  '/azure/organisation-overview': [ORGANISATIONS_OVERVIEW, ORGANISATIONS],
  '/organisations': [ORGANISATIONS_VIEW, ORGANISATIONS],
  '/health-check': [HEALTHCHECK_VIEW, HEALTHCHECK, DASHBOARD],
  '/google/devices/new': [DEVICES_REGISTER, DEVICES],
  '/google/devices/:id': [DEVICES_VIEW_DETAIL, DEVICES],
  '/google/devices': [DEVICES_VIEW, DEVICES],
  '/google/provisioning-tokens': [ZTE_TOKEN_VIEW, ZTE_TOKEN]
}

export const useUserPermission = () => {
  const userPermissions = useSelector(selectUserPermissions)
  const location = useLocation()
  const pathname = location.pathname

  const hasPermission = useMemo(() => {
    if (!pathname.startsWith('/overview/') && userPermissions.includes(SUPER_ADMIN)) {
      return true
    }

    // check if user have permission to access this route,
    // if the url not exist, dont need to check permission
    for (const url in ROUTE_PERMISSION_MAPPING) {
      const pattern = pathToRegexp(url)
      const match = pattern.exec(pathname)

      if (match) {
        const permissions = ROUTE_PERMISSION_MAPPING[url]
        return permissions.some(permission => userPermissions.includes(permission))
      }
    }

    return true
  }, [pathname, userPermissions])

  const canEvery = permissions => {
    if (!pathname.startsWith('/overview/') && userPermissions.includes(SUPER_ADMIN)) {
      return true
    }

    if (Array.isArray(permissions)) {
      return permissions.every(permission => userPermissions.includes(permission))
    }
    return userPermissions.includes(permissions)
  }

  const canAny = permissions => {
    if (!pathname.startsWith('/overview/') && userPermissions.includes(SUPER_ADMIN)) {
      return true
    }

    if (Array.isArray(permissions)) {
      return !!permissions.some(permission => userPermissions.includes(permission))
    }
    return !!userPermissions.includes(permissions)
  }

  const userAbility = {
    dashboard: {
      canView: canAny([DASHBOARD_VIEW, DASHBOARD]),
      healthCheck: {
        canView: canAny([HEALTHCHECK_VIEW, HEALTHCHECK, DASHBOARD])
      }
    },
    devices: {
      canView: canAny([DEVICES_VIEW, DEVICES]),
      canViewDetail: canAny([DEVICES_VIEW_DETAIL, DEVICES]),
      canRegister: canAny([DEVICES_REGISTER, DEVICES]),
      canDeregister: canAny([DEVICES_DEREGISTER, DEVICES]),
      canBulkDeregister: canAny([DEVICES_BULK_DEREGISTER, DEVICES]),
      canBulkDeleteByCsv: canAny([DEVICES_BULK_DEREGISTER_BY_CSV, DEVICES]),
      canRepair: canAny([DEVICES_REPAIR, DEVICES])
    },
    organisations: {
      canView: canAny([ORGANISATIONS_VIEW, ORGANISATIONS]),
      canOverview: canAny([ORGANISATIONS_OVERVIEW, ORGANISATIONS]),
      canRemoveTenant: canAny([ORGANISATIONS_REMOVE_TENANT, ORGANISATIONS]),
      groupTag: {
        canView: canAny([GROUP_TAG_VIEW, GROUP_TAG, ORGANISATIONS]),
        canUpdate: canAny([GROUP_TAG_UPDATE, GROUP_TAG, ORGANISATIONS])
      },
      zteToken: {
        canView: canAny([ZTE_TOKEN_VIEW, ZTE_TOKEN, ORGANISATIONS]),
        canUpdate: canAny([ZTE_TOKEN_UPDATE, ZTE_TOKEN, ORGANISATIONS])
      }
    }
  }

  return {
    hasPermission,
    canEvery,
    canAny,
    userAbility
  }
}
