import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Divider, Paper, Stack, TextField } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import BreadCrumb from '~/components/BreadCrumb'
import FullPageLoading from '~/components/FullPageLoading'
import { ADMIN_USER_COMPANY } from '~/constants/Routes'
import { useOverviewUpdateCompanyMutation } from './mutation'
import { useOverviewCompanyQuery } from './query'

const localization = 'pages.overview.company'

const schema = yup
  .object({
    name: yup.string().required()
  })
  .required()

const Edit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data, isLoading: retrievingData } = useOverviewCompanyQuery(id)
  const { mutate, isLoading, isSuccess } = useOverviewUpdateCompanyMutation(id)
  const onSubmit = async values => {
    mutate(values)
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  useEffect(() => {
    if (isSuccess) {
      navigate(ADMIN_USER_COMPANY)
    }
  }, [isSuccess, navigate])

  if (retrievingData) {
    return <FullPageLoading />
  }

  const BreadComponents = [
    {
      title: 'Administrator'
    },
    {
      route: ADMIN_USER_COMPANY,
      title: 'Company Overview'
    },
    {
      page: 'edit',
      title: data?.name || 'N/A'
    }
  ]

  return (
    <>
      <BreadCrumb breadcrumbs={BreadComponents} />
      <Divider style={{ marginBottom: 20 }} />
      <Paper
        sx={{
          px: 2,
          py: 2
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <TextField
              {...register('name')}
              label={t(`${localization}.name`)}
              variant='outlined'
              fullWidth
              defaultValue={data?.name}
              error={!!errors.name}
              helperText={errors?.name?.message}
            />

            <Stack direction='row-reverse'>
              <LoadingButton variant='contained' type='submit' loading={isLoading}>
                {t('button.create')}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </>
  )
}

export default Edit
