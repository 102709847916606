import { Box, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ProductionLogo from '~/assets/images/logo/easy4E.png'
import StagingLogo from '~/assets/images/logo/easy4E_staging.png'

import { useRegistrationInfoQuery } from '../Registration/query'
import FullPageLoading from '~/components/FullPageLoading'
import AdditionalForm from './Steps/AdditionalForm'

const GoogleRegistration = () => {
  const search = useLocation()
  const registrationToken = new URLSearchParams(search.search).get('state')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, isFetching } = useRegistrationInfoQuery(registrationToken)

  useEffect(() => {
    if (data?.registered_at != null) {
      navigate({ pathname: '/login' })
      enqueueSnackbar({
        message: 'The tenant has been registered, please use your microsoft admin account to login',
        variant: 'success'
      })
    }
  }, [data, dispatch, navigate])

  if (!data || isFetching) {
    return <FullPageLoading />
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        bgcolor: '#f0f0f0',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mb: 2,
          mt: 2
        }}
      >
        <div style={{ marginBottom: 20 }}>
          {import.meta.env.VITE_ENVIRONMENT === 'development' ? (
            <img src={StagingLogo} width={150} alt='' />
          ) : (
            <img src={ProductionLogo} width={150} alt='' />
          )}
        </div>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 8,
            bgcolor: '#fff',
            borderRadius: 8,
            height: 'fit-content',
            px: 4,
            py: 4
          }}
        >
          <Typography>Welcome to easy4Enroll</Typography>
          <Box sx={{ width: 550 }}>
            <AdditionalForm data={data} registrationToken={registrationToken} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default GoogleRegistration
