import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { LoadingButton } from '~/components/Button/LoadingButton'
import CustomModal from '~/components/Modal/CustomModal'
import { useOrganisationDetailQuery, useOrganisationListOverviewQuery } from '../query'
import {
  useRemoveOrganisationFromTenantMutation,
  useUpdateProvisionTokenMutation
} from '../mutation'
import { isOverviewPage } from '~/utils/helpers'
import { useIntuneEnrollTriggerListQuery } from '~/pages/HealthCheck/query'
import { useUpdateIntuneEnrollTriggerMutation } from '~/pages/HealthCheck/mutation'
import { CancelButton } from '~/components/Button/CancelButton'
import { useUserPermission } from '~/hooks/useUserPermission'

const flattenOrganisations = arr => {
  return arr.flatMap(item => [item, ...flattenOrganisations(item.childrens || [])])
}

const OrganisationGoogleDetails = ({ organisationId }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [token, setToken] = useState('')
  const [openRemove, setOpenRemove] = useState(false)
  const { userAbility } = useUserPermission()
  const formRef = useRef(null)

  const localization = 'pages.organisation.'

  const { data: ietList } = useIntuneEnrollTriggerListQuery(isOverviewPage)

  const { data: organisations } = useOrganisationListOverviewQuery()
  const {
    data: organisationDetail,
    isLoading: isLoadingDetails,
    isRefetching,
    refetch: refetchOrgDetail,
    isSuccess
  } = useOrganisationDetailQuery(organisationId)

  const { mutate: removeOrganisationFromTenant, isLoading: isRemoving } =
    useRemoveOrganisationFromTenantMutation(organisationId)
  const { mutate: updateToken, isLoading: isTokenUpdating } =
    useUpdateProvisionTokenMutation(organisationId)
  const { mutate: updateIet } = useUpdateIntuneEnrollTriggerMutation(organisationId)

  const isDetailLoading = isLoadingDetails || isRefetching

  const flatOrganisations = useMemo(() => {
    return flattenOrganisations(organisations || [])
  }, [organisations])

  const tokenPopup = useCallback((_, value) => {
    setOpen(true)
    setToken(value.pre_provisioning_token || null)
  }, [])

  // HANDLE SUBMIT TOKEN
  const handleSubmitModal = useCallback(() => {
    if (token === null) {
      handleCloseModal()
      return
    }

    updateToken(token)
    handleCloseModal()
  }, [token, updateToken])

  // HANDLE SUBMIT REMOVE ORGANISATION FROM TENANT
  const handleSubmitRemoveOrganisationModal = useCallback(() => {
    removeOrganisationFromTenant(organisationDetail.id)
    setOpenRemove(false)
    refetchOrgDetail()
  }, [organisationDetail, refetchOrgDetail, removeOrganisationFromTenant])

  // MODAL REMOVE ORGANISATION FROM TENANT
  const removeOrganisationPopup = () => {
    setOpenRemove(true)
  }

  const handleCloseRemoveOrganisationModal = () => {
    setOpenRemove(false)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const { setValue, getValues, control, handleSubmit } = useForm({})

  const handleChange = useCallback(
    e => {
      setValue('intune_enroll_trigger', e.target.value)
      if (formRef.current) {
        formRef.current.requestSubmit()
      }
    },
    [setValue]
  )

  const onSubmit = useCallback(() => {
    updateIet(getValues())
  }, [getValues, updateIet])

  const renderIetForm = useMemo(() => {
    if (!isOverviewPage || !isSuccess) {
      return (
        <p>
          {t(`${localization}enrollTrigger`)}: {organisationDetail?.intune_enroll_trigger}
        </p>
      )
    }

    return (
      <Grid container alignItems='center'>
        <Grid item xs={12} md={2} key={name}>
          {t(`${localization}enrollTrigger`)}:
        </Grid>
        <Grid item xs={12} md={10} key={name}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <Controller
                name='intune_enroll_trigger'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    renderValue={selected => {
                      return selected ?? 'do_nothing (Do nothing)'
                    }}
                    onChange={handleChange}
                    value={organisationDetail?.intune_enroll_trigger}
                    displayEmpty
                  >
                    <MenuItem value={null}>do_nothing (Do nothing)</MenuItem>
                    {ietList?.map(value => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
    )
  }, [
    control,
    handleChange,
    handleSubmit,
    ietList,
    isSuccess,
    onSubmit,
    organisationDetail?.intune_enroll_trigger,
    t
  ])

  const foundOrganisation = flatOrganisations.find(
    i => i.name === organisationDetail?.name && !organisationDetail?.is_temporary_removed
  )

  if (!organisationDetail || (!foundOrganisation && !isOverviewPage)) {
    return null
  }

  if (isDetailLoading && !isOverviewPage) {
    return (
      <Grid container justifyContent='center' alignItems='center' minHeight={200}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Paper sx={{ padding: 4 }}>
      <Grid container>
        <Grid container justifyContent='space-between' flexDirection='row'>
          <Grid>
            <Typography variant='h6'>{t(`${localization}organisationInformation`)}</Typography>
          </Grid>
          <Grid justifyContent='space-between' flexDirection='row' display='flex'>
            <Box sx={{ m: 0.5 }}>
              <LoadingButton
                onClick={e =>
                  removeOrganisationPopup(e, organisationDetail?.id, organisationDetail?.name)
                }
                loading={isRemoving}
                disabled={!isOverviewPage && !userAbility.organisations.canRemoveTenant}
              >
                {t(`${localization}removeOrganisationFromTenant`)}
              </LoadingButton>
            </Box>
            <Box sx={{ m: 0.5 }}>
              <LoadingButton
                onClick={e => tokenPopup(e, organisationDetail)}
                loading={isTokenUpdating}
                disabled={!!isOverviewPage && userAbility.organisations.zteToken.canUpdate}
              >
                {t(`${localization}updateToken`)}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <p>
        {t(`${localization}Id`)}: {organisationDetail?.id}
      </p>
      <p>
        {t(`${localization}externalId`)}: {organisationDetail?.external_id}
      </p>
      <p>
        {t(`${localization}name`)}: {organisationDetail?.name}
      </p>
      <p>
        {t(`${localization}nameShort`)}: {organisationDetail?.name_short}
      </p>
      <p>
        {t(`${localization}parentExternalId`)}: {organisationDetail?.parent_external_id}
      </p>
      {renderIetForm}
      <p>
        {t(`${localization}contact`)}: {organisationDetail?.contact_id}
      </p>
      <p>
        {t(`${localization}preprovisioningToken`)}: {organisationDetail?.pre_provisioning_token}
      </p>

      <CustomModal open={openRemove} onClose={handleCloseRemoveOrganisationModal}>
        <Typography variant='h6'>
          {t(`${localization}doYouWantToRemoveOrganisationFromTenant`)}: {organisationDetail?.name}?
        </Typography>
        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton onClick={handleSubmitRemoveOrganisationModal} loading={isRemoving}>
            {t(`${localization}confirm`)}
          </LoadingButton>
          <CancelButton onClick={handleCloseRemoveOrganisationModal} />
        </Stack>
      </CustomModal>

      <CustomModal open={open} onClose={handleCloseModal}>
        <Typography> {organisationDetail?.name} </Typography>
        <Box sx={{ minWidth: 120, marginTop: 2 }}>
          <form onSubmit={handleSubmit(handleSubmitModal)}>
            <FormControl fullWidth>
              <TextField
                name='pre_provisioning_token'
                label='Pre provisioning token'
                onChange={e => {
                  setToken(e.target.value)
                }}
                value={token}
              />
            </FormControl>
          </form>
        </Box>
        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton onClick={handleSubmitModal}>{t(`${localization}confirm`)}</LoadingButton>
          <CancelButton onClick={handleCloseModal} />
        </Stack>
      </CustomModal>
    </Paper>
  )
}

OrganisationGoogleDetails.propTypes = {
  organisationId: PropTypes.number
}

export default OrganisationGoogleDetails
