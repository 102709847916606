import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { overviewCreateCompany, overviewUpdateCompany } from '~/api'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useOverviewCreateCompanyMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ['overview_create_company'],
    async payload => {
      return await overviewCreateCompany(payload)
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully create company',
          variant: 'success'
        })

        queryClient.invalidateQueries('overview_companies')
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useOverviewUpdateCompanyMutation = companyId => {
  const queryClient = useQueryClient()
  return useMutation(
    ['overview_update_company', companyId],
    async payload => {
      return await overviewUpdateCompany(companyId, payload)
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully update company',
          variant: 'success'
        })

        queryClient.invalidateQueries('overview_companies')
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}
