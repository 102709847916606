import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { parse } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import { useGrantAppPermissionMutation } from '../mutation'

const GrantAppPermissions = ({ registrationToken, msTenantId }) => {
  const [flag, setFlag] = useState(false)
  const { admin_consent, error } = parse(window.location.search, { ignoreQueryPrefix: true })

  const grantPermissionRedirect = useCallback(() => {
    const redirectUrl = 'redirect_uri=' + window.location.origin + '/registration'
    const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID
    const url = `https://login.microsoftonline.com/${msTenantId}/adminconsent?client_id=${clientId}&${redirectUrl}&state=${registrationToken}`
    window.location.href = url
  }, [msTenantId, registrationToken])

  const permissionMutation = useGrantAppPermissionMutation()
  const queryClient = useQueryClient()
  useEffect(() => {
    if (admin_consent === 'True' && !error && !flag) {
      setFlag(true) // prevent Too many attemps error
      permissionMutation.mutate(registrationToken, {
        onSuccess: () => {
          queryClient.invalidateQueries('registration_token')
        }
      })
    }
  }, [admin_consent, error, flag, permissionMutation, queryClient, registrationToken])

  return (
    <>
      <Typography>Click the button below to grant permissions to your application.</Typography>
      <Box sx={{ mb: 2 }}>
        <div>
          <LoadingButton
            onClick={grantPermissionRedirect}
            sx={{ mt: 1, mr: 1 }}
            loading={permissionMutation.isLoading}
            variant='contained'
          >
            Grant App Permission
          </LoadingButton>
        </div>
      </Box>
    </>
  )
}

GrantAppPermissions.propTypes = {
  registrationToken: PropTypes.string,
  msTenantId: PropTypes.string
}

export default GrantAppPermissions
