import { Autocomplete, Box, TextField, Typography, createFilterOptions } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useEntitiesQuery } from '~/pages/HealthCheck/query'

const filterOptions = createFilterOptions({
  limit: 50 // reduce the lag when render too much records
})

const EntitySection = ({
  setValue,
  mostUsedEntityId,
  school,
  errors,
  control,
  entity,
  setEntity
}) => {
  const prevSchoolRef = useRef(school)

  // Queries
  const { data: entitiesData } = useEntitiesQuery()

  // Effects
  const entities = useMemo(() => {
    if (entitiesData) {
      return entitiesData.map(option => ({
        label: option.name + ' (' + option.entity_tid + ')',
        company_name: option.company?.name,
        id: option.id
      }))
    }
    return []
  }, [entitiesData])

  // if school is cahnged, make entity change base on school
  useEffect(() => {
    if (school && prevSchoolRef.current !== school) {
      setEntity(entities.find(x => x.id === school.entity_id))
    }
    prevSchoolRef.current = school
  }, [entities, school, setEntity])

  // make most selected entity as default option
  useEffect(() => {
    if (!entities?.length) return
    if (mostUsedEntityId) {
      setEntity(entities.find(x => x.id === mostUsedEntityId))
    }
  }, [entities, mostUsedEntityId, setEntity])

  // if entity changed, make sure the form register the new value
  useEffect(() => {
    setValue('entity_id', entity?.id)
  }, [entity, setValue])

  return (
    <>
      <Controller
        render={({ field: { onChange, value }, ...props }) => (
          <Autocomplete
            sx={{
              mt: 2
            }}
            fullWidth
            value={entity}
            filterOptions={filterOptions}
            onChange={(e, value) => {
              onChange(value?.id)
              setEntity(value)
            }}
            options={entities}
            getOptionLabel={option => option.label || ''}
            renderInput={params => (
              <TextField
                {...params}
                label='Entity ID'
                error={!!errors.entity_id}
                helperText={<>{errors.entity_id?.message}</>}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box display='flex' gap='8px'>
                  {option.label}
                  <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
                    - {option.company_name}
                  </Typography>
                </Box>
              </li>
            )}
          />
        )}
        defaultValue={entity?.id}
        name={'entity_id'}
        control={control}
      />
    </>
  )
}

EntitySection.propTypes = {
  setValue: PropTypes.func.isRequired,
  entity: PropTypes.object,
  mostUsedEntityId: PropTypes.number,
  school: PropTypes.object,
  setEntity: PropTypes.func,
  control: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    entity_id: PropTypes.bool
  }).isRequired
}

EntitySection.defaultProps = {
  mostUsedEntityId: null,
  entity: null,
  school: null,
  setEntity: () => {},
  errors: PropTypes.shape({
    entity_id: false
  }).isRequired
}

export default EntitySection
