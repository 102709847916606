export const CompanyHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Id',
    orderable: true
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    orderable: true
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
    orderable: true
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Updated At',
    orderable: true
  }
]

export const CompanyUsersHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Id',
    orderable: true
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    orderable: true
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    orderable: true
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    orderable: true
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
    orderable: true
  }
]

export const CompanySchoolsHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Id',
    orderable: true
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    orderable: true
  },
  {
    id: 'tenant.name',
    numeric: false,
    disablePadding: false,
    label: 'Tenant',
    orderable: false
  }
]

export const CompanyTenantsHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Id',
    orderable: true
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    orderable: true
  }
]
