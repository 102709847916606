import { Breadcrumbs, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TenantTable from './Tenant/TenantTable'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ADMIN_LOGIN } from '~/constants/Routes'
import { KEY_ADMIN_AUTH_TOKEN } from '~/constants/constants'

const Overview = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    const isAdminAuth = localStorage.getItem(KEY_ADMIN_AUTH_TOKEN)
    if (!isAdminAuth) {
      navigate(ADMIN_LOGIN)
    }
  })

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.tenantOverview')}</Typography>
      </Breadcrumbs>
      <Divider />
      <TenantTable />
    </>
  )
}

export default Overview
