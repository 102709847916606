import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import propTypes from 'prop-types'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import DataNotFound from '~/components/DataNotFound'
import { useUpdateMutation } from './mutate'
import Toolbar from './Toolbar'

const schema = yup
  .object({
    guard_name: yup.string().required(),
    name: yup.string().required(),
    permissions: yup.array().of(yup.string().nullable())
  })
  .required()

const Body = ({ permissions, useRoleData }) => {
  const localization = 'pages.overview.role'
  const { t } = useTranslation()
  const { mutate: updateRole } = useUpdateMutation({})
  const urlParams = useParams()

  const defaultValues = useMemo(() => {
    const userPermissionMaping = permissions.map(p => {
      let name = null
      useRoleData.permissions.forEach(userPerm => {
        if (userPerm.id === p.id) {
          name = userPerm.name
        }
      })
      return name
    })
    return {
      name: useRoleData.name,
      guard_name: useRoleData.guard_name,
      permissions: userPermissionMaping
    }
  }, [permissions, useRoleData])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSubmit = async values => {
    updateRole({
      ...values,
      id: urlParams.id
    })
  }

  if (!useRoleData) {
    return <DataNotFound></DataNotFound>
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <Toolbar localization={localization} r></Toolbar>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t(`${localization}.guard_name`)} <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant='outlined'
              value={useRoleData.guard_name}
              error={!!errors.guard_name}
              helperText={<>{errors.guard_name?.message}</>}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              Name <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant='outlined'
              {...register('name')}
              error={!!errors.name}
              helperText={<>{errors.name?.message}</>}
            />
          </Grid>

          <Grid container spacing={2}>
            {permissions?.map((perm, index) => (
              <Grid item xs={12} md={6} key={perm.id}>
                <Controller
                  name={`permissions[${index}]`}
                  control={control}
                  render={({ field: controlField }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...controlField}
                            checked={!!controlField.value}
                            onChange={e => {
                              if (e.target.checked) {
                                controlField.onChange(perm.name)
                              } else {
                                controlField.onChange(null)
                              }
                            }}
                          />
                        }
                        label={perm.name}
                      />
                    </>
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </form>
    </>
  )
}

Body.propTypes = {
  permissions: propTypes.array.isRequired,
  useRoleData: propTypes.object.isRequired
}

export default Body
