import { Box, Stack, Toolbar } from '@mui/material'
import PropTypes from 'prop-types'

const ListToolbarWrapper = ({ children }) => {
  return (
    <Toolbar disableGutters={false}>
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction='row-reverse' minWidth='46px' spacing={2}>
        {children}
      </Stack>
    </Toolbar>
  )
}

ListToolbarWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default ListToolbarWrapper
