// DASH BOARD
export const DASHBOARD = 'overview.dashboard'

// TENANT
export const TENANT = 'overview.tenants.*'
export const TENANT_VIEW = 'overview.tenants.view'
export const TENANT_VIEW_DETAIL = 'overview.tenants.view_detail'
export const TENANT_SYNC_DEVICES = 'overview.tenants.view_detail.sync_devices'
export const TENANT_CHECK_STATUS = 'overview.tenants.check_status'
export const TENANT_REMOVE_INACTIVE = 'overview.tenants.remove_inactive_tenant'

// DEVICE REGISTER SESSION
export const REGISTER_SESSION = 'overview.devices_register_session.*'
export const REGISTER_SESSION_VIEW = 'overview.devices_register_session.view'
export const REGISTER_SESSION_VIEW_DETAIL = 'overview.devices_register_session.view_detail'

// DEVICE
export const DEVICE = 'overview.devices.*'
export const DEVICE_VIEW = 'overview.devices.view'
export const DEVICE_VIEW_DETAIL = 'overview.devices.view_detail'
export const DEVICE_REGISTER = 'overview.devices.register'
export const DEVICE_DEREGISTER = 'overview.devices.deregister'

// ADMINISTRATOR MENU
export const ADMINISTRATOR = 'overview.administrator.*'
export const ADMINISTRATOR_VIEW = 'overview.administrator.view'
export const ADMINISTRATOR_INVITE = 'overview.administrator.invite.*'
export const ADMINISTRATOR_INVITE_AZURE = 'overview.administrator.invite_azure_tenants'
export const ADMINISTRATOR_INVITE_GOOGLE = 'overview.administrator.invite_google_domain'

// ADMINISTRATOR > USER > SCHOOLUSERS AND ADMIN MENU
export const USER = 'overview.administrator.users.*'
export const USER_CHANGE_ROLE = 'overview.administrator.users.change_role'
export const USER_CHANGE_TENANT = 'overview.administrator.users.change_azure_tenant'
export const USER_CHANGE_DOMAIN = 'overview.administrator.users.change_google_domain'
export const SCHOOL_USER = 'overview.administrator.users.school_users.*'
export const SCHOOL_USER_VIEW = 'overview.administrator.users.school_users.view'
export const SCHOOL_USER_VIEW_DETAIL = 'overview.administrator.users.school_users.view_detail'
export const SCHOOL_USER_DISABLE = 'overview.administrator.users.school_users.disable'
export const USER_ADMIN = 'overview.administrator.users.admin.*'
export const USER_ADMIN_CHANGE_ROLE = 'overview.administrator.users.admin.change_role'
export const USER_ADMIN_VIEW = 'overview.administrator.users.admin.view'
export const USER_ADMIN_VIEW_DETAIL = 'overview.administrator.users.admin.view_detail'
export const USER_ADMIN_DISABLE = 'overview.administrator.users.admin.disable'

// ADMINISTRATOR > COMPANY
export const COMPANY = 'overview.administrator.companies.*'
export const COMPANY_VIEW = 'overview.administrator.companies.view'
export const COMPANY_CREATE = 'overview.administrator.companies.create'
export const COMPANY_EDIT = 'overview.administrator.companies.edit'
export const COMPANY_VIEW_DETAIL = 'overview.administrator.companies.view_detail'

// ADMINISTRATOR > ROLE
export const ROLE = 'overview.administrator.roles.*'
export const ROLE_VIEW = 'overview.administrator.roles.view'
export const ROLE_CREATE = 'overview.administrator.roles.create'
export const ROLE_EDIT = 'overview.administrator.roles.edit'

// ADMINISTRATOR > SETTINGS
export const SETTING = 'overview.administrator.settings'
