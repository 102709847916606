import { getAzureGroupTag } from '~/api'
import organisationApi from '~/api/organisationApi'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'

export const useAzureGroupTagQuery = (enabled = true) => {
  return useQuery(
    ['azure_group_tag'],
    async () => {
      const { data } = await getAzureGroupTag()
      return data
    },
    {
      enabled
    }
  )
}

export const useGroupTagLoanerQuery = (enabled = true) => {
  return useQuery(
    ['group_tags_loaner'],
    async () => {
      const { data } = await organisationApi.listGroupTagsLoanerApi()
      return data
    },
    {
      enabled
    }
  )
}

export const useGroupTagStaffQuery = (enabled = true) => {
  return useQuery(
    ['group_tags_staff'],
    async () => {
      const { data } = await organisationApi.listGroupTagsStaffApi()
      return data
    },
    {
      enabled
    }
  )
}
