import { Autocomplete, Stack, TextField, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CancelButton } from '~/components/Button/CancelButton'
import { LoadingButton } from '~/components/Button/LoadingButton'
import CustomModal from '~/components/Modal/CustomModal'
import { useUpdateGroupTagMutation } from '../query'

const StudentGroupTag = ({
  defaultStudentGroupTags,
  groupTagsLoaner,
  grouptagStaff,
  open,
  setOpen,
  organisationId,
  organisationDetail
}) => {
  const { t } = useTranslation()
  const [groupTagStudent, setGroupTagStudent] = useState('')
  const [groupTagLoaner, setGroupTagLoaner] = useState('')
  const [groupTagStaff, setGroupTagStaff] = useState('')

  const { mutate: updateGroupTag } = useUpdateGroupTagMutation(organisationId)
  const localization = 'pages.organisation.'

  // INIT VALUE BEFORE OPEN MODAL
  useEffect(() => {
    setGroupTagStudent(organisationDetail.default_group_tag)
    setGroupTagLoaner(organisationDetail.default_group_tag_loaner)
    setGroupTagStaff(organisationDetail.default_group_tag_staff)
  }, [organisationDetail])

  const handleCloseModal = () => {
    setOpen(false)
  }

  // PROCESS DEFAULT STUDENT GROUPTAG
  const handleGroupTagChange = event => {
    setGroupTagStudent(event.target.value)
  }

  const groupTags = useMemo(() => {
    return defaultStudentGroupTags?.data || []
  }, [defaultStudentGroupTags])

  // PROCESS GROUP TAG LOANER
  const handleGroupTagLoanerChange = event => {
    setGroupTagLoaner(event.target.value)
  }

  const loanerOptions = useMemo(() => {
    return groupTagsLoaner?.data || []
  }, [groupTagsLoaner])

  // PROCESS GROUP TAG STAFF
  const handleGroupTagStaffChange = event => {
    setGroupTagStaff(event.target.value)
  }

  const staffOptions = useMemo(() => {
    return grouptagStaff?.data || []
  }, [grouptagStaff])

  // PROCESS SUBMIT
  const handleSubmitModal = useCallback(() => {
    updateGroupTag({
      group_tag: groupTagStudent,
      group_tag_loaner: groupTagLoaner,
      group_tag_staff: groupTagStaff
    })
    setOpen(false)
  }, [groupTagStudent, updateGroupTag, groupTagLoaner, groupTagStaff, setOpen])

  return (
    <CustomModal open={open} onClose={handleCloseModal}>
      <Typography> {organisationDetail?.name} </Typography>

      <Autocomplete
        id='group-tags'
        name='group_tag'
        freeSolo
        value={groupTagStudent}
        options={groupTags.map(option => option.group_tag)}
        onChange={e => setGroupTagStudent(e.target.innerHTML)}
        sx={{ mt: 2 }}
        disabled={groupTags?.length === 0}
        renderInput={params => (
          <Tooltip title={groupTags?.length === 0 ? t(`${localization}contactSupport`) : ''}>
            <TextField
              {...params}
              label={t(`${localization}defaultGroupTag`)}
              onChange={handleGroupTagChange}
            />
          </Tooltip>
        )}
      />

      <Autocomplete
        id='group-tag-staff'
        name='group_tag_staff'
        freeSolo
        value={groupTagStaff}
        options={staffOptions.map(option => option.default_group_tag_staff)}
        onChange={e => setGroupTagStaff(e.target.innerHTML)}
        sx={{ mt: 2 }}
        disabled={staffOptions?.length === 0}
        renderInput={params => (
          <Tooltip title={staffOptions?.length === 0 ? t(`${localization}contactSupport`) : ''}>
            <TextField
              {...params}
              label={t(`${localization}defaultStaffGroupTag`)}
              onChange={handleGroupTagStaffChange}
            />
          </Tooltip>
        )}
      />

      <Autocomplete
        id='group-tags_loaner'
        name='group_tag_loaner'
        freeSolo
        value={groupTagLoaner}
        options={loanerOptions.map(option => option.default_group_tag_loaner)}
        onChange={e => setGroupTagLoaner(e.target.innerHTML)}
        sx={{ mt: 2 }}
        disabled={loanerOptions?.length === 0}
        renderInput={params => (
          <Tooltip title={loanerOptions?.length === 0 ? t(`${localization}contactSupport`) : ''}>
            <TextField
              {...params}
              label={t(`${localization}groupTagLoaner`)}
              onChange={handleGroupTagLoanerChange}
            />
          </Tooltip>
        )}
      />

      <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
        <LoadingButton onClick={handleSubmitModal}>{t(`${localization}confirm`)}</LoadingButton>
        <CancelButton onClick={handleCloseModal} />
      </Stack>
    </CustomModal>
  )
}

StudentGroupTag.propTypes = {
  defaultStudentGroupTags: PropTypes.array,
  groupTagsLoaner: PropTypes.array,
  grouptagStaff: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  organisationId: PropTypes.number,
  organisationDetail: PropTypes.object
}

export default StudentGroupTag
