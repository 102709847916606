import { Stack, Typography } from '@mui/material'
import CustomModal from '~/components/Modal/CustomModal'
import { LoadingButton } from '~/components/Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useRepairDeviceMutation } from '../query'

const ModalInRepair = ({
  openInRepairModal,
  handleCloseInRepairModal,
  deviceToInRepair,
  setOpenInRepairModal,
  refetchDeviceList
}) => {
  const { t } = useTranslation()

  const { mutateAsync: repairDeviceMute, isLoading: isRepairDeviceLoading } =
    useRepairDeviceMutation()

  const handleSubmitInRepairModal = async () => {
    if (deviceToInRepair === null) {
      setOpenInRepairModal(false)
      return
    }

    await repairDeviceMute({ id: deviceToInRepair?.id, type: 'IN_REPAIR' })
    await refetchDeviceList()

    setOpenInRepairModal(false)
  }

  return (
    <CustomModal open={openInRepairModal} onClose={handleCloseInRepairModal}>
      <Typography variant='h6'>
        {t('pages.device.putDeviceToRepair')} : {deviceToInRepair?.serial} ?
      </Typography>
      <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
        <LoadingButton onClick={handleCloseInRepairModal}>
          {t('pages.organisation.cancel')}
        </LoadingButton>
        <LoadingButton onClick={handleSubmitInRepairModal} loading={isRepairDeviceLoading}>
          {t('pages.organisation.confirm')}
        </LoadingButton>
      </Stack>
    </CustomModal>
  )
}

ModalInRepair.propTypes = {
  openInRepairModal: PropTypes.bool,
  handleCloseInRepairModal: PropTypes.func,
  deviceToInRepair: PropTypes.object,
  setOpenInRepairModal: PropTypes.func,
  refetchDeviceList: PropTypes.func
}

export default ModalInRepair
