import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Divider, Paper, Stack, TextField } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import BreadCrumb from '~/components/BreadCrumb'
import { ADMIN_USER_COMPANY } from '~/constants/Routes'
import { useOverviewCreateCompanyMutation } from './mutation'

const schema = yup
  .object({
    name: yup.string().required()
  })
  .required()

const New = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { mutate, isLoading, isSuccess } = useOverviewCreateCompanyMutation()
  const onSubmit = async values => {
    mutate(values)
  }

  const BreadComponents = [
    {
      title: 'Administrator'
    },
    {
      route: ADMIN_USER_COMPANY,
      title: 'Company Overview'
    },
    {
      page: 'new',
      title: 'New Company'
    }
  ]

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  useEffect(() => {
    if (isSuccess) {
      navigate(ADMIN_USER_COMPANY)
    }
  }, [isSuccess, navigate])

  return (
    <>
      <BreadCrumb breadcrumbs={BreadComponents} />
      <Divider style={{ marginBottom: 20 }} />
      <Paper
        sx={{
          px: 2,
          py: 2
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <TextField
              {...register('name')}
              label={t('name')}
              variant='outlined'
              fullWidth
              error={!!errors.name}
              helperText={errors?.name?.message}
            />

            <Stack direction='row-reverse'>
              <LoadingButton variant='contained' type='submit' loading={isLoading}>
                {t('button.create')}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </>
  )
}

export default New
