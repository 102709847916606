import { enqueueSnackbar } from 'notistack'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { adminCheckIntuneLicenceRevoked, adminValidateDomainToken, adminViewTenant } from '~/api'
import { parseApiErrorMessage, removeEmptyValue, showErrorMessage } from '~/utils/helpers'

export const useViewTenantsQuery = params => {
  return useQuery(
    ['tenant_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await adminViewTenant(p)
      return { data: data.data, total: data.meta.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useCheckTenantIntuneLicenceRevokedMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async id => {
      const { data } = await adminCheckIntuneLicenceRevoked(id)
      return data.data
    },
    {
      onError: showErrorMessage,
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully check tenant status',
          variant: 'success'
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries('tenant_overview')
      }
    }
  )
}

export const useCheckDomainPermissionMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async id => {
      const { data } = await adminValidateDomainToken(id)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully check domain status',
          variant: 'success',
          autoHideDuration: 2000
        })
        queryClient.invalidateQueries('tenant_overview')
      },
      onError: showErrorMessage
    }
  )
}
