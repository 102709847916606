import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { overviewReRegisterTenantApplication, reRegisterTenantApplication } from '~/api'
import { parseApiErrorMessage } from '~/utils/helpers'
import msalLoginPopup from '~/utils/msal'

export const useReRegistrationAppMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async item => {
      const { accessToken } = await msalLoginPopup()
      const payload = { access_token: accessToken, tenant_id: item?.tenant_id }
      if (window.location.href.includes('/overview/')) {
        const { data } = await overviewReRegisterTenantApplication(payload)
        return data.data
      }
      const { data } = await reRegisterTenantApplication(payload)
      return data.data
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries('profile')
        queryClient.invalidateQueries('app_connection')
        queryClient.invalidateQueries('overview_get_tenant_detail')
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
