import {
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { getUserStatusColor } from '~/utils/helpers'
import { UserHeadCells } from '../UserHeadCells'
import { useDisableUsersQuery } from '../query'

const localization = 'pages.adminOverview.users.'

const UsersTableBody = ({ isLoading, users }) => {
  if (isLoading) {
    return <TableLoading colSpan={UserHeadCells.length} />
  }

  return (
    <TableBody>
      {users?.data?.length === 0 && <DataNotFound colSpan={UserHeadCells.length} />}
      {users?.data?.length > 0 &&
        users?.data?.map(row => {
          const chipcolor = getUserStatusColor(row?.status)

          return (
            <TableRow key={row.id}>
              <TableCell>
                <Link href={`/overview/super-users/${row.id}`} sx={{ ml: 4 }}>
                  {' '}
                  {row.id}
                </Link>
              </TableCell>
              <TableCell>
                {row?.first_name} {row?.last_name}
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.role}</TableCell>
              <TableCell>
                <Chip label={row?.status} color={chipcolor} size='small' />
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row?.created_at} />
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

UsersTableBody.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.object
}

UsersTableBody.defaultProps = {
  isLoading: false,
  users: {}
}

const DisableSuperUsers = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [page, setPage] = useState(1)

  const { data, isLoaing } = useDisableUsersQuery({ page, search, order, orderBy })

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const searchHandler = useCallback(event => {
    setSearchValue(event.target.value)
    setPage(1)
  }, [])

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Administrator</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <Toolbar>
          <Grid container spacing={0.5}>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item sx={12} sm={3}>
              <SearchToolBar
                filterSearch={search}
                onFilterSearch={searchHandler}
                placeholder='users'
              />
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />

        <TableContainer>
          <Table>
            <TableHeader
              headCells={UserHeadCells}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <UsersTableBody isLoaing={isLoaing} users={data} />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={handleChangePage}
        />
      </Paper>
    </>
  )
}

export default DisableSuperUsers
