import {
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import { getUserStatusColor } from '~/utils/helpers'
import { UserHeadCells } from './UserHeadCells'
import { useSuperUsersQuery } from './query'

const localization = 'pages.adminOverview.users.'

const OverviewUsersTableBody = ({ isLoading, users }) => {
  const { overviewUser } = useAdminPermission()
  if (isLoading) {
    return <TableLoading colSpan={UserHeadCells.length} />
  }

  const renderIdCol = row =>
    overviewUser.administrator.users.canViewAdminDetail ? (
      <Link href={`/overview/super-users/${row.id}`} sx={{ ml: 3 }}>
        {row.id}
      </Link>
    ) : (
      row.id
    )

  return (
    <TableBody>
      {users?.data?.length === 0 && <DataNotFound colSpan={UserHeadCells.length} />}
      {users?.data?.length > 0 &&
        users?.data?.map(row => {
          const chipcolor = getUserStatusColor(row?.status)
          return (
            <TableRow key={row.id}>
              <TableCell>{renderIdCol(row)}</TableCell>
              <TableCell>
                {row?.first_name} {row?.last_name}
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.role}</TableCell>
              <TableCell>
                <Chip
                  label={row?.status ?? 'active'}
                  color={row?.status ? chipcolor : 'success'}
                  size='small'
                />
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row?.created_at} />
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

OverviewUsersTableBody.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.shape({
    data: PropTypes.array.isRequired
  }).isRequired
}

const OverviewUsersTable = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [page, setPage] = useState(1)

  const { data, isLoaing } = useSuperUsersQuery({ page, search, order, orderBy })

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const searchHandler = debounce(event => {
    setSearchValue(event.target.value)
    setPage(1)
  }, 500)

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Administrator</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <Toolbar>
          <Grid container spacing={0.5}>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item sx={12} sm={3}>
              <SearchToolBar filterSearch={search} onChange={searchHandler} placeholder='users' />
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />

        <TableContainer>
          <Table>
            <TableHeader
              headCells={UserHeadCells}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <OverviewUsersTableBody isLoaing={isLoaing} users={data} />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={handleChangePage}
        />
      </Paper>
    </>
  )
}

export default OverviewUsersTable
