import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Box, ListItem, ListItemIcon, ListItemText, Stack, Toolbar } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ToolBarButton from '~/components/Button/ToolBarButton'
import { useAdminPermission } from '~/hooks/useAdminPermission'

const UserDetailToolbar = ({ user, localization, deleteUser, changeDisableUser }) => {
  const { t } = useTranslation()
  const { overviewUser } = useAdminPermission()

  return (
    <Toolbar
      disableGutters={false}
      sx={{
        bgcolor: '#C8FACD',
        borderRadius: '10px',
        my: 1,
        mr: 1
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {user?.disabled === true && (
          <ListItem>
            <ListItemIcon>
              <Box component='span' className='material-symbols-rounded' sx={{ color: '#102F44' }}>
                info
              </Box>
            </ListItemIcon>
            <ListItemText primary={`${t('message.disableAccount')}`} />
          </ListItem>
        )}
      </Box>

      <Stack direction='row-reverse' minWidth='46px' spacing={2}>
        {user?.status === 'active' && user?.disabled === false && (
          <ToolBarButton
            icon={<RemoveCircleOutlineIcon sx={{ mb: '2px' }} />}
            color='error'
            handleAction={() =>
              changeDisableUser({
                id: user.id,
                disabled: 1
              })
            }
            name={t('button.disable')}
            disabled={!overviewUser.administrator.users.canDisable}
          />
        )}

        {user?.status === 'active' && user?.disabled === true && (
          <ToolBarButton
            icon={<CheckCircleOutlineIcon sx={{ mb: '2px' }} />}
            handleAction={() =>
              changeDisableUser({
                id: user.id,
                disabled: 0
              })
            }
            name={t('button.enable')}
            disabled={!overviewUser.administrator.users.canDisable}
          />
        )}
      </Stack>
    </Toolbar>
  )
}

UserDetailToolbar.propTypes = {
  user: PropTypes.object,
  localization: PropTypes.string,
  deleteUser: PropTypes.object,
  changeDisableUser: PropTypes.func
}

export default UserDetailToolbar
