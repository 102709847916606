import { useMutation, useQueryClient } from 'react-query'
import { enqueueSnackbar } from 'notistack'
import {
  grantTenantApplicationPermission,
  registerTenantApplication,
  updateAzureTenantRegistrationContactInfo
} from '~/api'
import { parseApiErrorMessage, showErrorMessage } from '~/utils/helpers'
import msalLoginPopup from '~/utils/msal'

export const useRegistrationOptInMutation = () =>
  useMutation(
    async registrationToken => {
      const { accessToken } = await msalLoginPopup()
      const { data } = await registerTenantApplication(accessToken, registrationToken)
      return data.data
    },
    {
      onSuccess: data => {}
    }
  )

export const useGrantAppPermissionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async registrationToken => {
      const { data } = await grantTenantApplicationPermission(registrationToken)
      return data.data
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries('profile')
      },
      onError: showErrorMessage
    }
  )
}

export const useUpdateContactInfoMutation = () => {
  return useMutation(
    async payload => {
      const { data } = await updateAzureTenantRegistrationContactInfo(payload)
      return data.data
    },
    {
      onSuccess: data => {},
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}
