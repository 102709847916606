import { Toolbar as MuiToolbar, Box, Stack, Breadcrumbs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Table from './Table'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect } from 'react'
import { unsetSnackbar } from '~/store/snackbar/slice'
import AddIcon from '@mui/icons-material/Add'
import ToolBarButton from '~/components/Button/ToolBarButton'
import { ADMIN_USER_ROLE_NEW } from '~/constants/Routes'
import { useAdminPermission } from '~/hooks/useAdminPermission'

const Toolbar = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { overviewUser } = useAdminPermission()

  return (
    <>
      <MuiToolbar disableGutters={false}>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction='row'
          justifyContent='right'
          alignItems='right'
          minWidth='46px'
          spacing={2}
        >
          <ToolBarButton
            icon={<AddIcon />}
            name={t('pages.overview.role.create')}
            handleAction={() => {
              navigate(ADMIN_USER_ROLE_NEW)
            }}
            disabled={!overviewUser.administrator.roles.canCreate}
          />
        </Stack>
      </MuiToolbar>
    </>
  )
}

const Role = () => {
  const snackbarData = useSelector(selectSnackbar)
  const { showSnackbar, snackbar } = useSnackbar()
  const dispatch = useDispatch()
  useEffect(() => {
    if (snackbarData) {
      showSnackbar(snackbarData.message, snackbarData.type)
    }
    return () => {
      dispatch(unsetSnackbar({}))
    }
  })

  return (
    <>
      {snackbar}
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Roles</Typography>
      </Breadcrumbs>

      <Toolbar />
      <Table />
    </>
  )
}

export default Role
