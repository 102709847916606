import adminAxiosClient from './adminBase'

class OverviewTenantApi {
  listGroupTagsApi = id => adminAxiosClient.get(`api/overview/tenants/${id}/group-tags`)
  listGroupTagsStaffApi = id => adminAxiosClient.get(`api/overview/tenants/${id}/group-tags-staff`)
  listGroupTagsLoanerApi = id =>
    adminAxiosClient.get(`api/overview/tenants/${id}/group-tags-loaner`)

  healthCheckGoogleDomainApi = serviceTenantId =>
    adminAxiosClient.get(`api/overview/tenants/${serviceTenantId}/healthcheck-google-domain`)
}

export default new OverviewTenantApi()
