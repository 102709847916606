import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { overviewSuperUsersDetail } from '~/api'
import { setUserDetailInformation } from '~/store/user/slice'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useSuperUserQuery = id => {
  const dispatch = useDispatch()
  return useQuery(
    'overview_super_user_info',
    async () => {
      const { data } = await overviewSuperUsersDetail(id)
      return data.data
    },
    {
      onSuccess: data => {
        dispatch(setUserDetailInformation(data))
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
