import PropTypes from 'prop-types'
import MenuItemGroup from './MenuItemGroup'
import MenuItemLink from './MenuItemLink'
import { useSelector } from 'react-redux'
import { selectAdminPermissions } from '~/store/adminAuth/selector'
import { selectUserPermissions } from '~/store/user/selector'
import { DASHBOARD } from '~/constants/adminPermission'

const MenuItem = ({ item }) => {
  const adminPermissions = useSelector(selectAdminPermissions)
  const userPermisson = useSelector(selectUserPermissions)
  const hasOverviewUrl = item.url?.includes('/overview/') || false

  const isAdminOverview = hasOverviewUrl && adminPermissions.includes('super_admin')
  const isAdminUser = !hasOverviewUrl && userPermisson.includes('super_admin')

  const permissions = hasOverviewUrl ? adminPermissions : userPermisson

  // Dpnt need to check permission for admins
  if (isAdminOverview || isAdminUser || item.permission === DASHBOARD) {
    if (item.children) {
      return <MenuItemGroup key={item.title} item={item} />
    }
    return <MenuItemLink key={item.title} item={item} />
  }

  const permissionGroups = item.permissions_group || []

  const hasRequiredPermission = requirePermission => {
    return permissions.some(permission => {
      if (permission.endsWith('.*')) {
        const userPermission = permission.slice(0, -2)
        const regex = new RegExp(`^${userPermission}(\\..+)?$`)
        return regex.test(requirePermission)
      }

      return permission === requirePermission
    })
  }

  const canShowMenu = permissionGroups.some(hasRequiredPermission)

  if (item.children && canShowMenu) {
    return <MenuItemGroup key={item} item={item} />
  }

  if (hasRequiredPermission(item.permission)) {
    return <MenuItemLink key={item} item={item} />
  }

  return null
}

MenuItem.propTypes = {
  item: PropTypes.object
}

export default MenuItem
