import { LoadingButton } from '@mui/lab'
import { Breadcrumbs, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHealthCheckGoogleDomainQuery } from '~/pages/Organisations/GroupTag/query'
import SchoolSection from '../SchoolSection'
import CustomerSection from './CustomerSection'
import ZTETokenSection from './ZTETokenSection'

// tenant must be Google Tenant
const GoogleHealthCheck = ({ tenant }) => {
  const { t } = useTranslation()
  const [serviceTenantId, setServiceTenantId] = useState(null)

  const { isLoading } = useHealthCheckGoogleDomainQuery(serviceTenantId, setServiceTenantId)

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.ggHealthCheck')}</Typography>
      </Breadcrumbs>

      <Tooltip title={t('general.checkZteToken')}>
        <LoadingButton
          onClick={() => setServiceTenantId(tenant?.serviceTenant?.id)}
          sx={{ mt: 1, mr: 1 }}
          loading={isLoading}
          variant='contained'
        >
          {t('general.healthCheck')}
        </LoadingButton>
      </Tooltip>

      <CustomerSection tenant={tenant} />
      <ZTETokenSection tenant={tenant} />
      <SchoolSection tenant={tenant} />
    </>
  )
}

GoogleHealthCheck.propTypes = {
  tenant: PropTypes.object.isRequired
}

export default GoogleHealthCheck
