import { useCallback, useMemo, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import LoopIcon from '~/components/Button/LoopIcon'
import ListToolbar from '~/components/ListToolbar'
import { AZURE_DEVICE_NEW } from '~/constants/Routes'
import { useUserPermission } from '~/hooks/useUserPermission'
import { selectUser } from '~/store/auth/selector'
import ModalBulkDeRegister from './ModalBulkDeRegister'
import ModalInRepair from './ModalInRepair'
import ModalIsRepaired from './ModalIsRepaired'
import UploadPopup from './UploadPopup'

const DeviceToolbarButtonsAndModals = ({
  selected,
  open,
  deviceToDeregist,
  deviceToInRepair,
  openIsRepairModal,
  openInRepairModal,
  setOpen,
  setSelected,
  setDeviceToInRepair,
  setIsSelectAllSelected,
  setDeviceToDeregist,
  setOpenIsRepairModal,
  setOpenInRepairModal,
  refetchDeviceList,
  isFetchingDevice,
  setIsFetchingDevice
}) => {
  const user = useSelector(selectUser)
  const { userAbility } = useUserPermission()

  const [openUpload, setOpenUpload] = useState(false)

  const onCaptureDevices = useCallback(() => {
    setIsFetchingDevice(v => {
      localStorage.setItem('IS_FETCHING_DEVICE', !v)
      return !v
    })
  }, [setIsFetchingDevice])

  const onBulkDeregister = useCallback(() => {
    setOpen(true)
    setDeviceToDeregist(selected)
  }, [selected, setDeviceToDeregist, setOpen])

  const onBulkDeleteByCSV = useCallback(() => {
    setOpenUpload(true)
    setDeviceToDeregist(selected)
  }, [selected, setDeviceToDeregist])

  const handleCloseModal = () => {
    setOpen(false)
    setDeviceToDeregist(null)
  }

  const handleCloseInRepairModal = () => {
    setOpenInRepairModal(false)
    setDeviceToInRepair(null)
  }

  const handleCloseIsRepairModal = () => {
    setOpenIsRepairModal(false)
    setDeviceToInRepair(null)
  }

  const toolBarButtons = useMemo(() => {
    return [
      {
        button: 'bulkDeregisBtn',
        icon: <DeleteIcon />,
        isPermission: true,
        disabled: _.isEmpty(selected) || !userAbility.devices.canBulkDeregister,
        // loading: isBulkDeletingDevices,
        onClick: e => {
          onBulkDeregister()
        }
      },
      {
        button: 'bulkDeleteByCSV',
        icon: <DeleteIcon />,
        isPermission: true,
        disabled: userAbility.devices.canBulkDeleteByCsv === false,
        loading: false,
        onClick: e => {
          onBulkDeleteByCSV()
        }
      },
      {
        button: 'create',
        navigateLink: AZURE_DEVICE_NEW,
        icon: <AddIcon />,
        isPermission: true,
        disabled:
          !user.tenant.service_tenant.has_intune_licence_valid || !userAbility.devices.canRegister
      },
      {
        button: 'captureDevices',
        icon: <LoopIcon isFetching={isFetchingDevice} />,
        isToggle: true,
        selected: isFetchingDevice,
        isPermission: true,
        onClick: e => {
          onCaptureDevices()
        }
      }
    ]
  }, [
    selected,
    userAbility,
    user.tenant.service_tenant.has_intune_licence_valid,
    isFetchingDevice,
    onBulkDeregister,
    onBulkDeleteByCSV,
    onCaptureDevices
  ])

  return (
    <>
      <ListToolbar toolBarButton={toolBarButtons} />
      <ModalBulkDeRegister
        open={open}
        handleCloseModal={handleCloseModal}
        deviceToDeregist={deviceToDeregist}
        setOpen={setOpen}
        setSelected={setSelected}
        selected={selected}
        setIsSelectAllSelected={setIsSelectAllSelected}
        refetchDeviceList={refetchDeviceList}
      />

      <ModalInRepair
        openInRepairModal={openInRepairModal}
        handleCloseInRepairModal={handleCloseInRepairModal}
        deviceToInRepair={deviceToInRepair}
        setOpenInRepairModal={setOpenInRepairModal}
        refetchDeviceList={refetchDeviceList}
      />

      <ModalIsRepaired
        openIsRepairModal={openIsRepairModal}
        handleCloseIsRepairModal={handleCloseIsRepairModal}
        deviceToInRepair={deviceToInRepair}
        setOpenIsRepairModal={setOpenIsRepairModal}
        refetchDeviceList={refetchDeviceList}
      />

      <UploadPopup
        openUpload={openUpload}
        setOpenUpload={setOpenUpload}
        tenant={user?.tenant}
        setIsFetchingDevice={setIsFetchingDevice}
      />
    </>
  )
}

DeviceToolbarButtonsAndModals.propTypes = {
  selected: PropTypes.array,
  open: PropTypes.bool.isRequired,
  deviceToDeregist: PropTypes.array,
  deviceToInRepair: PropTypes.object,
  openIsRepairModal: PropTypes.bool.isRequired,
  openInRepairModal: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  setDeviceToInRepair: PropTypes.func.isRequired,
  setIsSelectAllSelected: PropTypes.func.isRequired,
  setDeviceToDeregist: PropTypes.func.isRequired,
  setOpenIsRepairModal: PropTypes.func.isRequired,
  setOpenInRepairModal: PropTypes.func.isRequired,
  refetchDeviceList: PropTypes.func.isRequired,
  isFetchingDevice: PropTypes.bool.isRequired,
  setIsFetchingDevice: PropTypes.func.isRequired
}

DeviceToolbarButtonsAndModals.defaultProps = {
  selected: [],
  deviceToDeregist: [],
  deviceToInRepair: null
}

export default DeviceToolbarButtonsAndModals
