import {
  Chip,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { ADMIN_VIEW_USER_DETAIL } from '~/constants/Routes'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import useTable from '~/hooks/useTable'
import { getUserStatusColor } from '~/utils/helpers'
import { CompanyUsersHeadCells } from './CompanyHeadCells'
import { useUserByCompanyIdQuery } from './query'

const CompanyUsersTableBody = ({ isLoading, users }) => {
  const { overviewUser } = useAdminPermission()
  if (isLoading) {
    return <TableLoading colSpan={CompanyUsersHeadCells.length} />
  }

  const renderIdCol = row => (
    <>
      {overviewUser.administrator.users.canViewDetail && (
        <MuiLink to={ADMIN_VIEW_USER_DETAIL.replace(':id', row.id)} component={Link}>
          {row.id}
        </MuiLink>
      )}
    </>
  )

  return (
    <TableBody>
      {users.length === 0 && <DataNotFound colSpan={CompanyUsersHeadCells.length} />}
      {users.length > 0 &&
        users.map((row, index) => {
          const chipcolor = getUserStatusColor(row.status)
          return (
            <TableRow hover key={index}>
              <TableCell align='left'>{renderIdCol(row)}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>
                <Chip label={row.status} color={chipcolor} size='small' />
              </TableCell>
              {row.role ? (
                <TableCell>
                  <Chip label={row.role} size='small' />
                </TableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>
          )
        })}
    </TableBody>
  )
}

CompanyUsersTableBody.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.array
}

CompanyUsersTableBody.defaultProps = {
  isLoading: false,
  users: []
}

const CompanyUsers = () => {
  const { id } = useParams()

  const {
    data: users,
    isLoading,
    order,
    page,
    orderBy,
    setPage,
    sortHandler
  } = useTable({
    query: useUserByCompanyIdQuery,
    queryAttributes: {
      id
    },
    initialStates: {
      order: 'asc',
      page: 1,
      orderBy: 'name'
    }
  })

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
          <TableHeader
            headCells={CompanyUsersHeadCells}
            onRequestSort={sortHandler}
            order={order}
            orderBy={orderBy}
            localization=''
          />
          <CompanyUsersTableBody isLoading={isLoading} users={users?.data || []} />
        </Table>
      </TableContainer>
      <PaginationTable
        data={users}
        page={page}
        handleChangePage={(_, value) => setPage(value)}
        size='medium'
      />
    </Paper>
  )
}

export default CompanyUsers
