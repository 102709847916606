import { Box, Modal } from '@mui/material'
import PropTypes from 'prop-types'

const CustomModal = ({ children, open, onClose, style }) => {
  const defaultStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '95vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: 'rgba(0, 0, 0, .5)'
    },
    '&::-webkit-scrollbar': {
      width: '10px'
    }
  }

  const customStyle = { ...defaultStyle, ...style }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={customStyle}>{children}</Box>
    </Modal>
  )
}

CustomModal.defaultProps = {
  open: false,
  style: {},
  children: null
}

CustomModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  style: PropTypes.object
}

export default CustomModal
