import styled from '@emotion/styled'
import { Box, Button, Divider, Grid, Modal, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useBulkDeleteDeviceByCsvDownloadMutation,
  useBulkDeleteDeviceByCsvMutation,
  useBulkDeleteDeviceByCsvUploadMutation
} from '~/pages/Device/mutation'
import { LoadingButton } from '../../../../components/Button/LoadingButton'
import AdditionalUploadPopup from './AdditionalUploadPopup'
import ConfirmDialog from './ConfirmDialog'

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const ErrorBox = styled(Box)({
  overflow: 'auto'
})

const ErrorTypography = styled(Typography)({
  color: '#d32f2f',
  fontFamily: "'Lato',sans-serif",
  fontSize: '0.75rem',
  fontWeightL: 400,
  lineHeight: 1.66
})

const localization = 'pages.device.'

const UploadPopup = ({ openUpload, setOpenUpload, tenant, setIsFetchingDevice }) => {
  const tenantId = tenant.id
  const [selectedFile, setSelectedFile] = useState(null)
  const [failures, setFailures] = useState([])
  const [error, setError] = useState()
  const [result, setResult] = useState(null)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [errorConfirm, setErrorConfirm] = useState('')
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation()

  const changeHandler = useCallback(event => {
    setSelectedFile(event.target.files[0])
    setFailures([])
    setError()
  }, [])

  const handleClose = useCallback(() => {
    setOpenUpload(false)
    setFailures([])
    setError()
    setResult(null)
    setSelectedFile(null)
  }, [setOpenUpload])

  const { mutate: actionImport, isLoading } = useBulkDeleteDeviceByCsvUploadMutation(tenantId)
  const { mutate: actionDelete, isLoading: isDeleting } = useBulkDeleteDeviceByCsvMutation(tenantId)
  const { mutate: download } = useBulkDeleteDeviceByCsvDownloadMutation(tenantId)

  const onSubmit = useCallback(() => {
    setResult(null)
    actionImport(selectedFile, {
      onSettled: () => {
        setSelectedFile(false)
      },
      onSuccess: data => {
        setResult(data)
      },
      onError: e => {
        setFailures(e.response.data.data)
        setError(e.response.data.message)
      }
    })
  }, [actionImport, selectedFile])

  const handleConfirmOpen = () => {
    setOpenConfirm(true)
  }

  const handleConfirmClose = () => {
    setOpenConfirm(false)
    setConfirmText('')
    setErrorConfirm('')
  }

  const handleConfirm = () => {
    if (confirmText && confirmText.toLocaleLowerCase() === 'yes') {
      actionDelete(
        { delete_all_not_managed: checked },
        {
          onSettled: handleConfirmClose(),
          onSuccess: () => {
            handleClose()
            setIsFetchingDevice(v => {
              if (v === false) {
                localStorage.setItem('IS_FETCHING_DEVICE', !v)
                return !v
              }
              return v
            })
          }
        }
      )
    } else {
      setErrorConfirm('Please type YES to confirm')
    }
  }

  const handleDownload = () => {
    download()
  }

  const handleExampleFile = () => {
    const exampleFile = 'serial1,text1\nserial2,text2\nserial3,text3\nserial4,text4'
    const blob = new Blob([exampleFile], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'example.csv'
    a.click()
  }

  return (
    <>
      <StyledModal open={openUpload} onClose={e => handleClose()}>
        <Box
          p={3}
          bgcolor={'background.default'}
          color={'text.primary'}
          borderRadius={5}
          maxWidth={500}
        >
          <Typography variant='h5' color='gray' textAlign='center'>
            {t(`general.bulkDeleteDevice`)} tenant: {tenant.serviceTenant.default_domain_name}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} xs={12}>
              {!result && (
                <>
                  <Grid item xs={'auto'}>
                    <Box>{t(`general.uploadCSV`)}</Box>

                    <>
                      <input
                        id='file-import'
                        name='file'
                        type='file'
                        onChange={changeHandler}
                        accept='.csv'
                        style={{ display: 'none' }}
                      />
                      <Box
                        mt={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Button
                          variant='contained'
                          component='label'
                          htmlFor='file-import'
                          color='primary'
                          sx={{
                            marginRight: 2
                          }}
                        >
                          {t(`${localization}chooseFile`)}
                        </Button>
                        {!selectedFile ? (
                          <label htmlFor='file-import'>{t(`${localization}noFileChosen`)}</label>
                        ) : (
                          <Box
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              display: 'block',
                              textOverflow: 'ellipsis',
                              maxWidth: '300px'
                            }}
                          >
                            <label htmlFor='file-import'>{selectedFile.name}</label>
                          </Box>
                        )}
                      </Box>
                    </>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='caption'>
                      {t(`${localization}downloadExample`)}
                      <a href='#' onClick={handleExampleFile}>
                        {t(`${localization}here`)}
                      </a>
                      {t(`${localization}toSeeTheFormat`)}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                {failures && failures.length > 0 && (
                  <ErrorBox>
                    {failures.map(index => (
                      <ErrorTypography key={index.row}>
                        (Row: {index.row}) {index.contents.map(content => content)}
                      </ErrorTypography>
                    ))}
                  </ErrorBox>
                )}
                {error && (
                  <ErrorBox>
                    <ErrorTypography>{error}</ErrorTypography>
                  </ErrorBox>
                )}
                <AdditionalUploadPopup result={result} checked={checked} setChecked={setChecked} />
              </Grid>

              <Grid item xs={12}>
                <Stack direction='row-reverse' spacing={2}>
                  {result ? (
                    <>
                      <LoadingButton
                        loading={isDeleting}
                        onClick={handleConfirmOpen}
                        name={`${t('button.confirm')}`}
                      >
                        {t(`${localization}delete`)}
                      </LoadingButton>
                      <LoadingButton onClick={handleDownload} name={`${t('button.download')}`}>
                        {t(`${localization}download`)}
                      </LoadingButton>
                      <LoadingButton onClick={handleClose} name={`${t('button.cancel')}`}>
                        {t(`${localization}cancel`)}
                      </LoadingButton>
                    </>
                  ) : (
                    <LoadingButton
                      disabled={!selectedFile}
                      loading={isLoading}
                      type='submit'
                      onClick={onSubmit}
                      name={`${t('button.upload')}`}
                    >
                      {t('button.upload')}
                    </LoadingButton>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </StyledModal>
      <ConfirmDialog
        openConfirm={openConfirm}
        handleConfirmClose={handleConfirmClose}
        errorConfirm={errorConfirm}
        confirmText={confirmText}
        setConfirmText={setConfirmText}
        handleConfirm={handleConfirm}
      ></ConfirmDialog>
    </>
  )
}
UploadPopup.propTypes = {
  openUpload: PropTypes.bool,
  setOpenUpload: PropTypes.func,
  tenant: PropTypes.object,
  setIsFetchingDevice: PropTypes.func
}

export default UploadPopup
